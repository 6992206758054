import React, { useState, useRef, useEffect, MutableRefObject } from "react";
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import { useLocation } from "react-router-dom";
import "./prospecting.scss";
//import MapView from "@arcgis/core/views/MapView";
import MapView from "@arcgis/core/views/MapView";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import EsriSearch from "@arcgis/core/widgets/Search";
import SearchSource from "@arcgis/core/widgets/Search/SearchSource";
import esriRequest from "@arcgis/core/request";
import * as locator from "@arcgis/core/rest/locator"
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import Extent from "@arcgis/core/geometry/Extent";
//import { whenTrueOnce } from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils"
import Session from "../../models/session";

import getMap from "./mapConfig";
import Filters from "./filters/Filters";

// Custom Widgets
import Measurement from "../measurement/Measurement";
import ZoomExtent from "../zoom-extent-btns/ZoomExtent";
import Draw from "../draw/Draw";
import PropertyList from "./property-list/PropertyList";

import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../responsive';

import Property from "../../models/property";

import GIF from '../../images/HL_SR_gif.gif';

import {
  Button,
  // @ts-ignore
} from '@carbon/react'

import {
  //UPDATES FROM NATALIE: Add toast notification, remove inline
  ToastNotification,
  ComposedModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InlineLoading,
  //ref: https://carbondesignsystem.com/components/notification/usage/#toast-notifications
  //InlineNotification
  //END UPDATES FROM NATALIE
  //@ts-ignore
} from '@carbon/react';

interface iProps {
  user: __esri.PortalUser | null;
  //property: Property;
  userTables: {
    searchHistory: string;
    savedSessions: string;
    notes: string;
    userMarkets: string;
    favoriteProperties: string;
    userPlatform: string;
  };
  drawSymbol: any;
  notesTable: MutableRefObject<__esri.FeatureLayer | undefined>;
  notedProperties: Set<number>;
  savedProperties?: Set<number>;
  favoriteProperty: (propertyId: number) => void;
  unfavoriteProperty: (propertyId: number) => void;
  addNoteToProperty: (note: string, propertyId: number) => Promise<any> | null;
  deleteNote: (objectId: number) => void;
  updateNote: (objectId: number, newNoteText: string) => Promise<any> | null;
}

interface LocationState {
  address?: string;
}

const filtersStorageKey = 'smartreach-filters';
export default function Prospecting(props: iProps) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [mobilePage, setMobilePage] = useState<string>('map');

  const location = useLocation();
  const state = location.state as LocationState;
  const address = state?.address || 'No address provided';
  const mapUIInitialized = useRef(false);
  const mapDiv = useRef<HTMLDivElement>(null);
  const searchDiv = useRef<HTMLDivElement>(null);
  const fetchedUserInfo = useRef<boolean>(false);
  const searchInitialized = useRef<boolean>(false);
  const extentSetFromSavedSearch = useRef(false);
  //const property = useRef<__esri.FeatureLayer>(null)

  const [propertyBaseExpression, setPropertyBaseExpression] = useState('');
  const [crmRecordBaseExpression, setCRMBaseExpression] = useState('');
  const [contractBaseExpression, setContractBaseExpression] = useState('');
  const [MHIBaseExpression, setMHIBaseExpression] = useState('');
  const [marketVariables, setMarketVariables] = useState<any>([]);

  const [propertyDefinitionExpression, setPropertyDefinitionExpression] = useState('');
  const [filterByMapExtent, setFilterByMapExtent] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorList, setErrorList] = useState([]);
  //const [viewLoaded, setViewLoaded] = useState(false);

  // const [favoriteProperties, setFavoriteProperties] = useState<Set<number>>(new Set());

  const [boundary, setBoundary] = useState<__esri.Geometry>();

  const map = useRef(getMap());
  const view = useRef<__esri.MapView>(
    new MapView({
      map: map.current,
      /*background: {
        color: "white"
      },*/
      //UPDATES FROM NATALIE: fixes touchy zooming
      constraints: { snapToZoom: false },
      //END UPDATES FROM NATALIE
      // zoom: 4,
      center: [-94, 36.798],
      scale: 2311162 * 8,
      popup: {
        //actions:[],
        dockEnabled: true,
        dockOptions: {
          // Disables the dock button from the popup
          buttonEnabled: true,
          // Ignore the default sizes that trigger responsive docking
          breakpoint: false
        }
      },
      ui: { // remove default UI
        components: ["attribution"]
      }
    })
  );

  const [viewLoaded, setViewLoaded] = useState(() => {
    const storedViewLoaded = sessionStorage.getItem('viewLoaded');
    return storedViewLoaded === 'true';
  });

  useEffect(() => {
    if (!viewLoaded) {
      const handle = reactiveUtils.when(
        () => !view.current.updating,
        () => {
          console.log("view done updating");
          setViewLoaded(true);
          sessionStorage.setItem('viewLoaded', 'true');
          handle.remove();
        },
        { once: true }
      );
    }
  }, [viewLoaded]);


  useEffect(() => {
    // Assuming mapView is your MapView object
    // Function to recursively find feature layers within group layers
    const featureLayers: any[] = [];

    const findFeatureLayers = (layer: any) => {
      if (layer.type !== "group") {
        // If the layer is a feature layer, add it to the list
        featureLayers.push(layer);
      } else if (layer.type === "group" && layer.layers) {
        // If the layer is a group layer and has sublayers, recursively search for feature layers
        layer.layers.forEach((sublayer: any) => findFeatureLayers(sublayer));
      }
    };

    // Loop through all layers in the map
    view.current.map.layers.forEach(layer => findFeatureLayers(layer));

    view.current.map.basemap.baseLayers.forEach(layer => findFeatureLayers(layer));

    // Loop through each FeatureLayer and execute a .when() function
    async function featuresCheck() {
      const errors: any = [];
      await Promise.all(featureLayers.map(featureLayer =>
        view.current.whenLayerView(featureLayer)
          .then(function (layerView) {
            // Check if the layerView was created successfully
            if (!layerView) {
              // Error callback function
              console.error(`Failed to load FeatureLayer "${featureLayer.title}": Layer view is null or undefined.`);
              errors.push(featureLayer.title);
            } else {
              // Success callback function
              //console.log(`FeatureLayer "${featureLayer.title}" is loaded successfully.`);
            }
          })
          .catch(function (error) {
            // Error callback function
            console.error(`Failed to load FeatureLayer "${featureLayer.title}":`, error);
            errors.push(featureLayer.title);
          })
      ));

      //console.log(errors);

      if (errors.length > 0) {
        setErrorList(errors);
        setErrorModal(true);
      }
    }

    featuresCheck();

  }, []);

  useEffect(() => {

    // Search for the address and zoom to it
    if (address !== "No address provided") {

      locator.addressToLocations('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer', {
        address: { SingleLine: address },
        outFields: ['*'],
      })
        .then((results: string | any[]) => {
          if (results.length > 0) {
            const location = results[0].location;
            try {

              setTimeout(function () {
                view.current.goTo({
                  target: [location.x, location.y],
                  zoom: 17,
                });
              }, 1000);

              // First create a point geometry
              let point = {
                type: "point",  // autocasts as new Point()
                longitude: location.x,
                latitude: location.y
              };

              // Create a symbol for drawing the point
              let markerSymbol = {
                type: "simple-marker",
                size: "12px",  // autocasts as new SimpleMarkerSymbol()
                color: [255, 255, 255]
              };

              // Create a graphic and add the geometry and symbol to it
              let pointGraphic = new Graphic({
                //@ts-ignore
                geometry: point,
                symbol: markerSymbol
              });

              view.current.graphics.add(pointGraphic)

              // Remove the graphic point after 5 seconds
              setTimeout(() => {
                view.current.graphics.remove(pointGraphic);
              }, 10000);
            }

            catch {

              view.current.goTo({
                target: [location.x, location.y],
                zoom: 15,
              });

            }
          }
          else {
            console.error('No results found for the address.');
          }
        })
        .catch((error: any) => {
          console.error('Error searching for address:', error);
        });
    }
  }, [address]);


  // init map and search widget
  useEffect(() => {
    if (mapDiv.current) {
      view.current.container = mapDiv.current;
    }

    if (searchDiv.current && !searchInitialized.current && props.user?.username) {
      searchInitialized.current = true;
      const searchHistoryTable = new FeatureLayer({ url: props.userTables.searchHistory });

      const historySearchSource = new SearchSource({
        name: 'Search History',
        suggestionsEnabled: true,
        getSuggestions: (params: any) => {
          return searchHistoryTable.queryFeatures({
            where: `user_id = '${props.user?.username}' AND search_str LIKE '%${params.suggestTerm}%'`,
            outFields: ['search_str'],
            returnDistinctValues: true
          }).then(results => {
            return results.features.map((feature, index) => {
              return {
                key: 'name',
                text: feature.attributes.search_str,
                sourceIndex: params.sourceIndex
              };
            })
          })
        },
        getResults: (params: any) => {

          const baseUrl = 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates';
          // TODO: move api key to secure location
          const apiKey = 'AAPK4a57a96900964092a576f686bd2126a9jca2SJSaz2g664UYz4OsM8PkXzICtoKNA6MFVMIvSUPqqUUV823V4BJ-Z_9edstB';
          const address = params.suggestResult.text
          const url = `${baseUrl}?f=pjson&maxLocations=1&address=${encodeURIComponent(address)}&token=${encodeURIComponent(apiKey)}`;

          return esriRequest(url, { responseType: 'json' })
            .then((response) => {
              const results = response.data.candidates.map((candidate: any) => {

                const feature = new Graphic({
                  geometry: new Point({
                    latitude: candidate.location.y,
                    longitude: candidate.location.x
                  }),
                  attributes: {}
                });
                const extent = new Extent({
                  xmax: candidate.extent.xmax,
                  xmin: candidate.extent.xmin,
                  ymax: candidate.extent.ymax,
                  ymin: candidate.extent.ymin,
                  spatialReference: response.data.spatialReference
                });
                return {
                  extent,
                  feature,
                  name: candidate.address
                }
              });
              return results;
            });
        }
      } as any);

      const searchWidget = new EsriSearch({
        view: view.current,
        container: searchDiv.current,
        sources: [historySearchSource],
        locationEnabled: true,
        searchAllEnabled: true,
        includeDefaultSources: true,
        popupEnabled: false,
        maxSuggestions: 4,
        allPlaceholder: 'Address, city, zip, neighborhood...',
        label: 'Address, city, zip, neighborhood...',
      });

      searchWidget.on('search-complete', () => {
        // save search term in AGO table
        const searchTerm = searchWidget.searchTerm;
        if (!searchTerm) return;
        searchHistoryTable.queryFeatureCount({
          where: `user_id = '${props.user?.username}' AND search_str = '${searchTerm}'`
        }).then(count => {
          if (count === 0) {
            searchHistoryTable.applyEdits({
              addFeatures: [new Graphic({
                attributes: {
                  user_id: props.user?.username,
                  search_str: searchTerm
                }
              })]
            });
          }
        });
      });
    }
  }, [props.user?.username, props.userTables]);

  // apply user market filter
  useEffect(() => {
    if (props.user && !fetchedUserInfo.current) {
      executeQueryJSON(props.userTables.userMarkets, {
        where: `user_id = '${props.user.username}'`,
        outFields: ['user_id', 'market'],
        returnGeometry: false
      }).then(response => {
        if (response.features.length > 0) {
          const markets = response.features.map(feature => feature.attributes.market);
          if (!markets.includes('ALL')) {
            const expression = `HL_Market IN (${markets.map(x => `'${x}'`).join(',')})`;
            setPropertyBaseExpression(expression);
            ['Property', 'MarketSummary'].forEach(layerId => {
              const layer = view.current?.map.findLayerById(layerId);
              if (layer) {
                const featureLayer = layer as __esri.FeatureLayer;
                featureLayer.definitionExpression = expression;

                if (layerId === 'Property' && !extentSetFromSavedSearch.current) {
                  featureLayer.queryExtent({
                    where: expression,
                  }).then(response => {
                    if (!extentSetFromSavedSearch.current) {

                      view.current.goTo(response.extent);
                    }
                  });
                }
              }
            });
          } else {
            setPropertyBaseExpression('1=1');
            if (!extentSetFromSavedSearch.current) {

              view.current.zoom = 4;
              view.current.center = new Point({ latitude: 36.798, longitude: -94 });
              view.current.scale = 2311162 * 8;
            }
          }
        }
      }, console.error);


      // filter Aspire layers
      executeQueryJSON(props.userTables.userPlatform, {
        where: `user_id = '${props.user.username}'`,
        outFields: ['user_id', 'platform'],
        returnGeometry: false
      }).then(response => {
        if (response.features.length > 0) {
          const platforms = response.features.map(feature => feature.attributes.platform);
          setMarketVariables(platforms)
          if (!platforms.includes('ALL')) {
            let crmExpression = `Platform IN (${platforms.map(x => `'${x}'`).join(',')})`;
            setCRMBaseExpression(crmExpression);
            ['CRMRecord', 'hoaLayer', 'hoaMarketLayer'].forEach(layerId => {
              const layer = view.current?.map.findLayerById(layerId);
              if (layer) {
                const featureLayer = layer as __esri.FeatureLayer;
                featureLayer.definitionExpression = crmExpression;
              }
            });
            // typo in field name
            const contractExpression = `Platfrom IN (${platforms.map(x => `'${x}'`).join(',')})`;
            setContractBaseExpression(contractExpression);
            ['ContractProperty', 'ContractHeatMap'].forEach(layerId => {
              const layer = view.current?.map.findLayerById(layerId);
              if (layer) {
                const featureLayer = layer as __esri.FeatureLayer;
                featureLayer.definitionExpression = contractExpression;
              }
            });
          } else {
            setCRMBaseExpression('1=1');
            setContractBaseExpression('1=1');
          }
        }
      }, console.error);
    }
  }, [props.user, props.userTables]);


  useEffect(() => {
    let session: Session | null = null;
    if (location.state && (location.state as any).search) {
      session = (location.state as any).search as Session;
    } else {
      const sessionJson = sessionStorage.getItem(filtersStorageKey);
      if (sessionJson) {
        session = JSON.parse(sessionJson) as Session;
      }
    }
    if (session) {
      if (session.data.mapExtent) {
        //console.log("there is extent", session.data.mapExtent)
        extentSetFromSavedSearch.current = true;
        const extent = new Extent(session.data.mapExtent)
        reactiveUtils.when(() => view.current.ready, () => {
          view.current.goTo(extent);
        }, {once: true});
      }

      if (session.data.filterByMapExtent !== undefined) {
        setFilterByMapExtent(session.data.filterByMapExtent);
      }

      if (session.data.boundary) {
        // add boundary to graphics layer
        const geometry = new Polygon(session.data.boundary);
        setBoundary(geometry);
        const drawLayer = view.current?.map.findLayerById('DrawBoundary');
        if (drawLayer) {
          (drawLayer as __esri.GraphicsLayer).add(new Graphic({
            geometry,
            symbol: props.drawSymbol
          }));
        }

      }
    }
  }, [location.state, props.drawSymbol]);

  // add map widgets
  useEffect(() => {
    if (!mapUIInitialized.current) {
      mapUIInitialized.current = true;
      //handle = reactiveUtils.when(() => view.current,
      //() => {
      const topLeftNode = document.createElement("div");
      const drawNode = document.createElement("div");
      view.current.ui.add(
        [
          {
            component: topLeftNode,
            position: "top-left",
            index: 0
          },
          {
            component: drawNode,
            position: "top-right",
            index: 0
          }
        ]
      );
      ReactDOM.render(
        <div className="top-left-btns">
          <ZoomExtent view={view} />
          <Measurement view={view.current} map={map.current} />
        </div>,
        topLeftNode
      );
      ReactDOM.render(<Draw symbol={props.drawSymbol} view={view} />,
        drawNode
      );
    }


    let drawEventListener: IHandle | null = null;
    const drawLayer = map.current.findLayerById('DrawBoundary');
    if (drawLayer) {
      const drawBoundaryLayer = drawLayer as __esri.GraphicsLayer;
      drawEventListener = drawBoundaryLayer.graphics.on('after-changes', () => {
        //console.log("change")

        // create multi polygon
        if (drawBoundaryLayer.graphics.length > 0) {
          const graphics = drawBoundaryLayer.graphics;
          let rings: number[][][] = [];
          const spatialReference: __esri.SpatialReference = graphics.getItemAt(0).geometry.spatialReference;
          graphics.forEach(graphic => {
            const polygon = graphic.geometry as __esri.Polygon;
            rings = [...rings, ...polygon.rings];
          })

          const polygon = new Polygon({
            rings,
            spatialReference
          });
          setBoundary(polygon);
        } else {
          setBoundary(undefined);
        }

      });
    }

    return () => {
      //if (handle) {
      //handle.remove();
      //}
      if (drawEventListener) {
        drawEventListener.remove();
      }
    }
  }, [props.drawSymbol])

  const onClickToPropList = () => {
    // For Mobile, Click property then
    // opens property list panel
    setMobilePage('propList');
  }

  const leftButtons =
    mobilePage === 'map' || mobilePage === 'filters' ?
      <Button kind="ghost" className="mobile-link" onClick={() => setMobilePage('propList')}>
        List
      </Button>
      :
      <Button kind="ghost" className="mobile-link" onClick={() => setMobilePage('map')}>
        Map
      </Button>


  //UPDATES FROM NATALIE: Retain Filters on click (no change to Map)
  const rightButtons =
    mobilePage === 'map' || mobilePage === 'propList' ?
      <Button kind="ghost" className="mobile-link" onClick={() => setMobilePage('filters')}>
        Filters
      </Button>
      :
      <Button kind="ghost" className="mobile-link" onClick={() => setMobilePage('map')}>
        Filters
      </Button>
  //END UPDATES FROM NATALIE  

  /** rerendering the page caused map and property list to freeze, 
   * inline style used to hide and show pages in mobile view */
  const mobilePageHandler = (page: string) => {
    if (isMobile) {
      if (mobilePage === page) {
        return true;
      } else if (mobilePage === page) {
        return true;
      } else if (mobilePage === page) {
        return true;
      } else return false;
    } else return true;
  }

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  return (
    <div className="prospecting">
      {/* <Filters view={view} filterByMapExtent={filterByMapExtent} toggleFilterByMapExtent={toggleFilterByMapExtent}/> */}
      <div className="filters-bar">
        {isMobile && leftButtons}
        <div ref={searchDiv}></div>
        {isMobile && rightButtons}
        {!isMobile &&
          <>
            <Filters view={view}
              //property={props.property}
              propertyBaseFilterExpression={propertyBaseExpression}
              crmRecordBaseFilterExpression={crmRecordBaseExpression}
              contractBaseFilterExpression={contractBaseExpression}
              //MHIBaseFilterExpression={MHIBaseExpression}
              setPropertyDefinitionExpression={(value: string) => { setPropertyDefinitionExpression(value); }}
              filterByMapExtent={filterByMapExtent}
              setFilterByMapExtent={setFilterByMapExtent}
              favoriteProperties={props.savedProperties}
              boundary={boundary}
              savedSessionsUrl={props.userTables.savedSessions}
              user={props.user}
              filtersStorageKey={filtersStorageKey}
            />
          </>
        }
      </div>
      <div className="main-view-container">
        <div className="map-container" ref={mapDiv} style={{ display: mobilePageHandler('map') ? '' : 'none' }}>
          {/*<div className="map-cover" style={{ visibility: viewLoaded ? 'hidden' : 'visible' }}>
            <InlineLoading />
            <p>
              Loading Map Content
            </p>
          </div>*/}
          <ComposedModal
            open={errorModal}
            //name="Aspire Form"
            //primaryButtonText="Send to Aspire"
            //secondaryButtonText="Cancel"
            //onRequestClose={closeErrorModal}
            size="md" //temporary set as medium until layering of elements is solved
            //size="lg"
            preventCloseOnClickOutside={true}
            className="feature_error_modal">
            <ModalHeader className="feature_error_modal_header">
              Error loading data from server

            </ModalHeader>
            <ModalBody className="feature_error_modal_body">
              <p className="cds--modal-content__text cds--modal-content__regular-content">
                The following layers were unable to load from the ArcGIS servers:
              </p>
              <br />
              {errorList.map((item, index) => (
                <p key={index} className="cds--modal-content__text_italic">{item}</p>
              ))}
              <br />
              <p className="cds--modal-content__text cds--modal-content__regular-content">
                Reload the page to attempt successful load of layers, or continue for limited usage in SR Sales
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                kind="secondary"
                onClick={() => { window.location.reload() }}>
                Reload Page
              </Button>
              <Button
                kind="danger"
                onClick={() => { closeErrorModal() }}>
                Continue
              </Button>
            </ModalFooter>
          </ComposedModal>
          <div className="notification-container">
            {/*UPDATES FROM NATALIE: Changed InlineNotification to ToastNotification*/}
            <ToastNotification
              className="get-started-notification"
              kind="info"
              lowContrast={true}
              statusIconDescription="Get started"
              title="Get started" //"View recommended properties"
              subtitle="Select filters or draw a boundary"
              //"Select filters or draw a boundary to reduce the total number of properties to less than 10,000."
              //UPDATES FROM NATALIE: Get started goes away after 10 secs
              timeout={10000}
            //hideCloseButton={true} 
            //END UPDATES FROM NATALIE 
            />
          </div>
        </div>
        <div className="property-list-container" style={{ display: mobilePageHandler('propList') ? '' : 'none' }}>
          <PropertyList view={view}
            definitionExpression={propertyDefinitionExpression}
            favoriteProperties={props.savedProperties}
            favoriteProperty={props.favoriteProperty}
            unfavoriteProperty={props.unfavoriteProperty}
            user={props.user}
            notesTable={props.notesTable}
            notedProperties={props.notedProperties}
            addNoteToProperty={props.addNoteToProperty}
            deleteNote={props.deleteNote}
            updateNote={props.updateNote}
            boundary={boundary}
            filterByMapExtent={filterByMapExtent}
            onClickToPropList={onClickToPropList}
            marketVariables={marketVariables}
          />
        </div>
        {isMobile &&
          <div id="filter-panel" className="filters-panel" style={{ display: mobilePageHandler('filters') ? '' : 'none' }}>
            <Filters view={view}
              //property={props.property}
              propertyBaseFilterExpression={propertyBaseExpression}
              crmRecordBaseFilterExpression={crmRecordBaseExpression}
              contractBaseFilterExpression={contractBaseExpression}
              //MHIBaseFilterExpression={MHIBaseExpression}
              setPropertyDefinitionExpression={(value: string) => { setPropertyDefinitionExpression(value); }}
              filterByMapExtent={filterByMapExtent}
              setFilterByMapExtent={setFilterByMapExtent}
              favoriteProperties={props.savedProperties}
              boundary={boundary}
              savedSessionsUrl={props.userTables.savedSessions}
              user={props.user}
              filtersStorageKey={filtersStorageKey}
            />
          </div>
        }
        <style>
          {/* leave this here */}
          {/* parent div needs to have overlow hidden on prospecting page, but not others */}
          {`.cds--content {overflow: hidden;}`}
        </style>
      </div>
    </div>

  );
}