export function roundString(value: number | null, decimals: number) {
  if (value === null) {
    value = 0;
  }
  return (Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals);
}

export function formatNumber(value: number | null) {
  if (value === null) return "n/a"
  
  return value.toString().replace(/(.)(?=(\d{3})+$)/g, '$1,') + " sq ft"
}

export function formatPhoneNumber(phoneNumber:number | null | undefined) {
  if (phoneNumber) {
    const phoneNumberString = phoneNumber?.toString();
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
  return "Phone number not available";
}