import React, { useEffect, useRef, useState, useCallback, MutableRefObject, useMemo } from "react";
import "./CRMupload.scss";
import { useNavigate } from "react-router-dom";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Property from "../../models/property";
import Session from "../../models/session";
//import getMap from "./SAmap";
import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../responsive';
import TemplateExport from "./TemplateExport"
import contactTemplate from '../../images/contact-template.png';
import { Aspire_URL_Test } from "../../SendToAspire/SendToAspire.test";
import { Aspire_URL_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { Aspire_URL_Prod } from "../../SendToAspire/SendToAspire.Prod";
import { Client_ID_Test } from "../../SendToAspire/SendToAspire.test";
import { Client_ID_Dev } from "../../SendToAspire/SendToAspire.Dev";
//import { Client_ID_Prod } from "../../../../SendToAspire/SendToAspire.Prod";
import { Secret_Test } from "../../SendToAspire/SendToAspire.test";
import { Secret_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { credentials } from "../../SendToAspire/APIcreds";
import ZoomInfo from "./ZoomInfo";
import ZoomInfoItem from "../shared/zoom-info-item/ZoomInfoItem";
import ContactExport from './ContactExport/ContactExport'

import {
  Column,
  Grid,
  InlineLoading,
  ToastNotification,
  Modal,
  FormItem,
  Select,
  SelectItem,
  FileUploaderDropContainer,
  TextInput,
  FileUploaderItem,
  ProgressBar,
  IconButton,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  //@ts-ignore
} from '@carbon/react';

import { Link } from "react-router-dom";

import {
  ArrowRight,
  Replicate,
  CheckmarkFilled,
  Misuse,
  WarningAltFilled,
  CloudUpload,
  TaskView,
  Close
  // @ts-ignore
} from '@carbon/icons-react';
import SavedSearchListItem from "../shared/saved-search-list-item/SavedSearchListItem";

import {
  Button
  //@ts-ignore
} from '@carbon/react';
import { filter } from "@arcgis/core/core/promiseUtils";
import Credential from "@arcgis/core/identity/Credential";
import { time } from "console";

interface iProps {
  user: __esri.PortalUser | null;
  propertyLayerUrl: string;
  savedSessionsTableUrl: string;
  favoritePropertiesTableUrl: string;
  notedProperties: Set<number>;
  savedProperties: Set<number> | undefined;
  removeSavedSearch: (objectId: number) => void;
  renameSavedSearch: (objectId: number, newName: string) => void;
  userTables: {
    searchHistory: string;
    savedSessions: string;
    notes: string;
    userMarkets: string;
    favoriteProperties: string;
    userPlatform: string;
  };
}

interface RowData {
  [key: string]: string;
}

// Define the type for credentials
type Credentials = {
  [market: string]: {
    ALIAS: string;
    CLIENTID: string;
    SECRET: string;
  };
};

const filtersStorageKey = 'smartreach-filters';

export default function CRMupload(props: iProps) {

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [mobilePage, setMobilePage] = useState<string>('map');

  const [exportListModal, setExportListModal] = useState(false)
  const [searchType, setSearchType] = useState<string>('Contact');
  const [uploadType, setUploadType] = useState<string>('Contacts');
  const [companyID, setCompanyID] = useState<Number>(0);
  const [proceedModal, setProceedModal] = useState(false)
  const [clientSecret, setClientSecret] = useState({
    clientID: "",
    secret: ""
  })

  const updateSessionStorageItem = (updates: any) => {
    // Retrieve the existing state from sessionStorage
    const savedState = sessionStorage.getItem('crmUploadState');
    const parsedState = savedState ? JSON.parse(savedState) : {};

    // Update each key with the new value
    Object.keys(updates).forEach(key => {
      parsedState[key] = updates[key];
    });

    // Save the updated state back to sessionStorage
    sessionStorage.setItem('crmUploadState', JSON.stringify(parsedState));
  };


  const [fieldMappings, setFieldMappings] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    mobilePhone: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    stateName: '',
    zipcode: '',
    title: '',
    properties: {

      propertyName: '',
      propertyAddress: '',
      propertyCity: '',
      propertyState: '',
      propertyZipCode: '',
      propertyIndustry: '',
      propertyTax: 0,
      propertyPayTerms: 0,

    }
  });

  // Helper function to get initial state from sessionStorage
  const getInitialState = () => {
    const savedState = sessionStorage.getItem('crmUploadState');
    const defaultState = {
      exportList: [],
      selectedInstance: undefined,
      filteredCredentials: {},
      csvData: [],
      selectedFile: "",
      upload: null,
      progress: 0,
      timeRemaining: "",
      uploadResults: {
        successCount: 0,
        errorCount: 0,
        existCount: 0,
        existNames: [],
        errorNames: []
      },
      resultsModal: false,
    };

    //console.log(savedState ? { ...defaultState, ...JSON.parse(savedState) } : defaultState)
    // If savedState exists, merge it with the default state to ensure all fields are present
    return savedState ? { ...defaultState, ...JSON.parse(savedState) } : defaultState;
  };

  // Initialize state with values from sessionStorage or defaults
  const [exportList, setExportList] = useState(() => getInitialState().exportList);
  const [selectedInstance, setSelectedInstance] = useState(() => getInitialState().selectedInstance);
  const [filteredCredentials, setFilteredCredentials] = useState<Credentials>(() => getInitialState().filteredCredentials);
  const [csvData, setCSVData] = useState<RowData[]>(() => getInitialState().csvData);
  const [selectedFile, setSelectedFile] = useState<string>(() => getInitialState().selectedFile);
  const [upload, setUpload] = useState<Boolean | null>(() => getInitialState().upload);
  const [progress, setProgress] = useState(() => getInitialState().progress);
  const [timeRemaining, setTimeRemaining] = useState(() => getInitialState().timeRemaining);
  const [uploadResults, setUploadResults] = useState(() => getInitialState().uploadResults);
  const [resultsModal, setResultsModal] = useState(() => getInitialState().resultsModal);

  // Use useMemo to create combinedState object
  const crmUploadState = useMemo(() => ({
    exportList,
    selectedInstance,
    filteredCredentials,
    csvData,
    selectedFile,
    upload,
    progress,
    timeRemaining,
    uploadResults,
    resultsModal
  }), [
    exportList,
    selectedInstance,
    filteredCredentials,
    csvData,
    selectedFile,
    upload,
    progress,
    timeRemaining,
    uploadResults,
    resultsModal
  ]);

  console.log(filteredCredentials)

  const progressRef = useRef<number>(progress);

  useEffect(() => {
    progressRef.current = progress
  }, [progress])

  // Use useEffect to store the combined state in sessionStorage whenever it changes
  useEffect(() => {
    const storedState = sessionStorage.getItem('crmUploadState');
    const parsedStoredState = storedState ? JSON.parse(storedState) : null;

    if (JSON.stringify(crmUploadState) !== JSON.stringify(parsedStoredState)) {
      sessionStorage.setItem('crmUploadState', JSON.stringify(crmUploadState));
    }
  }, [crmUploadState]);

  // Use useEffect to periodically check sessionStorage for updates
  useEffect(() => {
    const interval = setInterval(() => {
      const storedState = sessionStorage.getItem('crmUploadState');
      const parsedStoredState = storedState ? JSON.parse(storedState) : null;

      if (parsedStoredState) {
        if (parsedStoredState.progress !== progress) {
          setProgress(parsedStoredState.progress);
        }

        if (parsedStoredState.timeRemaining !== timeRemaining) {
          setTimeRemaining(parsedStoredState.timeRemaining);
        }
      }
    }, 10); // Check every 10th of a second

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [progress, timeRemaining]);

  // Function to get the current state from session storage
  const getSessionStorageState = () => {
    const savedState = sessionStorage.getItem('crmUploadState');
    return savedState ? JSON.parse(savedState) : null;
  };

  // Function to update session storage with a new state
  const updateSessionStorageState = (newState: any) => {
    sessionStorage.setItem('crmUploadState', JSON.stringify(newState));
  };

  // Function to increment a specific upload result count
  const incrementUploadResultCount = (countType: string | number) => {
    const currentState = getSessionStorageState() || null;
    currentState.uploadResults[countType] += 1;
    updateSessionStorageState(currentState);
  };

  useEffect(() => {
    if (props.user) {

      // filter Aspire Credentials based on user
      executeQueryJSON(props.userTables.userPlatform, {
        where: `user_id = '${props.user.username}'`,
        outFields: ['user_id', 'platform'],
        returnGeometry: false
      }).then(response => {
        if (response.features.length > 0) {
          const platforms = response.features.map(feature => feature.attributes.platform);
          //setMarketVariables(platforms)
          if (!platforms.includes('ALL')) {
            // Filter Aspire credentials based on allowed markets
            const filteredCredentials = Object.fromEntries(
              Object.entries(credentials).filter(([key, value]) => platforms.includes(key))
            );
            // Use filtered credentials
            setFilteredCredentials(filteredCredentials);
          } else {
            setFilteredCredentials(credentials)
          }
        }
      }, console.error);
    }
    if (window.location.href == "http://localhost:3000/contacts") {
      setClientSecret({
        clientID: Client_ID_Test,
        secret: Secret_Test
      });
    }
  }, [props.user, props.userTables]);



  const firstName = props.user?.fullName ? props.user.fullName.split(" ")[0] : '';

  const mobilePageHandler = (page: string) => {
    if (isMobile) {
      if (mobilePage === page) {
        return true;
      } else if (mobilePage === page) {
        return true;
      } else if (mobilePage === page) {
        return true;
      } else return false;
    } else return true;
  }

  // Event handler for file selection
  const handleFileSelect = (event: any) => {
    event.preventDefault(); // Prevent default behavior of opening file in browser
    setUpload(false);

    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e!.target!.result as string;
      const rows = fileContent.split('\n');
      const fieldNames = parseCSVRow(rows[0]);
      const rowData = [];

      for (let i = 1; i < rows.length; i++) {
        const rowValues = parseCSVRow(rows[i]);

        // Check if all values in the row are empty
        const isEmptyRow = rowValues.every(value => value.trim() === '');

        // If the row is not empty, process it
        if (!isEmptyRow) {
          const rowDict: { [key: string]: string } = {};

          for (let j = 0; j < fieldNames.length; j++) {
            if (rowValues[j]) {
              let cleanedValue = rowValues[j].replace(/(^"|"$)/g, '').trim();
              const cleanedField = fieldNames[j].replace(/(^"|"$)/g, '').trim();
              rowDict[cleanedField] = cleanedValue;
            } else {
              rowDict[fieldNames[j]] = '';
            }
          }

          rowData.push(rowDict);
        }
      }

      setCSVData(rowData);
    };
    if (event.type === "change") {
      setSelectedFile(event.target.files[0].name);
      reader.readAsText(event.target.files[0]);
    } else if (event.type === "drop") {
      setSelectedFile(event.dataTransfer.files[0].name);
      reader.readAsText(event.dataTransfer.files[0]);
    }
  };

  // Helper function to parse a CSV row
  const parseCSVRow = (row: string): string[] => {
    const result = [];
    let currentCell = '';
    let insideQuotes = false;

    for (let i = 0; i < row.length; i++) {
      const char = row[i];

      if (char === '"' && (i === 0 || row[i - 1] !== '\\')) {
        insideQuotes = !insideQuotes;
      } else if (char === ',' && !insideQuotes) {
        result.push(currentCell);
        currentCell = '';
      } else {
        currentCell += char;
      }
    }

    // Add the last cell
    result.push(currentCell);

    return result.map(cell => cell.trim());
  };



  const handleFieldMappingChange = (field: string, value: string) => {
    setFieldMappings({ ...fieldMappings, [field]: value });
  };

  const handleFieldMappingChangeProperty = (nestedField: string, value: string) => {
    setFieldMappings({
      ...fieldMappings,
      properties: {
        ...fieldMappings.properties,
        [nestedField]: value
      }
    });
  };

  // Event handler for file selection
  const handleFileDelete = (event: any) => {
    setSelectedFile(''); // Set the selected file to state
    setCSVData([])
    sessionStorage.setItem('csvData', JSON.stringify([]))
    sessionStorage.setItem('selectedFile', '')
    setUpload(false)
  };

  let Aspire_URL = ""

  if (window.location.href == "http://localhost:3000/contacts") {
    Aspire_URL = Aspire_URL_Test;
  }

  if (window.location.href == "https://dev.smartreach.app/contacts") {
    Aspire_URL = Aspire_URL_Dev;
    //Aspire_URL = Aspire_URL_Test;
  }

  if (window.location.href == "https://smartreach.app/contacts") {
    Aspire_URL = Aspire_URL_Prod;
  }

  function authPOST() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "clientId": clientSecret.clientID,
      "secret": clientSecret.secret
    });

    let requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    // AUTHORIZATION REQUEST THAT WILL USE CLIENT ID AND SECRET FOR EACH OPCO
    return fetch(Aspire_URL + "/Authorization", requestOptions)
      .then(response => {
        if (!response.ok) {
          //console.error('Network response was not ok');
          return Promise.reject('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        const bearer_token = result.Token;
        const bearer = 'Bearer ' + bearer_token;
        return bearer as any;
      })
      .catch(error => {
        //console.error('Error:', error);
        throw error; // Re-throw the error to propagate it to the caller
      });
  }


  function companyGET(bearer: any, item: any) {

    let myHeaders = new Headers({
      "Authorization": bearer,
      "Content-Type": "application/json"
    });

    let requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let encodedCompanyName = encodeURIComponent(item['CompanyName'] || item[fieldMappings.companyName.trim()]);
    let urlcomp = `${Aspire_URL}/Companies?$filter=CompanyName eq '${encodedCompanyName}'`;

    return fetch(urlcomp, requestOptions)
      .then(response => {
        if (!response.ok) {
          //console.error('Network response was not ok');
          return Promise.reject('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        if (result.length === 0) {
          //console.log("No company found");
          return Promise.resolve(0 as any);
        } else {
          return Promise.resolve(result[0].CompanyID);
        }
      })
      .catch(error => {
        //console.error('Error:', error);
        return Promise.reject(error);
      });
  }

  function companyPOST(bearer: any, CompanyID: any, item: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (CompanyID !== 0) {
        //console.log('Company already exists in Aspire');
        // No need to setCompanyID here as it's already set
        resolve(CompanyID);
      } else {
        let myHeaders = new Headers({
          "Authorization": bearer,
          "Content-Type": "application/json"
        });

        var raw = JSON.stringify({
          "CompanyName": item["CompanyName"] || item[fieldMappings.companyName.trim()] || null,
          "Active": true
        });

        let requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        // COMPANIES POST REQUEST THAT CREATES A NEW COMPANY IN ASPIRE FROM CURRENT PROPERTY'S COMPANY
        fetch(Aspire_URL + "/Companies", requestOptions)
          .then((res) => {
            //console.log(requestOptions);
            if (res.status === 400) {
              resolve(0); // Resolve with a default value if needed
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result !== undefined && result !== '' && result !== null && result !== ' ') {
              setCompanyID(result.CompanyID);
              resolve(result.CompanyID);
            } else {
              setCompanyID(0);
              resolve(0);
            }
          })
          .catch(error => {
            //console.error('Error:', error);
            reject(error);
          });
      }
    });
  }

  function contactGET(bearer: any, item: any) {

    const firstName = item['FirstName'] || item[fieldMappings.firstName.trim()];
    const lastName = item['LastName'] || item[fieldMappings.lastName.trim()];
    const email = item['Email'] || item[fieldMappings.email.trim()];

    let url = `${Aspire_URL}/Contacts?%24filter=Email%20eq%20%27${encodeURIComponent(email)}%27`/*%20and%20LastName%20eq%20%27${encodeURIComponent(lastName)}%27`*/;
    //console.log(url)

    let requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        "Authorization": bearer,
        "Content-Type": "application/json"
      },
      redirect: 'follow'
    };

    return fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          //console.error('Network response was not ok');
          return Promise.reject('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        if (result.length === 0) {
          //console.log("No contact found");
          return 0 as any;
        } else {
          return result[0].ContactID as any;
        }
      })
      .catch(error => {
        //console.error('Fetch error:', error);
        return Promise.reject(error);
      });
  }

  function contactTYPE(bearer: any) {

    const raw = "";

    let requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        "Authorization": bearer,
        "Content-Type": "application/json"
      },
      redirect: 'follow'
    };

    return fetch(Aspire_URL + "/ContactTypes?$select=ContactTypeName,ContactTypeID&$filter=Active%20eq%20true%20and%20ContactTypeName%20eq%20%27Prospect%27&$top=1", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.length === 0) {
          //console.log("No ContactType found");
          return Promise.resolve(0 as any);
        } else {
          return Promise.resolve(result[0].ContactTypeID);
        }
      })
      .catch(error => { return Promise.reject(error) });
  }

  function contactPOST(bearer: any, ContactID: any, CompanyID: any, item: any, contactTypeID: any, currentItem: number) {
    if (ContactID) {

      let interProgress = progressRef.current
      //console.log('Contact already exists in Aspire');
      setUploadResults((prevState: { existNames: number[]; existCount: number; }) => ({
        ...prevState,
        existNames: [...(prevState.existNames ?? []), interProgress],
        existCount: prevState.existCount + 1,
      }));
      incrementUploadResultCount('existCount');
      return Promise.resolve(0 as any);
    }
    else {

      let stateProvinceCode = null
      let stateProvinceName = null

      if (!item['StateProvinceCode']) {
        if (item[fieldMappings.stateName.trim()].length > 2)
          stateProvinceName = item[fieldMappings.stateName.trim()]
        else if (item[fieldMappings.stateName.trim()].length === 2) {
          stateProvinceCode = item[fieldMappings.stateName.trim()]
        }
      }
      else if (item['StateProvinceCode']) {
        if (item['StateProvinceCode'].length > 2)
          stateProvinceName = item['StateProvinceCode']
        else if (item['StateProvinceCode'].length === 2) {
          stateProvinceCode = item['StateProvinceCode']
        }
      }

      let myHeaders = new Headers({
        "Authorization": bearer,
        "Content-Type": "application/json"
      });

      let raw = JSON.stringify({
        "Contact": {
          "FirstName": item['FirstName'] || item[fieldMappings.firstName.trim()] || null,
          "LastName": item['LastName'] || item[fieldMappings.lastName.trim()] || null,
          "CompanyID": CompanyID,
          //"CompanyName": item['CompanyName'] || null,
          "ContactTypeID": contactTypeID,
          "Title": item['Title'] || item[fieldMappings.title.trim()] || null,
          "Email": item['Email'] || item[fieldMappings.email.trim()] || null,
          "MobilePhone": item['MobilePhone'] || item[fieldMappings.mobilePhone.trim()] || null,
          "OfficePhone": item['OfficePhone'] || item[fieldMappings.phone.trim()] || null,
          "Active": true
        },
        "OfficeAddress": {
          "AddressLine1": item['AddressLine1'] || item[fieldMappings.address.trim()] || null,
          "AddressLine2": null,
          "City": item['City'] || item[fieldMappings.city.trim()] || null,
          "StateProvinceCode": stateProvinceCode || "",
          "StateProvinceName": stateProvinceName || null,
          "ZipCode": item['ZipCode'] || item[fieldMappings.zipcode.trim()] || null,
        }
      });

      let requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let OuchFactor = '';

      // CONTACTS POST REQUEST THAT WILL CREATE A NEW CONTACT IN ASPIRE FROM CURRENT PROPERTY'S CONTACT
      return fetch(Aspire_URL + "/Contacts", requestOptions)
        .then((res) => {
          //console.log(requestOptions);
          if (res.status === 400) {
            OuchFactor = "BigOuch";
            //console.log(OuchFactor);
            return Promise.resolve(null);
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result !== null && OuchFactor !== "BigOuch") {
            setUploadResults((prevState: { successCount: number; }) => ({ ...prevState, successCount: prevState.successCount + 1 }));
            incrementUploadResultCount('successCount');
            return result;
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setUploadResults((prevState: { errorNames: number[]; errorCount: number; }) => ({
            ...prevState,
            errorNames: [...(prevState.errorNames ?? []), currentItem || progress],
            errorCount: prevState.errorCount + 1,
          }));
          incrementUploadResultCount('errorCount');
          return Promise.reject(error);
        });
    }
  }

  function propertiesGET(bearer: any, ContactID: any, CompanyID: any, item: any) {

    const address = item['AddressLine1'] || item[fieldMappings.address.trim()];

    return new Promise((resolve, reject) => {
      let myHeaders = new Headers({
        "Authorization": bearer,
        "Content-Type": "application/json"
      });

      let startstring = encodeURIComponent(address);

      let requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      let url = `${Aspire_URL}/Properties?%24filter=PropertyAddressLine1%20eq%20%27${startstring}%27`;

      fetch(url, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(result => {
          if (result.length === 0) {
            //console.log("No property found");
            resolve(false); // Resolve with false if no property found
          } else {
            //console.log("Property found");
            resolve(true); // Resolve with true if property found
          }
        })
        .catch(error => {
          //console.error('Error during fetch:', error);
          reject(error); // Reject with the error
        });
    });
  }

  /*useEffect(() => {
    console.log(uploadResults.errorNames);
    console.log(uploadResults.existNames);
  }, [uploadResults]);*/

  async function uploadToAspire() {
    modalClose()
    setProgress(0)
    setUpload(true);
    const startTime = performance.now();
    if (csvData && selectedInstance) {
      const totalItems = csvData.length; // Get the total number of items
      let currentItem = 0; // Initialize current item number
      const bearer = await authPOST();
      const contactTypeID = await contactTYPE(bearer);
      for (const item of csvData) {
        currentItem++; // Increment current item number
        setProgress(currentItem); // Update progress

        let interProgress = progressRef.current

        if ((item['FirstName'] || fieldMappings.firstName) && (item['LastName'] || fieldMappings.lastName) && (item['CompanyName'] || fieldMappings.companyName) && (item['Email'] || fieldMappings.email)) {
          try {
            //const bearer = await authPOST();
            const companyResult = await companyGET(bearer, item);
            const companyID = await companyPOST(bearer, companyResult, item);
            const contactID = await contactGET(bearer, item);
            await contactPOST(bearer, contactID, companyID, item, contactTypeID, currentItem);
          } catch (error) {
            setUploadResults((prevState: { errorNames: number[]; errorCount: number; }) => ({
              ...prevState,
              errorNames: [...(prevState.errorNames ?? []), interProgress],
              errorCount: prevState.errorCount + 1,
            }));
            incrementUploadResultCount('errorCount');
            console.error('Error uploading data to Aspire:', error);
          }
        } else {
          setUploadResults((prevState: { errorNames: number[]; errorCount: number; }) => ({
            ...prevState,
            errorNames: [...(prevState.errorNames ?? []), interProgress],
            errorCount: prevState.errorCount + 1,
          }));
          incrementUploadResultCount('errorCount');
        }

        // Calculate the time taken for one item
        const currentTime = performance.now();
        const timeTakenPerItem = (currentTime - startTime) / currentItem;

        // Calculate the remaining time based on the remaining number of items
        const remainingItems = totalItems - currentItem;
        const estimatedTimeLeft = remainingItems * timeTakenPerItem;

        // Convert milliseconds to minutes and seconds
        const minutes = Math.floor(estimatedTimeLeft / 60000);
        let seconds = Math.floor((estimatedTimeLeft % 60000) / 1000);

        // Ensure seconds are within the range of 0-59
        seconds = seconds > 59 ? 59 : seconds;

        // Format seconds to have leading zero if less than 10
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        // Display minutes if more than 6 minutes, otherwise display both minutes and seconds
        let timeRemaining;
        if (minutes > 6) {
          timeRemaining = `Estimated time remaining: ${minutes} minutes`;
        } else {
          timeRemaining = `Estimated time remaining: ${minutes} minutes ${formattedSeconds} seconds`;
        }

        setTimeRemaining(timeRemaining)

        const progressDict = {
          time: timeRemaining,
          progress: currentItem
        }

        //sessionStorage.setItem("uploadProgress", JSON.stringify(progressDict))
        updateSessionStorageItem({ timeRemaining: timeRemaining, progress: currentItem });
      }
    }
    setFieldMappings({
      firstName: '',
      lastName: '',
      companyName: '',
      mobilePhone: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      stateName: '',
      zipcode: '',
      title: '',
      properties: {

        propertyName: '',
        propertyAddress: '',
        propertyCity: '',
        propertyState: '',
        propertyZipCode: '',
        propertyIndustry: '',
        propertyTax: 0,
        propertyPayTerms: 0,
      }
    })
    setResultsModal(true)
    updateSessionStorageItem({ resultsModal: true });
  }


  // Calculate helper text based on progress
  let helperText = `Processed ${progress} of ${csvData.length} items`;

  if (progress === csvData.length) {
    helperText = 'Upload complete';
  }

  const proceedFunct = () => {
    if (csvData.length > 0 && selectedInstance) {
      setProceedModal(true)
    }
    else {
      alert('Please choose an Aspire instance and import a CSV before uploading.')
    }
  }

  const modalClose = () => {
    setProceedModal(false)
  }

  const closeResultsModal = () => {
    setResultsModal(false);
    setUploadResults({
      successCount: 0,
      errorCount: 0,
      existCount: 0,
      existNames: [],
      errorNames: []
    })
  }

  const closeExportModal = () => {
    setExportListModal(prevState => !prevState);
  }

  const handleInstanceChange = (instanceName: any) => {
    setSelectedInstance(instanceName); // Set the selected instance

    if (window.location.href == "https://dev.smartreach.app/contacts") {
      setClientSecret({
        //@ts-ignore
        clientID: credentials[instanceName].CLIENTID,
        //@ts-ignore
        secret: credentials[instanceName].SECRET
      })

      /*setClientSecret({
        clientID: Client_ID_Test,
        secret: Secret_Test
      });*/
    }

    if (window.location.href == "https://smartreach.app/contacts") {
      setClientSecret({
        //@ts-ignore
        clientID: credentials[instanceName].CLIENTID,
        //@ts-ignore
        secret: credentials[instanceName].SECRET
      });
    }
  };

  const exportListFunct = (newItem: any) => {
    // Add the new item to exportList
    setExportList([...exportList, newItem]);
  };

  // After adding the new item, remove duplicates using useEffect
  useEffect(() => {
    // Check if exportList is not empty
    if (exportList.length) {
      // Remove duplicates
      const uniqueData = exportList.filter((item: { id: any; }, index: any, self: any[]) =>
        index === self.findIndex((t: { id: any; }) => t.id === item.id)
      );

      // Update the state with the new array only if there's a change
      if (JSON.stringify(uniqueData) !== JSON.stringify(exportList)) {
        setExportList(uniqueData);
      }
    }
  }, [exportList]); // Run this effect whenever exportList changes

  function deleteContactFunct(idToDelete: any) {

    // Function to delete an item based on its id
    // Filter the data array to exclude the item with the specified id
    const newData = exportList.filter((item: { id: any; }) => item.id !== idToDelete);
    // Update the state with the new array
    setExportList(newData)

    if (newData.length === 0) {

      setExportListModal(false)


    }

  }

  function deleteAllContactFunct() {

    // Update the state with the new array
    setExportList([])

    setExportListModal(false)

  }

  const emptyFunct = () => {
    "nothing"
  }

  return (
    <div className="main-view-container">
      {/*<div className="filters-bar">
        <div ref={searchDiv}></div>
  </div>*/}
      <div className="submission-container">
        {(uploadType === "Contacts" || !isMobile) &&
          <><div className="saved-title">Contacts Bulk Upload</div>
            <div className="divider"></div></>
        }
        {isMobile && uploadType === 'Zoom' &&
          <>
            <span className="exportList">
              <IconButton
                onClick={(e: any) => closeExportModal()}
                label="View export list"
                kind="ghost"
                align="left"
                disabled={exportList.length ? false : true}
              >
                <TaskView size={16} className="is-favorite" />
              </IconButton>
            </span>
            <div className="saved-title">ZoomInfo Search</div>
            <div className="divider"></div>
          </>
        }
        {isMobile &&
          <div className="mode-switch">
            <Button className={uploadType === 'Contacts' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' onClick={(evt: any) => { setUploadType('Contacts') }}>
              Contacts Upload
            </Button>
            <Button className={uploadType === 'Zoom' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' onClick={(evt: any) => { setUploadType('Zoom') }}>
              ZoomInfo Search
            </Button>
          </div>}
        {!csvData.length && (uploadType === "Contacts" || !isMobile) &&
          <div className="submission-tip">
            1. Choose Aspire instance for uploads.
            {filteredCredentials &&
              <Select
                id="instance-select"
                className="instance-select"
                placeholder="Select Value"
                labelText=""
                value={selectedInstance}
                onChange={(e: any) => handleInstanceChange(e.target.value)}
              >
                <SelectItem value="">Select an instance</SelectItem>
                {/*//@ts-ignore*/}
                {Object.keys(filteredCredentials).sort().map((name, index) => (
                  <SelectItem key={index} value={name} text={filteredCredentials[name].ALIAS} />
                ))}
              </Select>
            }
            <p>
              2. Import CSV file containing contact information matching the format in the template below.
            </p>
            <TemplateExport uploadType={uploadType}></TemplateExport>
            <p>
              3. Click "Upload" to send new contacts to Aspire!
            </p>
          </div>
        }
        {(uploadType === "Contacts" || !isMobile) &&
          <>
            <FormItem className="form-item">
              <p className="cds--label-description">
                Supported file type is CSV. One file at a time.
              </p>
              <FileUploaderDropContainer
                accept={[
                  //'.xlsx', // Accept .xlsx files
                  '.csv' // Accept .csv files
                ]}
                labelText="Drag and drop a file here or click to import"
                name=""
                disabled={!selectedInstance}
                multiple={false}
                onAddFiles={handleFileSelect} // Call handleFileSelect when files are added
                onChange={function noRefCheck() { }}
                tabIndex={0} />
              <div className="cds--file-container cds--file-container--drop" />
              {selectedFile && (
                <FileUploaderItem
                  errorBody="500kb max file size. Select a new file and try again."
                  errorSubject="File size exceeds limit"
                  iconDescription="Delete file"
                  name={selectedFile}
                  onDelete={(event: any) => handleFileDelete(event)}
                  size="md"
                  status="edit" />
              )}
              <Button className="upload-button" disabled={!selectedInstance || upload} onClick={() => proceedFunct()}>
                Upload
                <CloudUpload className="upload-icon"></CloudUpload>
              </Button>
              {upload &&
                <><ProgressBar
                  className="progress-bar"
                  value={progress}
                  max={csvData.length}
                  status={progress === csvData.length ? 'finished' : 'active'}
                  label="Uploading items"
                  helperText={helperText}
                  size="big"
                >
                </ProgressBar>
                  {progress < csvData.length && timeRemaining !== "Estimated time remaining: 0 minutes 00 seconds" && (
                    <span className="time-remaining">{timeRemaining}</span>
                  )}
                </>}
            </FormItem><Modal
              open={proceedModal}
              onRequestClose={() => {
                modalClose(); setFieldMappings({
                  firstName: '',
                  lastName: '',
                  companyName: '',
                  mobilePhone: '',
                  phone: '',
                  email: '',
                  address: '',
                  city: '',
                  stateName: '',
                  zipcode: '',
                  title: '',
                  properties: {

                    propertyName: '',
                    propertyAddress: '',
                    propertyCity: '',
                    propertyState: '',
                    propertyZipCode: '',
                    propertyIndustry: '',
                    propertyTax: 0,
                    propertyPayTerms: 0,
                  }
                });
              }}
              //modalHeading="Add a custom domain"
              modalLabel="Bulk Contact Upload to Aspire"
              primaryButtonText="Proceed"
              secondaryButtonText="Cancel"
              onRequestSubmit={() => (uploadToAspire())}
            >
              <p className="modal-notice">
                Notice: If uploaded CSV does not match the fields of the template CSV, please map the below
                fields appropriately. If template CSV was used ignore this and proceed. Required fields
                noted by red asterisk.
              </p>
              {/*<p className="bullets">
      - FirstName
    </p>
    <p className="bullets">
      - LastName
    </p>
    <p className="bullets">
      - CompanyName
    </p>
    <p className="bullets">
      - StateProvinceCode or ZipCode
  </p>*/}
              <div>
                {/* Field mapping selects */}
                <div className="row">
                  <Select
                    id="firstName"
                    placeholder="Select Value"
                    labelText="FirstName"
                    value={csvData[0]?.FirstName ? 'FirstName' : fieldMappings.firstName}
                    onChange={(e: any) => handleFieldMappingChange('firstName', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="lastName"
                    placeholder="Select Value"
                    labelText="LastName"
                    value={csvData[0]?.LastName ? 'LastName' : fieldMappings.lastName}
                    onChange={(e: any) => handleFieldMappingChange('lastName', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                </div>
                <div className="row">
                  <Select
                    id="companyName"
                    placeholder="Select Value"
                    labelText="CompanyName"
                    value={csvData[0]?.CompanyName ? 'CompanyName' : fieldMappings.companyName}
                    onChange={(e: any) => handleFieldMappingChange('companyName', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="title"
                    placeholder="Select Value"
                    labelText="Title"
                    value={csvData[0]?.Title ? 'Title' : fieldMappings.title}
                    onChange={(e: any) => handleFieldMappingChange('title', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                </div>
                <div className="row">
                  <Select
                    id="phone"
                    placeholder="Select Value"
                    labelText="Mobile Phone"
                    value={csvData[0]?.MobilePhone ? 'MobilePhone' : fieldMappings.mobilePhone}
                    onChange={(e: any) => handleFieldMappingChange('phone', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="phone"
                    placeholder="Select Value"
                    labelText="Office Phone"
                    value={csvData[0]?.OfficePhone ? 'OfficePhone' : fieldMappings.phone}
                    onChange={(e: any) => handleFieldMappingChange('phone', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="email"
                    placeholder="Select Value"
                    labelText="Email"
                    value={csvData[0]?.Email ? 'Email' : fieldMappings.email}
                    onChange={(e: any) => handleFieldMappingChange('email', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                </div>
                <div className="row">
                  <Select
                    id="address"
                    placeholder="Select Value"
                    labelText="Address"
                    value={csvData[0]?.AddressLine1 ? 'AddressLine1' : fieldMappings.address}
                    onChange={(e: any) => handleFieldMappingChange('address', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                </div>
                <div className="row">
                  <Select
                    id="city"
                    placeholder="Select Value"
                    labelText="City"
                    value={csvData[0]?.City ? 'City' : fieldMappings.city}
                    onChange={(e: any) => handleFieldMappingChange('city', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="stateName"
                    placeholder="Select Value"
                    labelText="State"
                    value={csvData[0]?.StateProvinceCode ? 'StateProvinceCode' : fieldMappings.stateName}
                    onChange={(e: any) => handleFieldMappingChange('stateName', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                  <Select
                    id="zipCode"
                    placeholder="Select Value"
                    labelText="ZipCode"
                    value={csvData[0]?.ZipCode ? 'ZipCode' : fieldMappings.zipcode}
                    onChange={(e: any) => handleFieldMappingChange('zipcode', e.target.value)}
                  >
                    <SelectItem value="">Select a column</SelectItem>
                    {csvData.length > 0 && Object.keys(csvData[0])
                      .sort() // Sort the keys alphabetically
                      .map((key, index) => (
                        <SelectItem key={index} value={key} text={key} />
                      ))}
                  </Select>
                </div>
              </div>
              {/* Additional code for rendering CSV data and handling field replacements */}
            </Modal>
            <Modal className="results-modal" open={resultsModal} onRequestClose={() => closeResultsModal()} passiveModal modalHeading="Upload Results"
              preventCloseOnClickOutside={true} id="results-modal-container">
              <div className="results-modal-div">
                {uploadResults.successCount > 0 &&
                  <span>
                    <CheckmarkFilled className='success'></CheckmarkFilled>
                    {uploadResults.successCount} {uploadResults.successCount === 1 ? 'contact' : 'contacts'} uploaded successfully
                  </span>
                }

                {uploadResults.existCount > 0 &&
                  <span>
                    <WarningAltFilled className='warning'></WarningAltFilled>
                    {uploadResults.existCount} {uploadResults.existCount === 1 ? 'contact' : 'contacts'} already exist in Aspire and were not uploaded
                    {/*{uploadResults.existNames?.length > 0 && uploadResults.existNames.map((name: string) => (
                      <div>
                        Row {name}
                        <br />
                      </div>
                    ))}*/}
                  </span>
                }

                {uploadResults.errorCount > 0 &&
                  <span>
                    <Misuse className='error'></Misuse>
                    {uploadResults.errorCount} {uploadResults.errorCount === 1 ? 'contact' : 'contacts'} failed to upload due to unpopulated required fields
                    {/*{uploadResults.errorNames?.length > 0 && uploadResults.errorNames.map((name: string) => (
                      <div>
                        Row {name}
                        <br />
                      </div>
                    ))}*/}
                  </span>
                }
              </div>
            </Modal>
          </>
        }
        {isMobile && uploadType === 'Zoom' &&
          /*//@ts-ignore*/
          <ZoomInfo
            isMobile={isMobile}
            uploadType={uploadType}
            exportList={exportListFunct}
            exportListModal={closeExportModal}
            exportListArray={exportList}
            filteredCredentials={filteredCredentials}
            userTables={props.userTables}
            user={props.user}
          >
          </ZoomInfo>
        }
        {exportList.length > 0 && exportListModal &&
          <Modal open={exportListModal} onRequestClose={() => closeExportModal()} passiveModal modalHeading="Contacts for Export"
            preventCloseOnClickOutside={true} className="export-modal-container" id="export-modal-container" label="">
            <IconButton
              className="export-modal-delete-all"
              onClick={((e: any) => deleteAllContactFunct())}
              //label="Remove all contacts"
              kind="ghost"
              align="right"
            //disabled={props.exportListArray.length ? false : true}
            >
              {/*<Close size={16} />*/}
              Remove all contacts
            </IconButton>
            <ContactExport
              uploadType={uploadType}
              contacts={exportList}
            />
            <div className="export-modal-div">
              {exportList?.map((contact: { [key: string]: any; }, index: React.Key | null | undefined) => (
                <>
                  <IconButton
                    className="export-modal-delete"
                    onClick={(e: any) => deleteContactFunct(contact.id)}
                    label="Remove contact"
                    kind="ghost"
                    align="right"
                  //disabled={props.exportListArray.length ? false : true}
                  >
                    <Close size={16} />
                  </IconButton>
                  <ZoomInfoItem
                    key={index}
                    contactResult={contact}
                    contactEnrich={"not function"}
                    userTables={props.userTables}
                    user={props.user}
                    enrichLoading={emptyFunct}
                    creditsModal={emptyFunct}

                  />
                </>
              ))}
            </div>
          </Modal>
        }
      </div>
      {!isMobile &&
        /*//@ts-ignore*/
        <ZoomInfo
          isMobile={isMobile}
          uploadType={uploadType}
          exportList={exportListFunct}
          exportListModal={closeExportModal}
          exportListArray={exportList}
          filteredCredentials={filteredCredentials}
          userTables={props.userTables}
          user={props.user}
        >
        </ZoomInfo>
      }
    </div>
  );
}
