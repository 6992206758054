import {useEffect, useState, useRef, MutableRefObject} from 'react';
import "./zoom-extent.scss";

// Esri Widgets
import Zoom from "@arcgis/core/widgets/Zoom";
//import { when, whenOnce, pausable } from "@arcgis/core/core/watchUtils";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils"

// Carbon Widgets
import {
  Button,
  //@ts-ignore
} from '@carbon/react';

// Carbon Icons
import {
  ZoomIn,
  ZoomOut,
  NextOutline,
  PreviousOutline
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  view: MutableRefObject<__esri.MapView>;
}

export default function ZoomExtent(props: iProps) {
  const [canNext, setCanNext] = useState(true);
  const [canPrev, setCanPrev] = useState(true);

  const currentIndexRef = useRef<number>(0);
  const lastIndexRef = useRef<number>(0);
  const extentsRef = useRef<Array<__esri.Extent | undefined>>([]);
  const zoomRef = useRef(new Zoom({ view: props.view.current }));
  // Handle for pausing watchUtils 
  // so extent is not recorded
  //const pauseRef = useRef<__esri.PausableWatchHandle>();
  const handle = useRef<__esri.WatchHandle>();
  const isWatching = useRef(true); // Flag to control watch execution
  
  const pauseWatch = () => {
    isWatching.current = false;
  }

  const resumeWatch = () => {
    isWatching.current = true;
  }

  useEffect(() => {
    reactiveUtils.when(() => props.view.current.ready, () => {
      reactiveUtils.when(() => props.view.current.extent, () => {
        handle.current = reactiveUtils.when(() => [props.view.current.stationary && isWatching.current], () => {
          //console.log("view is stationary")
          const prevExtent = extentsRef.current[lastIndexRef.current]?.center;
          const prevX = prevExtent?.x;
          const prevY = prevExtent?.y;
          const curX = props.view.current.center.x;
          const curY = props.view.current.center.y;

          if (curX !== prevX && curY !== prevY) {
            extentsRef.current.push(props.view.current.extent);
            lastIndexRef.current = extentsRef.current.length - 1;
            currentIndexRef.current = lastIndexRef.current;
            disableExtentButtons();
          }
        });
      }, { once: true });
    });

    return () => {
      extentsRef.current = [];
    }
  }, [props.view]);

  const disableExtentButtons = () => {
    currentIndexRef.current > 0 ? setCanPrev(false) : setCanPrev(true);
    currentIndexRef.current === lastIndexRef.current ? setCanNext(true) : setCanNext(false);
  }

  const previousBookmark = () => {
    //pauseRef.current?.pause()
    pauseWatch()
    const previous = extentsRef.current[currentIndexRef.current - 1];
    currentIndexRef.current = currentIndexRef.current - 1;
    disableExtentButtons();
    props.view.current.goTo(previous).then(() => resumeExtentWatch());
  }

  const nextBookmark = () => {
    //pauseRef.current?.pause();
    pauseWatch()
    const next = extentsRef.current[currentIndexRef.current + 1];
    currentIndexRef.current = currentIndexRef.current + 1;
    disableExtentButtons();
    props.view.current.goTo(next).then(() => resumeExtentWatch());
  }

  const resumeExtentWatch = () => {
    // also check if zoom buttons are clicked
    props.view.current.on("drag", resumeWatch);
    props.view.current.on("mouse-wheel", resumeWatch);
  }

  const zoomIn = () => {
    resumeWatch()
    zoomRef.current.zoomIn();
  }

  const zoomOut = () => {
    resumeWatch()
    zoomRef.current.zoomOut();
  }

  return (
    <>
      <div className="btn-container">
        <Button
          hasIconOnly={true}
          iconDescription="Zoom in"
          tooltipPosition='right'
          className='button zoom-btn'
          onClick={zoomIn}
          renderIcon={ZoomIn}
        />
        <Button
          hasIconOnly={true}
          iconDescription="Zoom out"
          tooltipPosition='right'
          className='button zoom-btn'
          onClick={zoomOut}
          renderIcon={ZoomOut}
        />
        <Button
          disabled={canNext}
          hasIconOnly={true}
          iconDescription="Next map view" //UPDATES FROM NATALIE: tooltip nomenclature
          tooltipPosition='right'
          className='button extent-btn'
          onClick={nextBookmark}
          renderIcon={NextOutline}
        />
        <Button
          disabled={canPrev}
          hasIconOnly={true}
          iconDescription="Previous map view" //UPDATES FROM NATALIE: tooltip nomenclature
          tooltipPosition='right'
          className='button extent-btn'
          onClick={previousBookmark}
          renderIcon={PreviousOutline}
        />
      </div>
    </>
  )
}