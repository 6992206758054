import React, {useState} from 'react';
import "./navbar.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../responsive';
import MobileNavBar from './MobileNavBar';
import ModalManagerHelp from './ModalManagerHelp';

import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderPanel,
  SkipToContent,
  Switcher,
  SwitcherItem,
  // updates from lydia
  Modal,
  //UPDATES FROM NATALIE: import SwitcherDivider
  SwitcherDivider
  //@ts-ignore
} from '@carbon/react';

import {
  Help,
  Login,
  Logout,
  Switcher as SwitcherIcon,
  //@ts-ignore
} from '@carbon/icons-react';

const links = [
  {
    path: '/start',
    label: 'Start'
  },
  {
    path: '/properties',
    label: 'Properties'
  },
  {
    path: '/contacts',
    label: 'Contacts'
  },
  {
    path: '/new-sales',
    label: 'New Sales'
  }
];

interface iProps {
  userIsSignedIn: boolean,
  user: __esri.PortalUser | null,
  login: () => Promise<void>,
  logout: () => Promise<void>
};


export default function NavBar(props: iProps) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const location = useLocation();
  const navigate = useNavigate();
  //UPDATES FROM NATALIE: Assistance panel
  const [helpPanelExpanded, setHelpPanelExpanded] = useState(false);
  //END UPDATES FROM NATALIE
  const [appPanelExpanded, setAppPanelExpanded] = useState(false);

  // updates from lydia
  //Send property to Aspire
  const [showHelpModal, setShowHelpModal] = useState(false);
  const openHelpModal = () => {
    setShowHelpModal(true);
  }

  const closeHelpModal = () => {
    setShowHelpModal(false);
  }
  // end updates from lydia

  const signIn = () => {
    props.login().then(() => {
      navigate("/start");
    });
  }

  const signOut = () => {
    navigate("/")
    props.logout();
  }

  //UPDATES FROM NATALIE
  //Remove placeholder link
  /*
  const goToHelp = () => {
    window.open("https://heartlandcompany.com", "_blank");
  }*/

  //UPDATES FROM NATALIE: add help panel toggle
  const toggleHelpPanel = () => {
    setHelpPanelExpanded(!helpPanelExpanded);
  }

  const toggleApplicationsPanel = () => {
    setAppPanelExpanded(!appPanelExpanded);
  }

  const signedIn = props.userIsSignedIn && props.user;

  return (
    <Header aria-label="Header" className="navbar">
      {!isMobile ?
        <>
          <HeaderName element={Link} to="/" prefix="">
            <span className="branding">
              <span className="heartland-logo">
                Heartland
              </span>
              <span className='smartreach-logo' >
                <span className='font-normal'>
                  SmartReach
                </span>
                &nbsp;Sales
              </span>
            </span>
          </HeaderName>
          <SkipToContent />
          <HeaderNavigation aria-label="Navigation">
            {
              links.map((link, index) => {
                return (
                  <HeaderMenuItem element={Link} to={link.path} isCurrentPage={location.pathname.startsWith(link.path)} key={index}>
                    {link.label}
                  </HeaderMenuItem>
                );
              })
            }
          </HeaderNavigation>
        </>
        :
        <MobileNavBar links={links}/>
      }
      <HeaderGlobalBar>
        <HeaderGlobalAction
          //UPDATES FROM NATALIE: Assistance panel
          aria-label="Assistance"
          onClick={toggleHelpPanel}
          //aria-label="Help"
          //onClick={goToHelp}
          //END UPDATES FROM NATALIE
        >
          <Help size={20} />
        </HeaderGlobalAction>
        {!signedIn &&
          <HeaderGlobalAction
            onClick={signIn}
            aria-label={"Sign In"}>
            <Login size={20} />
          </HeaderGlobalAction>
        }
        {signedIn &&
          <HeaderGlobalAction
            onClick={signOut}
            aria-label={"Sign Out"}>
            <Logout size={20} />
          </HeaderGlobalAction>
        }
        <HeaderGlobalAction
          aria-label="Applications"
          onClick={toggleApplicationsPanel}
          tooltipAlignment="end">
          <SwitcherIcon size={20} />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
      {/*UPDATES FROM NATALIE: Help panel*/}
      <HeaderPanel aria-label="Help"
        className="help-panel"
        expanded={helpPanelExpanded}
      >
        <div className='help-title'>Assistance</div>
        <Switcher aria-label="Help">
          <SwitcherItem
            href="https://heartlanduniversityofexcellence.myabsorb.com/#/courses"
            target="_blank"
            aria-label="Training">
            Training (LMS)
          </SwitcherItem>

          {/* updates from lydia */}
          <SwitcherItem
            onClick={openHelpModal}
            target="_blank"
            aria-label="FAQ">
            FAQ
          </SwitcherItem>
          {/* end updates from lydia */}

          <SwitcherDivider></SwitcherDivider>
         <SwitcherItem
            href="mailto:data@heartlandcompany.com?subject=SmartReach: Data error report&body=Please specify the data error below."
            target="_blank"
            aria-label="Report a data error">
            Report a data error
          </SwitcherItem>
          <SwitcherItem
            href="mailto:data@heartlandcompany.com?subject=SmartReach: Enhancement request&body=Please specify the enhancement request below."
            target="_blank"
            aria-label="Request an enhancement">
            Request an enhancement
          </SwitcherItem>
          <SwitcherItem
            href="mailto:data@heartlandcompany.com?subject=SmartReach: User feedback&body=Please share your feedback below."
            target="_blank"
            aria-label="Share your feedback">
            Share your feedback
          </SwitcherItem>
          <SwitcherDivider></SwitcherDivider>
          <div id="help-msg">
            For immediate assistance, please contact us at data@heartlandcompany.com
          </div>
        </Switcher>
      </HeaderPanel>
      {/*UPDATES FROM NATALIE: Applications panel*/}
      <HeaderPanel aria-label="Applications"
        className="applications-panel"
        expanded={appPanelExpanded}
      >
        <div className='applications-title'>Applications</div>
        <Switcher aria-label="Applications">
         {/*<SwitcherItem
            href="https://app.powerbi.com/groups/3472f5a9-a65c-46d7-ae99-f2dda5437e07/reports/26262e06-beaa-40fc-8e7c-fb7dc00a2e45/ReportSection71c69d4a6089090d3637?chromeless=1"
            target="_blank"
            aria-label="Aspire Maintenance Dashboard">
            Aspire Maintenance Dashboard
      </SwitcherItem>*/}
          <SwitcherItem
            href="https://cloud.youraspire.com"
            target="_blank"
            aria-label="Aspire">
            Aspire
          </SwitcherItem>
          <SwitcherDivider></SwitcherDivider>
          <SwitcherItem
            href="https://app.goilawn.com/login"
            target="_blank"
            aria-label="GoiLawn">
            GoiLawn
          </SwitcherItem>
          <SwitcherItem
            href="https://app.siterecon.ai/"
            target="_blank"
            aria-label="SiteRecon">
            SiteRecon
          </SwitcherItem>
          <SwitcherItem
            href="https://earth.google.com/"
            target="_blank"
            aria-label="Google Earth">
            Google Earth
          </SwitcherItem>
        </Switcher>
      </HeaderPanel>
      {/*END UPDATES FROM NATALIE*/}

      {/* updates from lydia: help modal*/}
      <Modal passiveModal
        open={showHelpModal}
        modalHeading="Frequently Asked Questions"
        onRequestClose={closeHelpModal}
        size="md"
        preventCloseOnClickOutside={false}
        className="help-modal">
          <ModalManagerHelp
          isSignedIn={props.userIsSignedIn}
          isMobile={isMobile}/>
        </Modal>
      {/* end updates from lydia*/}

    </Header>
  )
}