import React, {useEffect, useRef, useState, useCallback} from "react";
import "./start.scss";
import {useNavigate} from "react-router-dom";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Property from "../../models/property";
import Session from "../../models/session";
import PropertyListItem from "../shared/property-list-item/PropertyListItem";

import {
  Column,
  Grid,
  InlineLoading
  //@ts-ignore
} from '@carbon/react';
import {Link} from "react-router-dom";

import {
  ArrowRight
  // @ts-ignore
} from '@carbon/icons-react';
import SavedSearchListItem from "../shared/saved-search-list-item/SavedSearchListItem";

interface iProps {
  user: __esri.PortalUser | null;
  propertyLayerUrl: string;
  savedSessionsTableUrl: string;
  favoritePropertiesTableUrl: string;
  notedProperties: Set<number>;
  savedProperties: Set<number> | undefined;
  removeSavedSearch: (objectId: number) => void;
  renameSavedSearch: (objectId: number, newName: string) => void;
}

export default function Start(props: iProps) {

  const navigate = useNavigate();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  const favoritePropertiesTable = useRef<__esri.FeatureLayer>();
  const savedSearchesTable = useRef<__esri.FeatureLayer>();

  const [propertiesLoaded, setPropertiesLoaded] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [savedPropertiesCount, setSavePropertiesCount] = useState<number | null>(null);

  const [savedSearchesLoaded, setSavedSearchesLoaded] = useState(false);
  const [savedSearches, setSavedSearches] = useState<Session[]>([]);

  const fetchFavoriteProperties = useCallback(() => {
    if (!props.user?.username) return;
    const abortController = new AbortController();
    favoritePropertiesTable.current?.queryFeatures({
      where: `user_id = '${props.user?.username}'`,
      returnGeometry: false,
      outFields: ['property_id', 'created_on'],
      orderByFields: ['created_on DESC'],
      num: 4
    }, {signal: abortController.signal}).then(favoriteProperties => {
      if (favoriteProperties.features.length > 0) {
        const propertyIds: number[] = [];
        const propertySavedOnDict: {[key: number]: number} = {};

        favoriteProperties.features.forEach(feature => {
          const propertyId = feature.attributes['property_id'];
          propertyIds.push(propertyId);
          propertySavedOnDict[propertyId] = feature.attributes['created_on'];
        });

        propertyLayer.current?.queryFeatures({
          where: `PropertyID IN (${propertyIds.join(',')})`,
          outFields: ['*'],
          returnGeometry: false,
          num: 4
        },
        {signal: abortController.signal}).then(response => {
          const _properties = response.features.map(feature => {
            const atts = feature.attributes;
            return {
              id: atts.PropertyID,
              name: atts.Property_Name,
              address: atts.Property_Address,
              city: atts.City,
              state: atts.State,
              zip: atts.Zip,
              type: atts.Property_Type_Main,
              acres: atts.Land_Area__AC_,
              buildingArea: atts.RBA,
              rank: atts.Rank,
              propertyManagerName: atts.Property_Manager_Name,
              propertyManagerContact: atts.Property_Manager_Contact,
              propertyManagerPhone: atts.Property_Manager_Phone,
              leasingCompanyName: atts.Leasing_Company_Name,
              leasingCompanyContact: atts.Leasing_Company_Contact,
              leasingCompanyPhone: atts.Leasing_Company_Phone,
              ownerName: atts.Owner_Name,
              ownerContact: atts.Owner_Contact,
              ownerPhone: atts.Owner_Phone,
              amenities: atts.Amenities,
              parkingSpaces: atts.Number_Of_Parking_Spaces,
              type_secondary: atts.Secondary_Type,
              googleStreetView: atts.GoogleStreetView,
              googleSearch: atts.GoogleSearch,
              googleMaps: atts.GoogleMaps,
              bingStreetView: atts.BingStreetView,
              closestBranch: atts.Closest_Branch,
              customer: atts.Customer,
              driveTime: atts.ToBreak,
              opco: atts.Sales_Company,
              objectId: atts.OBJECTID,
              submarketCluster: atts.Submarket_Cluster,
              latitude: atts.Latitude,
              longitude: atts.Longitude,
              favoriteTimestamp: propertySavedOnDict[atts.PropertyID]
            } as Property
          });

          _properties.sort((a, b) => (b.favoriteTimestamp || 0) - (a.favoriteTimestamp || 0));

          setProperties(_properties);
          setPropertiesLoaded(true);
        }).catch((reason) => {
          console.error(reason);
          if(reason.name === "AbortError") return;
          setProperties([]);
          setPropertiesLoaded(true);
        });
      } else {
        setProperties([]);
        setPropertiesLoaded(true);
      }
    }).catch((reason) => {
      console.log(reason);
      if(reason.name === "AbortError") return;
      setPropertiesLoaded(true);
    });

    return () => {
      abortController.abort();
    }
  }, [props.user?.username])

  const fetchSavedSearches = useCallback(() => {
    if (!props.user?.username) return;
    const abortController = new AbortController();

    savedSearchesTable.current?.queryFeatures({
      where: `user_id = '${props.user?.username}'`,
      returnGeometry: false,
      outFields: ['session_title', 'session_json', 'created_on', 'OBJECTID'],
      orderByFields: ['created_on DESC']
    }, {signal: abortController.signal}).then(featureSet => {
      if (featureSet.features.length > 0) {
        const searches = featureSet.features.map(feature => {
          const atts = feature.attributes;
          return {
            id: atts.OBJECTID,
            title: atts.session_title,
            data: JSON.parse(atts.session_json),
            saveTimestamp: atts.created_on
          } as Session;
        });

        setSavedSearches(searches);
        setSavedSearchesLoaded(true);
      } else {
        setSavedSearches([]);
        setSavedSearchesLoaded(true);
      }
    }).catch((reason) => {
      console.error(reason);
      setSavedSearchesLoaded(true);
    });
    
    return () => {
      abortController.abort();
    }
  }, [props.user?.username]);

  useEffect(() => {
    const abortController = new AbortController();
    if (props.user?.username && !favoritePropertiesTable.current && !propertyLayer.current) {
      favoritePropertiesTable.current = new FeatureLayer({url: props.favoritePropertiesTableUrl});
      propertyLayer.current = new FeatureLayer({url: props.propertyLayerUrl});
      fetchFavoriteProperties();

      favoritePropertiesTable.current.queryFeatureCount({
        where: `user_id = '${props.user?.username}'`
      }, {signal: abortController.signal}).then((count) => {
        setSavePropertiesCount(count);
      }).catch((err) => {
        console.log(err);
      })
    }
    return () => {
      abortController.abort();
    }
  }, [props.user?.username, props.favoritePropertiesTableUrl, props.propertyLayerUrl, fetchFavoriteProperties])

  useEffect(() => {
    if (props.user?.username && !savedSearchesTable.current) {
      savedSearchesTable.current = new FeatureLayer({url: props.savedSessionsTableUrl});
      fetchSavedSearches();
    }
  }, [props.user?.username, props.savedSessionsTableUrl, fetchSavedSearches])

  const removeSavedSearch = (objectId: number) => {
    const searches = savedSearches.filter(search => search.id !== objectId);
    setSavedSearches(searches);
    props.removeSavedSearch(objectId);
  }

  const renameSavedSearch = (objectId: number, newName: string) => {
    const searches = [...savedSearches];
    const search = searches.find(search => search.id === objectId);
    if (search) {
      search.title = newName;
    }
    setSavedSearches(searches);
    props.renameSavedSearch(objectId, newName);
  }

  const renameSavedProperty = (property_id: number, newName: string) => {
    const faveProperties = favoritePropertiesTable.current
    faveProperties!.queryFeatures({
      where: `property_id = ${property_id}`,
      outFields: ["*"],
    }).then((result) => {
      if (result.features.length > 0) {
        //const credits = result.features.map(feature => feature.attributes.Credits);
        // Update attributes
        //console.log(credits)
        const featureToUpdate = result.features[0];
        const currentValue = featureToUpdate.attributes.property_title;
        const newValue = newName;
        /*if (newValue == 0) {
          setCredits(false)
        }*/

        // Apply the update
        featureToUpdate.attributes.property_title = newValue;

        // Apply the update
        faveProperties!.applyEdits({
          updateFeatures: [featureToUpdate],
        }).then((editsResult) => {
          if (editsResult.updateFeatureResults.length > 0) {
            console.log("Feature updated successfully");
          } else {
            console.error("Error updating feature");
          }
        });
      } else {
        console.error("Feature not found");
      }
    });
  }

  const goToProperty = (property: Property) => () => {
    navigate("/properties", {replace: false, state: {property: property}});
  }

  const firstName = props.user?.fullName ? props.user.fullName.split(" ")[0] : '';
  return (
    <div className="start">
      <Grid>
        <Column sm={4} md={8} lg={16}>
          <div className="greeting">
            Hi, {firstName}.
          </div>
        </Column>
      </Grid>
      <Grid >
        <Column sm={4} md={8} lg={8}>
          <div className="get-started">
            Get started prospecting, view your sales reporting dashboard, or pick up where you left off below.
          </div>
        </Column>
        <Column sm={4} md={8} lg={8}>
          <Grid>
            <Column sm={4} md={4} lg={4}>
              <Link to="/properties" className="no-text-decoration">
                <div className="link-card">
                  <div className="link-card-title">
                    Properties
                  </div>
                  <div className="link-card-description">
                    Identify valuable properties using the new map and filtering tools.
                  </div>
                  <ArrowRight className='arrow' size={32} />
                </div>
              </Link>
            </Column>
            <Column sm={4} md={4} lg={4}>
              <Link to="/new-sales" className="no-text-decoration">
                <div className="link-card">
                  <div className="link-card-title">
                    New Sales Dashboard
                  </div>
                  <div className="link-card-description">
                    Track key sales metrics for your branch team and properties.
                  </div>
                  <ArrowRight className='arrow' size={32} />
                </div>
              </Link>
            </Column>
          </Grid>
        </Column>
      </Grid>
      <div className="divider"></div>
      <Grid >
        {savedSearches?.length > 0 && props.savedProperties &&

          <Column sm={4} md={8} lg={8}>
            <div className="saved-title">My recently saved searches</div>

            <div className="saved-list">
              {savedSearchesLoaded && savedSearches.slice(0,4).map((search, index) =>
                <SavedSearchListItem
                  key={index}
                  search={search}
                  removeSavedSearch={removeSavedSearch}
                  renameSavedSearch={renameSavedSearch}
                />
              )}
              {!savedSearchesLoaded &&
                <InlineLoading
                  status="active"
                  iconDescription="Active Loading Indicator"
                  description="Loading saved searches..."
                />
              }
            </div>
            <Link to="/start/saved-searches" className="view-all cds--link">
              View all {savedSearches.length} saved searches
            </Link>
          </Column>
        }

        {props.savedProperties &&
          <Column sm={4} md={8} lg={8}>
            <div className="saved-title">My recently saved properties</div>

            <div className="saved-list">
              {propertiesLoaded && properties.map((property, index) =>
                <PropertyListItem
                  key={index}
                  property={property}
                  isFavorite={true}
                  hasNote={props.notedProperties.has(property.id)}
                  onClick={goToProperty(property)}
                  renameSavedProperty={renameSavedProperty}
                />
              )}
              {!propertiesLoaded &&
                <InlineLoading
                  status="active"
                  iconDescription="Active Loading Indicator"
                  description="Loading saved properties..."
                />
              }
            </div>
            <Link to="/start/saved-properties" className="view-all cds--link">
              View all {savedPropertiesCount} saved properties
            </Link>
          </Column>
        }
      </Grid>
    </div >
  );
}
