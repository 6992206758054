import React from 'react';

import './TemplateExport.scss'

import {
  Replicate,
  // @ts-ignore
} from '@carbon/icons-react';

import {
  Button
  // @ts-ignore
} from '@carbon/react';

interface TemplateProps {
    uploadType: string; // Change this to accept an object if needed
}

export default function DownloadTemplateButton({ uploadType }: TemplateProps) {
  const handleDownload = (uploadType: string) => {
    // Create a CSV content
    let csvContent = ''
    let title = ''

    if (uploadType === 'Contacts') {
      csvContent = "FirstName,LastName,CompanyName,Title,Email,MobilePhone,OfficePhone,AddressLine1,City,StateProvinceCode,ZipCode\n";
      title = "ContactsTemplate.csv"
    }
    /*else if (uploadType === 'Properties') {
      csvContent = "PropertyName,AddressLine1,City,StateProvinceCode,ZipCode,Industry,PaymentTerms,TaxJurisdiction,ContactFirstName,ContactLastName,ContactCompanyName,ContactPhone,ContactEmail,ContactTitle\n";
      title = "PropertiesTemplate.csv"
    }*/
    // Create a Blob object from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create an anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = title; // Set the download attribute with the desired file name
    a.click();

    // Release the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <Button onClick={(event: any) =>handleDownload(uploadType)} className='replicate-button' size="sm">

      <Replicate className="replicate-icon"></Replicate>
    </Button>
  );
}