import React, { useState, useEffect } from 'react'
import "./measurement.scss";
import AreaWidget from './AreaWidget';
import DistanceWidget from './DistanceWidget';

//UPDATES FROM NATALIE: Draw tooltip
import ToolTip from "../shared/tool-tip/ToolTip";
//END UPDATES FROM NATALIE

// Carbon Widgets
import {
  Button,
  //@ts-ignore
} from '@carbon/react';

//UPDATES FROM NATALIE: Measure UI
import {
  RulerAlt,
  ChevronLeft,
  ChevronUp,
  ChevronDown,
  FloatingIp,
  AreaCustom,
  Add,
  // @ts-ignore
} from '@carbon/icons-react';
import { isBoxedPrimitive } from 'util/types';
import { NULL } from 'sass';
//END UPDATES FROM NATALIE

interface iProps {
  map: __esri.Map;
  view: __esri.MapView;
}

export default function Measurement(props: iProps) {
  const [activeWidget, setActiveWidget] = useState<string | null>();
  const [widgets, setWidgets] = useState<Array<JSX.Element>>([]);
  // Handle opening widget selection and toolbox
  // Main toolbox and widgets
  const [isOpen, toggleDiv] = useState(false);
  // Ruler expand toggle
  const [Expand, toggleExpand] = useState(false);
  // Distance widget dropdown
  const [isOpened, toggleDiv2] = useState(false);
  // Area widget dropdown
  const [isOpened2, toggleDiv3] = useState(false);
  const [showToolbox, showBox] = useState(false);

  useEffect(() => {
    if(showToolbox){
    // updates from lydia
      const measurements = <div><DistanceWidget open={isOpened} view={props.view} activeWidget={activeWidget}/><AreaWidget open={isOpened2} view={props.view} activeWidget={activeWidget}/></div>
      setWidgets(prevElement => [...prevElement, measurements])

      /*
      if(activeWidget === "distance") {
        const distance = <DistanceWidget open={isOpened} view={props.view} activeWidget={activeWidget}/>
        setWidgets(prevElement => [...prevElement, distance])
      }
      else {
        const area = <AreaWidget open={isOpened2} view={props.view} activeWidget={activeWidget}/>
        setWidgets(prevElement => [...prevElement, area])
      }
      */
    // end updates from lydia


      /*if(activeWidget !== "area" || "distance") {
        toggleDiv(isOpen);
        toggleDiv2(isOpened);
      }*/
      /*if (showToolbox) {
        /*var x = document.getElementById("selectionBox");
        x!.style.display === "none"
        toggleDiv(!isOpen)
      }
      if (showToolbox){
       hide(hideButtons);}*/
    }

    // update from lydia
    //return () => {setWidgets([])}

  },[activeWidget, isOpen, showToolbox, props.view])

 /* useEffect(() => {
    if (showToolbox){
      toggleDiv2(isOpened)
      if (isOpened){
        hide(hideButtons)
      }
    }
  },[showToolbox, hideButtons, isOpened])*/

  useEffect(() => {
    /*if (!isOpen){
      toggleDiv2(false)
      toggleDiv3(false)
    }*/
    if (isOpened){
      toggleDiv(false);
    }
    if (isOpened2){
      toggleDiv(false);
    }
  },[isOpen, isOpened, isOpened2])

  // updates from lydia
  const clear = () => {
    return () => {setWidgets([])}
  }

  const hideWidget = () => {
    let elements = document.getElementsByClassName("esri-distance-measurement-2d__container"),
        len = elements !== null ? elements.length : 0;
    for(let i = 0; i < len; i++) {
        elements[i].className += " hidden";
    }
    elements = document.getElementsByClassName("esri-area-measurement-2d__container");
    len = elements !== null ? elements.length : 0;
    for(let i = 0; i < len; i++) {
        elements[i].className += " hidden";
    }
  }

  const showWidget = (type: string) => {
    if(type == "Distance") {
      let elements = document.getElementsByClassName("esri-distance-measurement-2d__container"),
          len = elements !== null ? elements.length : 0;
      for(let i = 0; i < len; i++) {
          elements[i].className = "esri-distance-measurement-2d__container";
      }
    } else {
      let elements = document.getElementsByClassName("esri-area-measurement-2d__container"),
          len = elements !== null ? elements.length : 0;
      for(let i = 0; i < len; i++) {
          elements[i].className = "esri-area-measurement-2d__container";
      }
    }
  }
  // end updates from lydia

  const addAnotherWidget = (newWidget: string | null | undefined) => {
    if(newWidget === "distance") {
      const distance = <DistanceWidget open={isOpened} view={props.view} activeWidget={activeWidget}/>;
      setWidgets(prevElement => [...prevElement, distance])
    } else {
      const area = <AreaWidget open={isOpened2} view={props.view} activeWidget={activeWidget}/>
      setWidgets(prevElement => [...prevElement, area]);
    }
  }

  const selectWidget = (type:string) => {
    if(!type) return;
    showBox(true);
    setActiveWidget(type);
  }

  const openTools = () => {
    toggleDiv(!isOpen);
    toggleExpand(!Expand)
    //showBox(false);
  }

  const openTools2 = () => {
    showBox(false);
    }

    /*const openTools3 = () => {
      if(activeWidget === "distance"){
      toggleDiv2(!isOpened);
      toggleDiv(!isOpen);
      }
      else return null
      }

      const openTools5 = () => {
        if(activeWidget === "area"){
        toggleDiv3(!isOpened2);
        }
        else return null
        }*/

      // Opens Distance Widget dropdown
      const openTools4 = () => {
        toggleDiv2(!isOpened);
      }
      // Opens Area Widget dropdown
      const openTools6 = () => {
        toggleDiv3(!isOpened2);
      }


  //UPDATES FROM NATALIE: Measure UI
  return (
    <div  className="measure-btn">

    {!Expand &&

        <Button
        iconDescription="Measure" //UPDATES FROM NATALIE: Measure tool tooltip nomenclature
        onClick={openTools}
        className="expand-button"
        hasIconOnly={true}
        renderIcon={RulerAlt}
        tooltipPosition="right"
      />
    }

    <div className="isOpened">

      {isOpened &&
            <Button
            iconDescription="Measure" //UPDATES FROM NATALIE: Measure tool tooltip nomenclature
            onClick={ () => {hideWidget(); openTools4(); showBox(false); toggleDiv(!isOpen);}} // update from lydia
            className="measure-button"
            hasIconOnly={false}
            renderIcon={FloatingIp}
            tooltipPosition="right">
              <RulerAlt className="RulerAlt"/> Distance
            </Button>

      }

      </div>

      {isOpened2 &&
            <Button
            iconDescription="Measure" //UPDATES FROM NATALIE: Measure tool tooltip nomenclature
            onClick={ () => {hideWidget(); openTools6(); showBox(false); toggleDiv(!isOpen);}} // update from lydia
            className="measure-button"
            hasIconOnly={false}
            renderIcon={AreaCustom}
            tooltipPosition="right">
              <RulerAlt className="RulerAlt"/> Area
            </Button>

      }

      {isOpen &&
      <Button
      iconDescription="Measure" //UPDATES FROM NATALIE: Measure tool tooltip nomenclature
      onClick={openTools}
      className="measure-button"
      hasIconOnly={false}
      renderIcon={ChevronDown}
      tooltipPosition="right">
        <RulerAlt className="RulerAlt"/> Measure
      </Button>


      /*<Button
        iconDescription="Measure" //UPDATES FROM NATALIE: Measure tool tooltip nomenclature
        onClick={openTools}
        className="measure-button"
        hasIconOnly={true}
        renderIcon={RulerAlt}
        tooltipPosition="right"
      />*/
      }

      {
        isOpen &&
        <div className="toolbox-container">
          <div  className="selection-box" id="selectionBox">
          <Button
              id="distance"
              //className="esri-icon-measure-line"
              /*renderIcon={FloatingIp}*/
              iconDescription="Measure Distance"
              onClick={() => {showWidget("Distance"); selectWidget("distance"); openTools4();}} // update from lydia
              className="distance-area-buttons"
              tooltipPosition={showToolbox ? "top" : "right"}>
              <FloatingIp className="FloatingIp"/> Distance
              </Button>

            <Button
              id="area"
              //className="button esri-icon-measure-area"
              /*renderIcon={AreaCustom}*/
              iconDescription="Measure Area"
              onClick={() => {showWidget("Area"); selectWidget("area"); openTools6();}} // update from lydia
              className="distance-area-buttons"
              tooltipPosition={showToolbox ? "top" : "right"}>
              <AreaCustom className="CustomArea"/> Area
              </Button>

          <div  className="selection-box2">

            <Button
              id="button"
              className="ClearButton"
              hasIconOnly={false}
              iconDescription="Close measure(s)"
              /*renderIcon={ChevronLeft} */
              onClick={clear()} // update from lydia
              tooltipPosition="right">
                Clear
              </Button>

              <Button
              id="button"
              className="DoneButton"
              hasIconOnly={false}
              iconDescription="Close measure(s)"
              /*renderIcon={ChevronLeft} */
              onClick={() => openTools()}
              tooltipPosition="right">
                Done
              </Button>

              </div>

            {/*<Button
              id="distance"
              className="esri-icon-measure-line"
              hasIconOnly
              iconDescription="Measure Distance"
              onClick={() => selectWidget("distance")}
              tooltipPosition={showToolbox ? "top" : "right"}
            />
            <Button
              id="area"
              className="button esri-icon-measure-area"
              hasIconOnly
              iconDescription="Measure Area"
              onClick={() => selectWidget("area")}
              tooltipPosition={showToolbox ? "top" : "right"}
            />
            <Button
              id="button"
              hasIconOnly={true}
              iconDescription="Close"
              renderIcon={ChevronLeft}
              onClick={openTools}
              tooltipPosition="right"
            />*/}
          </div>
        </div>
      }

              <div className="widget-container">
                  {
                    widgets.map((widget, index) => {
                      return <div key={index}>{widget}</div>
                    })
                  }
                </div>
          {
            showToolbox &&
              <div id="toolbox" className="toolbox">
                {/*UPDATES FROM NATALIE: Add another measurement button
                <ToolTip delay={100} direction="right" content="Add another measurement to the map">
                <Button
                  className="add-measure-btn"
                  onClick={()=> addAnotherWidget(activeWidget)}
                  kind="secondary"
                  renderIcon={Add}
                >
                  Add a measurement
                </Button>
                </ToolTip>*/}
                <Button
                  className="add-measure-btn"
                  onClick={()=> addAnotherWidget(activeWidget)}
                  kind="secondary"
                  /*renderIcon={Add}*/
                >
                  New
                </Button>

              </div>
          }

          {
            isOpened &&
              <Button
              id="DoneButtonDistance"
              className="DoneButton"
              hasIconOnly={false}
              iconDescription="Close measure(s)"
              /*renderIcon={ChevronLeft} */
              onClick={() => {hideWidget(); toggleDiv(!isOpen); showBox(false); openTools4();}} // update from lydia
              tooltipPosition="right">
                Done
              </Button>

          }

          {
            isOpened2 &&
              <Button
              id="DoneButtonArea"
              className="DoneButton"
              hasIconOnly={false}
              iconDescription="Close measure(s)"
              /*renderIcon={ChevronLeft} */
              onClick={() => {hideWidget(); toggleDiv(!isOpen); showBox(false); openTools6(); hideWidget();}} // update from lydia
              tooltipPosition="right">
                Done
              </Button>

          }
    </div>
  )
}