import React, {useCallback, useEffect, useRef, useState} from "react";

import "./saved-properties.scss";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Property from "../../models/property";
import SavedPropertyListItem from "./saved-property-list-item/SavedPropertyListItem";
import {Link, useNavigate} from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  InlineLoading,
  Layer,
  Search,
  Dropdown,
  //@ts-ignore
} from '@carbon/react';

import {
  Add,
  //@ts-ignore
} from '@carbon/icons-react';

interface iProps {
  user: __esri.PortalUser | null;
  propertyLayerUrl: string;
  favoritePropertiesTableUrl: string;
  unfavoriteProperty: (propertyId: number) => void;
  notedProperties: Set<number>;
}

const sortOptions = ["Recently added", "Recommended", "Lot size (acres)"]; //UPDATES FROM NATALIE
export default function SavedProperties(props: iProps) {
  const navigate = useNavigate();
  const propertyLayer = useRef<__esri.FeatureLayer>();
  const favoritePropertiesTable = useRef<__esri.FeatureLayer>();

  const [propertiesLoaded, setPropertiesLoaded] = useState(false);
  const [allSavedProperties, setAllSavedProperties] = useState<Property[]>([]);
  const [displayProperties, setDisplayProperties] = useState<Property[]>([]);
  const [sortOption, setSortOption] = useState("Recently added");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    if (props.user?.username && !favoritePropertiesTable.current && !propertyLayer.current) {
      favoritePropertiesTable.current = new FeatureLayer({url: props.favoritePropertiesTableUrl});
      propertyLayer.current = new FeatureLayer({url: props.propertyLayerUrl});

      favoritePropertiesTable.current?.queryFeatures({
        where: `user_id = '${props.user?.username}'`,
        returnGeometry: false,
        outFields: ['property_id', 'created_on'],
        orderByFields: ['created_on DESC']
      }, {signal: abortController.signal}).then(favoriteProperties => {
        if (favoriteProperties.features.length > 0) {
          const propertyIds: number[] = [];
          const propertySavedOnDict: {[key: number]: number} = {};

          favoriteProperties.features.forEach(feature => {
            const propertyId = feature.attributes['property_id'];
            propertyIds.push(propertyId);
            propertySavedOnDict[propertyId] = feature.attributes['created_on'];
          });

          propertyLayer.current?.queryFeatures({
            where: `PropertyID IN (${propertyIds.join(',')})`,
            outFields: ['*'],
            returnGeometry: false,
          }, {signal: abortController.signal}).then(response => {
            const _properties = response.features.map(feature => {
              const atts = feature.attributes;
              return {
                id: atts.PropertyID,
                name: atts.Property_Name,
                address: atts.Property_Address,
                city: atts.City,
                state: atts.State,
                zip: atts.Zip,
                type: atts.Property_Type_Main,
                acres: atts.Land_Area__AC_,
                buildingArea: atts.RBA,
                rank: atts.Rank,
                propertyManagerName: atts.Property_Manager_Name,
                propertyManagerContact: atts.Property_Manager_Contact,
                propertyManagerPhone: atts.Property_Manager_Phone,
                leasingCompanyName: atts.Leasing_Company_Name,
                leasingCompanyContact: atts.Leasing_Company_Contact,
                leasingCompanyPhone: atts.Leasing_Company_Phone,
                ownerName: atts.Owner_Name,
                ownerContact: atts.Owner_Contact,
                ownerPhone: atts.Owner_Phone,
                amenities: atts.Amenities,
                parkingSpaces: atts.Number_Of_Parking_Spaces,
                type_secondary: atts.Secondary_Type,
                googleStreetView: atts.GoogleStreetView,
                googleSearch: atts.GoogleSearch,
                googleMaps: atts.GoogleMaps,
                bingStreetView: atts.BingStreetView,
                closestBranch: atts.Closest_Branch,
                customer: atts.Customer,
                driveTime: atts.ToBreak,
                opco: atts.Sales_Company,
                objectId: atts.OBJECTID,
                submarketCluster: atts.Submarket_Cluster,
                latitude: atts.Latitude,
                longitude: atts.Longitude,
                favoriteTimestamp: propertySavedOnDict[atts.PropertyID]
              } as Property
            });

            setAllSavedProperties(_properties);
            setPropertiesLoaded(true);
          }).catch((reason) => {
            console.log(reason);
            if (reason.name === "AbortError") return;
            setAllSavedProperties([]);
            setPropertiesLoaded(true);
          });
        } else {
          setAllSavedProperties([]);
          setPropertiesLoaded(true);
        }
      }).catch((reason) => {
        console.log(reason);
        if (reason.name === "AbortError") return;
        setAllSavedProperties([]);
        setPropertiesLoaded(true);
      });
    }

    return () => {
      abortController.abort();
    }
  }, [props.user?.username, props.favoritePropertiesTableUrl, props.propertyLayerUrl]);

  const filterProperties = () => {
    if (allSavedProperties.length > 0) {
      let _properties = [...allSavedProperties];
      if (filterText) {
        _properties = _properties.filter(property =>
          property.name?.toLowerCase().includes(filterText)
          || property.address?.toLocaleLowerCase().includes(filterText)
        );
      }

      if (sortOption === "Recently added") {
        _properties.sort((a, b) => (b.favoriteTimestamp || 0) - (a.favoriteTimestamp || 0));
      } else if (sortOption === "Recommended") {
        _properties.sort((a,b) => (b.rank || 0) - (a.rank || 0));
      } else if (sortOption === "Lot size (acres)") { //UPDATES FROM NATALIE
        _properties.sort((a,b) => (b.acres || 0) - (a.acres || 0));
      }

      setDisplayProperties(_properties)
    } else {
      setDisplayProperties([]);
    }
  }
  useEffect(filterProperties, [sortOption, filterText, allSavedProperties]);

  const removeProperty = useCallback((propertyId: number) => {
    const favProps = allSavedProperties.filter((property) => property.id !== propertyId);
    setAllSavedProperties(favProps);
    props.unfavoriteProperty(propertyId);
  }, [props, allSavedProperties]);

  const onFilterChange = (evt: any) => {
    setFilterText(evt.target.value.toLowerCase());
  }

  const onSortOptionChange = (evt: any) => {
    setSortOption(evt.selectedItem);
  }

  return (
    <div className="saved-properties">
      <Breadcrumb >
        <BreadcrumbItem className="breadcrumb">
          <Link to="/start">Start</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="page-title">
        Saved properties
      </div>
      <Layer className="list-controls">
        <Search
          className="search"
          closeButtonLabelText="Clear search input"
          id="saved-properties-search"
          labelText="Filter saved properties"
          placeholder="Filter your saved properties..."
          onChange={onFilterChange}
          disabled={!propertiesLoaded}
        />
        <div className="rh-controls">
          <Dropdown
            className="sort-by"
            id="saved-property-sort"
            label="Sort by"
            titleText="Sort by"
            items={sortOptions}
            initialSelectedItem='Recently added'
            onChange={onSortOptionChange}
            size='md'
            itemToString={(item: string) => item}
            type='inline'
          />
          <Button
            onClick={() => {navigate("/properties")}}
            size="md"
            renderIcon={Add}
          >
            New search
          </Button>
        </div>
      </Layer>
      <div className="property-list">
        {propertiesLoaded && displayProperties.map((property, index) =>
          <SavedPropertyListItem
            key={index}
            property={property}
            removeProperty={removeProperty}
            hasNote={props.notedProperties.has(property.id)}
          />
        )}
        {!propertiesLoaded &&
          <InlineLoading
            status="active"
            iconDescription="Active Loading Indicator"
            description="Loading saved properties..."
          />
        }
      </div>
    </div>
  );
}