export const credentials = {
    "Keesen Landscape Management, Inc.": {
        "ALIAS": "Keesen",
        "CLIENTID": "aefc28aa-568e-4c91-8db2-f5e389b9a74a",
        "SECRET": "5JgSEyQa4GJ2C7ii2kmEDmgdAN4a1x7o"
    },
    "Signature Landscape LLC": {
        "ALIAS": "Signature",
        "CLIENTID": "11be8e93-c7de-440e-8dee-755edcbda11f",
        "SECRET": "QJMnbo4L0T6sgpsPsIIkzyWEnQSUpekv"
    },
    "Columbia Landcare, LLC": {
        "ALIAS": "Columbia",
        "CLIENTID": "0133f9f2-8409-415f-9f35-f3b6433d030d",
        "SECRET": "fEwTJwpOwB7bdLYWcmJaLyuwXMYcVTmD"
    },
    "Roark Landscaping Corporation": {
        "ALIAS": "Roark",
        "CLIENTID": "2802d3b3-d373-4538-a373-4f035c8b8e8b",
        "SECRET": "A0DhbjrRuN3MuDCXXSgmlhgueu5xiZ0A"
    },
    "Total Environment Inc": {
        "ALIAS": "Total Environment",
        "CLIENTID": "d4f6b9a4-02a1-4d33-a24e-0251102a6d7a",
        "SECRET": "CuZOYibWQeJxfyAPRJCB9CwcZXe9I6fA"
    },
    "Cutting Edge Services, LLC": {
        "ALIAS": "Cutting Edge Idaho",
        "CLIENTID": "13a0d222-1e8d-4f19-9b59-779d0e87529e",
        "SECRET": "uRRQLAXXKo3mt9nymW4RsWHnMNTQTJ6Z"
    },
    "Cutting Edge Landscape Utah": {
        "ALIAS": "Cutting Edge Utah",
        "CLIENTID": "7c013d39-7612-4d28-b793-f9c4683093d0",
        "SECRET": "tkBhTQaPYRCKdNsNxN5i9VemFl8dMQW7"
    },
    "Perficut": {
        "ALIAS": "Perficut",
        "CLIENTID": "0ba9e210-5004-4ec9-8f12-3c56060e51df",
        "SECRET": "tGXPhlKuelBCEmROgZOxe2yn2LsgpSDX"
    },
    "Landscape Concepts Management": {
        "ALIAS": "LCM",
        "CLIENTID": "add4f57b-6596-4d6a-adb2-b37ad20586de",
        "SECRET": "0bbJEZn1NZW1TR4j4QwKiviyRLwy2VQ4"
    },
    "Hillenmeyer Landscape Services, LLC.": {
        "ALIAS": "Hillenmeyer",
        "CLIENTID": "25f15215-0070-4937-9a9e-11b82fb95ba1",
        "SECRET": "z750TyHcOXKzFwFTKxf1ABob3Qwxc7WI"
    },
    "LSI": {
        "ALIAS": "LSI",
        "CLIENTID": "b7b55c7e-5117-4fbf-a03e-b70a0ddecd37",
        "SECRET": "3I7hlkSg6XGzWzVNR5RTt3mIgURod6JV"
    },
    "VerdeGo": {
        "ALIAS": "VerdeGo",
        "CLIENTID": "b74dd91c-8ef5-48c3-ad38-446f20ceb30c",
        "SECRET": "c1puCvmQLVlYBSEaaY76OKDvt5Tx4Qob"
    },
    "JML Landscape Management": {
        "ALIAS": "JML",
        "CLIENTID": "0a8aba01-9ce9-43ac-9284-af851665be4c",
        "SECRET": "EzL076lVAXIAPziiE3qswDMBC6Ma9ndx"
    },
    "Heritage Landscape Services, LLC": {
        "ALIAS": "HLS",
        "CLIENTID": "190de341-ba46-45c1-8085-f9c8a00d1666",
        "SECRET": "pYFiD4TGizMfaYveTfu3tFjLTfaJKcGe"
    },
    "Heartland North East": {
        "ALIAS": "HeartLand North East",
        "CLIENTID": "79a427e5-82a0-41e8-bc4e-da06d6f1b82b",
        "SECRET": "dPvcmWt2Acs3Qvair3DprSeIMhUiFDKg"
    },
    "Merit Landscape Solutions of Texas": {
        "ALIAS": "Merit TX",
        "CLIENTID": "1f912a54-3524-42eb-be6f-b8dddfe77e25",
        "SECRET": "BkEonytEP4mwoeZ1IU25m7Hxrcszgtmy"
    },
    "Heartland Arizona": {
        "ALIAS": "HeartLand Arizona",
        "CLIENTID": "a3f62fdb-df11-4c70-acb6-f28319e88870",
        "SECRET": "r3MhMHY0bqulAcxzwjQfg9XkjpvPSkMX"
    },
    "Landscape Services Group": {
        "ALIAS": "LSG",
        "CLIENTID": "a621b375-2159-480f-9a16-524ef1979ba9",
        "SECRET": "tJWiEdRk9tijDVPFf6izbl2n7c4anzu7"
    },
    "HLSA/Exterity": {
        "ALIAS": "HLSA/Exterity",
        "CLIENTID": "dff5a0fc-e1bc-4933-9701-d454e0b580d0",
        "SECRET": "VblCj73rPTBGJ6pXbyMPx1QCRQvK6OLV"
    }
}
