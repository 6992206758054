import React from 'react';
import './ContactExport.scss';
//@ts-ignore
import { Download } from '@carbon/icons-react'
//@ts-ignore
import { Button } from '@carbon/react';

interface iProps {
    uploadType: string;
    contacts: any[]; // Array of contacts
}

export default function DownloadTemplateButton(props: iProps) {
    const handleDownload = (uploadType: string, contacts: any[]) => {
        let csvContent = '';
        let title = '';

            // Add header row
            csvContent = "FirstName,LastName,CompanyName,Title,Email,MobilePhone,OfficePhone,AddressLine1,City,StateProvinceCode,ZipCode\n";

            contacts.forEach((contact) => {
                const {
                    firstName,
                    lastName,
                    company: { name: companyName },
                    jobTitle,
                    email,
                    mobilePhone,
                    phone,
                    street,
                    city,
                    state,
                    zipCode
                } = contact;

                // Add double quotes and escape commas in each field
                const cleanedFirstName = firstName ? `"${firstName.replace(/"/g, '""')}"` : '';
                const cleanedLastName = lastName ? `"${lastName.replace(/"/g, '""')}"` : '';
                const cleanedCompanyName = companyName ? `"${companyName.replace(/"/g, '""')}"` : '';
                const cleanedJobTitle = jobTitle ? `"${jobTitle.replace(/"/g, '""')}"` : '';
                const cleanedEmail = email ? `"${email.replace(/"/g, '""')}"` : '';
                const cleanedMobilePhone = mobilePhone ? `"${mobilePhone.replace(/"/g, '""')}"` : '';
                const cleanedPhone = phone ? `"${phone.replace(/"/g, '""')}"` : '';
                const cleanedStreet = street ? `"${street.replace(/"/g, '""')}"` : '';
                const cleanedCity = city ? `"${city.replace(/"/g, '""')}"` : '';
                const cleanedState = state ? `"${state.replace(/"/g, '""')}"` : '';
                const cleanedZipCode = zipCode ? `"${zipCode.replace(/"/g, '""')}"` : '';

                // Concatenate the cleaned fields into the CSV content
                csvContent += `${cleanedFirstName},${cleanedLastName},${cleanedCompanyName},${cleanedJobTitle},${cleanedEmail},${cleanedMobilePhone},${cleanedPhone},${cleanedStreet},${cleanedCity},${cleanedState},${cleanedZipCode}\n`;
            });


            title = "SR_Sales_ZoomInfoExport.csv";

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = title;
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Button onClick={() => handleDownload(props.uploadType, props.contacts)} className='export-button' size="sm">
            <Download className="export-icon" size="12" />
        </Button>
    );
}