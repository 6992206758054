import React, { useState } from "react";
import "./tooltip.scss";


interface iProps {
  delay?: number;
  children?: React.ReactNode | undefined;
  content?: string | number;
  direction?: string;
}
/**** Custom Tooltip for Icons ****/
export default function Tooltip(props: iProps) {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="tooltip-wrapper cds--popover--high-contrast cds--popover--left"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div className={`tooltip-tip  cds--tooltip-content ${props.direction || "top"}`}>
          {props.content}
        </div>
      )}
    </div>
  );
};

 