import React from 'react'
import ReactDOM from 'react-dom'
import Property from '../../../../models/property';
import './property-detail-modal.scss'

import {
  Button,
  ComposedModal,
  ModalHeader,
  ModalBody,
  TextInput,
  ModalFooter,
  //@ts-ignore
} from '@carbon/react';

interface iProps {
  property: Property;
  toEdit: any;
  setToEdit: Function;
  open: boolean
  modalController: Function;
  submitFieldEdits: Function;
}

export default function ModalManager(props: iProps) {
  const cancelEdits = () => {
    props.modalController(false);
  }

  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <ComposedModal
            open={props.open}
            onClose={() => props.modalController(false)}
            size='md'
            preventCloseOnClickOutside={true}
          >
            <ModalHeader 
              aria-label="Contact Editor" 
              title="Edit Property Contact Information" 
              buttonOnClick={() => props.modalController(false)} />
            <ModalBody aria-label="hasScrollingContent" className="modal-body" hasScrollingContent={false}>
              <div className='modal-grid-container'>
                <div className='row'>
                  <TextInput
                    autoComplete="off"
                    data-modal-primary-focus
                    value={props.toEdit.propertyManagerName || ''}
                    id="property-manager-company"
                    label="Property company"
                    labelText="Property manager company name"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, propertyManagerName: evt.target.value }))}
                    type="text"
                    className="modal-input"
                    style={{ marginBottom: '1rem' }}
                  />
                  <TextInput
                    data-modal-primary-focus
                    id="property-manager-contact"
                    label="Property manager contact"
                    labelText="Property manager contact name"
                    value={props.toEdit.propertyManagerContact || ''}
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, propertyManagerContact: evt.target.value }))}
                    type="text"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                  <TextInput
                    data-modal-primary-focus
                    value={props.toEdit.propertyManagerPhone || ''}
                    id="property-manager-phone"
                    label="Property manager phone number"
                    labelText="Property manager contact phone number"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, propertyManagerPhone: evt.target.value }))}
                    type="tel"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                </div>
                <div className='row'>
                  <TextInput
                    autoComplete="off"
                    data-modal-primary-focus
                    value={props.toEdit.leasingCompanyName || ''}
                    id="leasing-company-name"
                    label="Leasing company name"
                    labelText="Leasing company name"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, leasingCompanyName: evt.target.value }))}
                    type="text"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                  <TextInput
                    data-modal-primary-focus
                    id="leasing-company-contact"
                    label="Leasing company contact"
                    labelText="Leasing company contact name"
                    value={props.toEdit.leasingCompanyContact || ''}
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, leasingCompanyContact: evt.target.value }))}
                    type="text"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                  <TextInput
                    data-modal-primary-focus
                    value={props.toEdit.leasingCompanyPhone || ''}
                    id="leasing-company-phone"
                    label="Leasing company phone number"
                    labelText="Leasing company contact phone number"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, leasingCompanyPhone: evt.target.value }))}
                    type="tel"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                </div>
                <div className='row'>
                  <TextInput
                    autoComplete="off"
                    data-modal-primary-focus
                    value={props.toEdit.ownerName || ''}
                    id="property-owner-name"
                    label="Property owner name"
                    labelText="Property owner name"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, ownerName: evt.target.value }))}
                    type="text"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                  <TextInput
                    data-modal-primary-focus
                    id="property-owner-contact"
                    label="Property owner contact"
                    labelText="Property owner contact name"
                    value={props.toEdit.ownerContact || ''}
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, ownerContact: evt.target.value }))}
                    type="text"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                  <TextInput
                    data-modal-primary-focus
                    value={props.toEdit.ownerPhone || ''}
                    id="property-owner-phone"
                    label="Property owner phone number"
                    labelText="Property owner contact phone number"
                    onChange={(evt: any) => props.setToEdit((prevState: any) => ({ ...prevState, ownerPhone: evt.target.value }))}
                    type="tel"
                    style={{ marginBottom: '1rem' }}
                    className="modal-input"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button kind="secondary" className="modal-btn" onClick={cancelEdits}>Cancel</Button>
              <Button kind="primary" className="modal-btn" onClick={props.submitFieldEdits}>Update information</Button>
            </ModalFooter>
          </ComposedModal>,
          document.body
        )}
    </>
  );
}
