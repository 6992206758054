import React, { useEffect, useRef, useState, useCallback, MutableRefObject, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import "./ZoomInfo.scss";
import ZoomInfoItem from "../shared/zoom-info-item/ZoomInfoItem";
import ZoomInfoDetails from "../shared/zoom-info-item/ZoomInfoDetails";
import Tooltip from "../shared/tool-tip/ToolTip";
import { useNavigate } from "react-router-dom";
import { executeQueryJSON } from "@arcgis/core/rest/query";
import Property from "../../models/property";
import Session from "../../models/session";
//import getMap from "./SAmap";
import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../responsive';
import TemplateExport from "./TemplateExport"
import contactTemplate from '../../images/contact-template.png';
import { Aspire_URL_Test } from "../../SendToAspire/SendToAspire.test";
import { Aspire_URL_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { Aspire_URL_Prod } from "../../SendToAspire/SendToAspire.Prod";
import { Client_ID_Test } from "../../SendToAspire/SendToAspire.test";
import { Client_ID_Dev } from "../../SendToAspire/SendToAspire.Dev";
//import { Client_ID_Prod } from "../../../../SendToAspire/SendToAspire.Prod";
import { Secret_Test } from "../../SendToAspire/SendToAspire.test";
import { Secret_Dev } from "../../SendToAspire/SendToAspire.Dev";
import { credentials } from "../../SendToAspire/APIcreds";

import {
    Column,
    Grid,
    InlineLoading,
    ToastNotification,
    Modal,
    FormItem,
    Select,
    SelectItem,
    ContentSwitcher,
    Switch,
    Accordion,
    AccordionItem,
    FileUploaderDropContainer,
    TextInput,
    IconButton,
    FileUploaderItem,
    ProgressBar,
    Button
    //@ts-ignore
} from '@carbon/react';

import { Link } from "react-router-dom";

import {
    ArrowRight,
    ArrowLeft,
    TaskView,
    Add,
    SortDescending,
    SortAscending,
    Replicate,
    CheckmarkFilled,
    Misuse,
    WarningAltFilled,
    CloudUpload
    // @ts-ignore
} from '@carbon/icons-react';

type Credentials = {
    [market: string]: {
        ALIAS: string;
        CLIENTID: string;
        SECRET: string;
    };
};

interface iProps {
    isMobile: boolean;
    uploadType: string;
    exportList?: Function;
    exportListModal: Function;
    exportListArray: Array<any>;
    filteredCredentials: Credentials | undefined;
    userTables: {
        searchHistory: string;
        savedSessions: string;
        notes: string;
        userMarkets: string;
        favoriteProperties: string;
        userPlatform: string;
    };
    user: __esri.PortalUser | null;
}

interface LocationState {
    searchCriteria?: object;
}

export default function ZoomInfo(props: iProps) {

    //const initialState = location.state || {};

    // Helper function to get initial state from sessionStorage
    const getInitialState = () => {
        const savedState = sessionStorage.getItem('combinedState');
        return savedState ? JSON.parse(savedState) : {
            searchType: 'Contact',
            searched: false,
            enriched: false,
            contactResults: [],
            metros: [],
            contactEnrichment: {},
            enrichLoading: false,
            searchLoading: false,
            creditsModal: false,
            searchErrorModal: false,
            loadMore: false,
            clickCount: 1,
            maxResults: 0,
            searchCriteria: {
                firstName: '',
                lastName: '',
                jobTitle: '',
                phone: '',
                companyName: '',
                address: '',
                city: '',
                state: '',
                street: '',
                zipCode: '',
                radius: '',
                metro: ''
            }
        };
    };

    const location = useLocation();
    const state = location.state as LocationState;
    const initialState = state?.searchCriteria || null;

    // Initialize state with values from sessionStorage or defaults
    const [searchType, setSearchType] = useState(() => getInitialState().searchType);
    const [searched, setSearched] = useState(() => initialState ? false : getInitialState().searched);
    const [enriched, setEnriched] = useState(() => initialState ? false : getInitialState().enriched);
    const [contactResults, setContactResults] = useState(() => getInitialState().contactResults);
    const [metros, setMetros] = useState(() => getInitialState().metros);
    const [contactEnrichment, setContactEnrichment] = useState(() => getInitialState().contactEnrichment);
    const [enrichLoading, setEnrichLoading] = useState(() => getInitialState().enrichLoading);
    const [searchLoading, setSearchLoading] = useState(() => getInitialState().searchLoading);
    const [creditsModal, setCreditsModal] = useState(() => getInitialState().creditsModal);
    const [searchErrorModal, setSearchErrorModal] = useState(() => getInitialState().searchErrorModal);
    const [loadMore, setLoadMore] = useState(() => getInitialState().loadMore);
    const [clickCount, setClickCount] = useState(() => getInitialState().clickCount);
    const [maxResults, setMaxResults] = useState(() => getInitialState().maxResults);
    const [searchCriteria, setSearchCriteria] = useState(() => initialState || getInitialState().searchCriteria);

    // Use useMemo to create combinedState object
    const combinedState = useMemo(() => ({
        searchType,
        searched,
        enriched,
        contactResults,
        metros,
        contactEnrichment,
        enrichLoading,
        searchLoading,
        creditsModal,
        searchErrorModal,
        loadMore,
        clickCount,
        maxResults,
        searchCriteria
    }), [
        searchType,
        searched,
        enriched,
        contactResults,
        metros,
        contactEnrichment,
        enrichLoading,
        searchLoading,
        creditsModal,
        searchErrorModal,
        loadMore,
        clickCount,
        maxResults,
        searchCriteria
    ]);

    // Use useEffect to store the combined state in sessionStorage whenever it changes
    useEffect(() => {
        const storedState = sessionStorage.getItem('combinedState');
        const parsedStoredState = storedState ? JSON.parse(storedState) : null;

        if (JSON.stringify(combinedState) !== JSON.stringify(parsedStoredState)) {
            sessionStorage.setItem('combinedState', JSON.stringify(combinedState));
        }
    }, [combinedState]);


    useEffect(() => {

        async function fieldOptions() {
            const bearer = await authPOST()
            const metros: any = await metroGET(bearer)
            setMetros(metros)
        }

        fieldOptions()

    }, [])

    const handleSearchCriteriaChange = (field: string, value: string) => {
        setSearchCriteria({ ...searchCriteria, [field]: value });
        //console.log(searchCriteria)
    };

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (searchLoading) {
            // Set the timer for 5 seconds (5000 milliseconds)
            timer = setTimeout(() => {
                setSearchLoading(false);
                alert('ZoomInfo search timed out');
            }, 10000);
        }
        if (enrichLoading) {
            // Set the timer for 5 seconds (5000 milliseconds)
            timer = setTimeout(() => {
                setEnrichLoading(false);
                alert('ZoomInfo search timed out');
            }, 10000);
        }

        // Cleanup the timer when the component unmounts or the state changes
        return () => clearTimeout(timer);
    }, [searchLoading, enrichLoading]);


    const contactResultsTEST = {
        "maxResults": 30579,
        "totalResults": 10,
        "currentPage": 1,
        "data": [
            {
                "id": 1434351821,
                "firstName": "Daniel",
                "middleName": "",
                "lastName": "Herbert",
                "validDate": "6/19/2020 1:41 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Director & Service Owner Information Technology Tools",
                "contactAccuracyScore": 96,
                "hasEmail": false,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 9867169,
                    "name": "AmerisourceBergen"
                }
            },
            {
                "id": 1910983889,
                "firstName": "Michael",
                "middleName": "A.",
                "lastName": "Manning",
                "validDate": "7/2/2020 9:31 AM",
                "lastUpdatedDate": "",
                "jobTitle": "Director, General Manager",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 103153842,
                    "name": "Triumph Group"
                }
            },
            {
                "id": 1458033004,
                "firstName": "Carmela",
                "middleName": "",
                "lastName": "Burston",
                "validDate": "7/8/2020 3:19 AM",
                "lastUpdatedDate": "",
                "jobTitle": "Senior Director, National retail Partner Sales",
                "contactAccuracyScore": 90,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 8929846,
                    "name": "Comcast"
                }
            },
            {
                "id": 1256317471,
                "firstName": "Michael",
                "middleName": "",
                "lastName": "Zedosky",
                "validDate": "7/6/2020 2:52 PM",
                "lastUpdatedDate": "7/12/2020 1:00 PM",
                "jobTitle": "Senior Director, Partner Sales",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 8929846,
                    "name": "Comcast"
                }
            },
            {
                "id": 1766622792,
                "firstName": "Maureen",
                "middleName": "",
                "lastName": "O'Connell",
                "validDate": "6/23/2020 8:00 PM",
                "lastUpdatedDate": "7/6/2020 11:05 PM",
                "jobTitle": "Northeast Partner Sales Director",
                "contactAccuracyScore": 94,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 8929846,
                    "name": "Comcast"
                }
            },
            {
                "id": 1562524332,
                "firstName": "Lori",
                "middleName": "",
                "lastName": "Miller",
                "validDate": "7/6/2020 3:00 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Global HR Business Partner & Director, Supply Chain",
                "contactAccuracyScore": 92,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 21252521,
                    "name": "DuPont"
                }
            },
            {
                "id": 1759016284,
                "firstName": "Nadine",
                "middleName": "",
                "lastName": "Durham",
                "validDate": "2/6/2020 4:49 AM",
                "lastUpdatedDate": "7/10/2020 9:23 AM",
                "jobTitle": "Service Owner & Director, Enterprise IT Services & Revenue Management",
                "contactAccuracyScore": 89,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 9867169,
                    "name": "AmerisourceBergen"
                }
            },
            {
                "id": 4061031,
                "firstName": "Michael",
                "middleName": "",
                "lastName": "McNamara",
                "validDate": "6/24/2020 8:00 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Director, Finance & Corporate Controller",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 46302607,
                    "name": "Marinus Pharmaceuticals"
                }
            },
            {
                "id": 250538259,
                "firstName": "Laurie",
                "middleName": "",
                "lastName": "Wajda",
                "validDate": "5/22/2020 11:23 AM",
                "lastUpdatedDate": "",
                "jobTitle": "Director, Human Resources & HR Business Partner",
                "contactAccuracyScore": 91,
                "hasEmail": true,
                "hasSupplementalEmail": true,
                "hasDirectPhone": true,
                "hasMobilePhone": true,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 19108134,
                    "name": "Campbell Soup"
                }
            },
            {
                "id": 1811800368,
                "firstName": "John",
                "middleName": "",
                "lastName": "Kelley",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Liz",
                "middleName": "",
                "lastName": "Smith",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Joy",
                "middleName": "",
                "lastName": "Heartman",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Becca",
                "middleName": "",
                "lastName": "Smart",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Chad",
                "middleName": "",
                "lastName": "McGee",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Boris",
                "middleName": "",
                "lastName": "Sloan",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Chris",
                "middleName": "",
                "lastName": "Tallow",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Mary",
                "middleName": "",
                "lastName": "Poppins",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            },
            {
                "id": 1811800368,
                "firstName": "Grady",
                "middleName": "",
                "lastName": "Trenchover",
                "validDate": "6/23/2020 12:28 PM",
                "lastUpdatedDate": "",
                "jobTitle": "Controller & Director, Finance",
                "contactAccuracyScore": 96,
                "hasEmail": true,
                "hasSupplementalEmail": false,
                "hasDirectPhone": true,
                "hasMobilePhone": false,
                "hasCompanyIndustry": true,
                "hasCompanyPhone": true,
                "hasCompanyStreet": true,
                "hasCompanyState": true,
                "hasCompanyZipCode": true,
                "hasCompanyCountry": true,
                "hasCompanyRevenue": true,
                "hasCompanyEmployeeCount": true,
                "company": {
                    "id": 12240745,
                    "name": "Church & Dwight"
                }
            }
        ]
    }

    const contactEnrichmentTEST = {
        "success": true,
        "data": {
            "outputFields": [
                [
                    "id",
                    "firstName",
                    "middleName",
                    "lastName",
                    "email",
                    "hasCanadianEmail",
                    "phone",
                    "directPhoneDoNotCall",
                    "street",
                    "city",
                    "region",
                    "metroArea",
                    "zipCode",
                    "state",
                    "country",
                    "continent",
                    "personHasMoved",
                    "withinEu",
                    "withinCalifornia",
                    "withinCanada",
                    "lastUpdatedDate",
                    "validDate",
                    "noticeProvidedDate",
                    "salutation",
                    "suffix",
                    "jobTitle",
                    "jobFunction",
                    "companyDivision",
                    "education",
                    "hashedEmails",
                    "picture",
                    "mobilePhoneDoNotCall",
                    "externalUrls",
                    "companyId",
                    "companyName",
                    "companyDescriptionList",
                    "companyPhone",
                    "companyFax",
                    "companyStreet",
                    "companyCity",
                    "companyState",
                    "companyZipCode",
                    "companyCountry",
                    "companyContinent",
                    "companyLogo",
                    "companySicCodes",
                    "companyNaicsCodes",
                    "contactAccuracyScore",
                    "companyWebsite",
                    "companyRevenue",
                    "companyRevenueNumeric",
                    "companyEmployeeCount",
                    "companyType",
                    "companyTicker",
                    "companyRanking",
                    "isDefunct",
                    "companySocialMediaUrls",
                    "companyPrimaryIndustry",
                    "companyIndustries",
                    "companyRevenueRange",
                    "companyEmployeeRange",
                    "employmentHistory",
                    "managementLevel",
                    "locationCompanyId"
                ]
            ],
            "result": [
                {
                    "input": {
                        "personid": 5190877047
                    },
                    "data": [
                        {
                            "id": 5190877047,
                            "firstName": "Dalton",
                            "middleName": "",
                            "lastName": "Silhan",
                            "email": "dsilhan@heartlandcompany.com",
                            "hasCanadianEmail": "false",
                            "phone": "620-218-0582",
                            "directPhoneDoNotCall": false,
                            "street": "1200 Main St Ste 4200",
                            "city": "Kansas City",
                            "region": "Missouri",
                            "metroArea": "Kansas City",
                            "zipCode": "64105",
                            "state": "Missouri",
                            "country": "United States",
                            "continent": "North America",
                            "personHasMoved": "Uncertain",
                            "withinEu": false,
                            "withinCalifornia": false,
                            "withinCanada": false,
                            "lastUpdatedDate": "01/09/2024 01:43 PM",
                            "validDate": "12/17/2023 07:00 PM",
                            "noticeProvidedDate": "02/07/2021",
                            "salutation": "",
                            "suffix": "",
                            "jobTitle": "GIS Developer",
                            "jobFunction": [
                                {
                                    "name": "HR / Financial / ERP Systems",
                                    "department": "Information Technology"
                                }
                            ],
                            "education": [],
                            "hashedEmails": [
                                "bd32210bdf3f2898704024a669df7f4a",
                                "d31d042cbcab39edc75fcdd6f89bb97eff6764339526c2c7f944917cd427cef46b4de44077085b51535064b077e5db31524004f7cbdfd67d5159ed39fd6f33c0",
                                "675fc73898769e4f501acf712b0935b4a4fca493",
                                "ffed7631efab915fabfa4b59a1f3316a98302c02259b877cd5f36a3e693a3aa5"
                            ],
                            "picture": "",
                            "mobilePhoneDoNotCall": false,
                            "externalUrls": [
                                {
                                    "type": "linkedin.com",
                                    "url": "https://www.linkedin.com/in/dalton-silhan-15b694184"
                                }
                            ],
                            "contactAccuracyScore": 94.0,
                            "isDefunct": false,
                            "employmentHistory": [
                                {
                                    "jobTitle": "GIS Analyst",
                                    "managementLevel": [],
                                    "fromDate": "2021-12-01T00:00:00.000Z",
                                    "toDate": "2023-10-01T00:00:00.000Z",
                                    "company": {
                                        "companyId": 438785571,
                                        "companyName": "HeartLand",
                                        "companyPhone": "(816) 683-8698",
                                        "companyWebsite": "www.heartlandcompany.com"
                                    }
                                },
                                {
                                    "jobTitle": "GIS Analyst & Intern",
                                    "managementLevel": [],
                                    "fromDate": "2020-11-01T00:00:00.000Z",
                                    "toDate": "2021-12-01T00:00:00.000Z",
                                    "company": {
                                        "companyId": 438785571,
                                        "companyName": "HeartLand",
                                        "companyPhone": "(816) 683-8698",
                                        "companyWebsite": "www.heartlandcompany.com"
                                    }
                                }
                            ],
                            "managementLevel": [
                                "Non-Manager"
                            ],
                            "locationCompanyId": 438785571,
                            "company": {
                                "division": "",
                                "id": 438785571,
                                "name": "HeartLand",
                                "descriptionList": [
                                    {
                                        "description": "Founder/CEOLeading Green Industry entrepreneur and executive with significant experience in private equity-sponsored, landscape consolidation strategies I have learned that exceptional companies are built by inspired people, working toward shared goals in an environment of mutual respect and trust. My role at HeartLand is dedicated to helping our people and companies become better versions of themselves, which creates opportunities for everyone involved. Chief Development OfficerLeads strategic planning, organizational development, organic sales growth and management training in pursuit of HeartLands vision We deploy integrated information systems to do more than track performance; we support field operations and make our operators better businesspeople. When our operators can make real-time, informed decisions, they can deliver exceptional service to our clients. Chief Financial OfficerLeads financial planning, reporting and internal control development for the company, including ERP and production systems across all HeartLand operating companies \"An acquisition is much more than a transaction it is an endless partnership among people that brings two organizations together; to create something much greater than either organization could accomplish on its own.\" Chief Acquisition OfficerLeads M&A division; including identifying, sourcing, qualifying, executing, and integrating acquisitions for HeartLand. In 2008, I became the Branch Manager at Signature Landscape where I was responsible for all sales, account management and operations. My goal was to develop a culture with high-energy, integrity, professional development and humor. I am grateful for the leaders of HeartLand who are committed to raising the bar for my team, from opening up new opportunities to providing the resources needed for us to succeed. Thanks to them, my team is learning at a more heightened level than ever before."
                                    }
                                ],
                                "phone": "(816) 683-8698",
                                "fax": "",
                                "street": "1200 Main St Ste 4200",
                                "city": "Kansas City",
                                "state": "Missouri",
                                "zipCode": "64105",
                                "country": "United States",
                                "continent": "North America",
                                "logo": "https://res.cloudinary.com/zoominfo-com/image/upload/w_100,h_100,c_fit/heartlandcompany.com",
                                "sicCodes": [
                                    {
                                        "id": "67",
                                        "name": "Holding And Other Investment Offices"
                                    },
                                    {
                                        "id": "671",
                                        "name": "Holding Offices"
                                    },
                                    {
                                        "id": "6719",
                                        "name": "Offices of Holding Companies, Not Elsewhere Classified"
                                    }
                                ],
                                "naicsCodes": [
                                    {
                                        "id": "55",
                                        "name": "Management of Companies and Enterprises"
                                    },
                                    {
                                        "id": "551",
                                        "name": "Management of Companies and Enterprises"
                                    },
                                    {
                                        "id": "5511",
                                        "name": "Management of Companies and Enterprises"
                                    },
                                    {
                                        "id": "55111",
                                        "name": "Management of Companies and Enterprises"
                                    },
                                    {
                                        "id": "551112",
                                        "name": "Offices of Other Holding Companies"
                                    },
                                    {
                                        "id": "54",
                                        "name": "Professional, Scientific, and Technical Services"
                                    },
                                    {
                                        "id": "541",
                                        "name": "Professional, Scientific, and Technical Services"
                                    },
                                    {
                                        "id": "5413",
                                        "name": "Architectural, Engineering, and Related Services"
                                    },
                                    {
                                        "id": "54132",
                                        "name": "Landscape Architectural Services"
                                    },
                                    {
                                        "id": "541320",
                                        "name": "Landscape Architectural Services"
                                    }
                                ],
                                "website": "www.heartlandcompany.com",
                                "revenue": "$9.6 Million",
                                "revenueNumeric": 9611000,
                                "employeeCount": 17,
                                "type": "PRIVATE",
                                "ticker": "",
                                "ranking": [],
                                "socialMediaUrls": [
                                    {
                                        "type": "LINKED_IN",
                                        "url": "http://www.linkedin.com/company/heartland-homescapes-llc",
                                        "followerCount": ""
                                    },
                                    {
                                        "type": "FACEBOOK",
                                        "url": "http://www.facebook.com/heartlandinvestigations",
                                        "followerCount": "49"
                                    },
                                    {
                                        "type": "",
                                        "url": "",
                                        "followerCount": ""
                                    }
                                ],
                                "primaryIndustry": [
                                    "Holding Companies & Conglomerates"
                                ],
                                "industries": [
                                    "Holding Companies & Conglomerates"
                                ],
                                "revenueRange": "$5 mil. - $10 mil.",
                                "employeeRange": "10 - 20"
                            }
                        }
                    ],
                    "matchStatus": "FULL_MATCH"
                }
            ],
            "requiredFields": []
        }
    }

    const onClickEnriched = () => {

        setEnriched((prevState: any) => !prevState)

    }

    const enrichLoadingFunct = () => {

        setEnrichLoading((prevState: any) => !prevState)

    }

    const creditsModalFunct = () => {

        setCreditsModal((prevState: any) => !prevState)

    }

    const enrichData = (value: any) => {

        setContactEnrichment(value)

    }

    const exportListModal = () => {
        if (props.exportListModal) props.exportListModal();
    }


    function authPOST() {

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Content-Type": "application/json",
            });

            const raw = JSON.stringify({
                "username": "dsilhan@heartlandcompany.com",
                "password": "Heartland2!"
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/authenticate", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    const bearer_token = result.jwt;
                    const bearer = 'Bearer ' + bearer_token;
                    resolve(bearer as any)
                    return bearer as any;
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    function contactPOST(bearer: any, page: number) {

        if (!loadMore) {
            page = 1
        }

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Authorization": bearer,
                "Content-Type": "application/json",
            });

            const raw = JSON.stringify({
                "firstName": searchCriteria.firstName || null,
                "lastName": searchCriteria.lastName || null,
                "jobTitle": searchCriteria.jobTitle || null,
                "phone": searchCriteria.phone ? [searchCriteria.phone] : null,
                "rpp": 100,
                "page": page,
                //"state": searchCriteria.state || null,
                "street": searchCriteria.street || null,
                "zipCode": searchCriteria.zipCode || null,
                "zipCodeRadiusMiles": searchCriteria.zipCode ? searchCriteria.radius : null,
                "metroRegion": searchCriteria.metro || null,
                "companyName": searchCriteria.companyName || null,
                //"locationSearchType": "Person"
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/search/contact", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        setSearchLoading(false);
                        setSearchErrorModal(true)
                        setContactResults([])
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    if (result.data.length === 0) {
                        resolve(false); // Resolve with false
                        setContactResults([])
                    } else {
                        setMaxResults(result.maxResults)
                        resolve(result.data as any); // Resolve with true
                        return result.data
                    }
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    function metroGET(bearer: any) {

        return new Promise((resolve, reject) => {
            const myHeaders = new Headers({
                "Authorization": bearer,
                "Content-Type": "application/json",
            });

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                //body: raw,
                redirect: "follow"
            };

            fetch("https://api.zoominfo.com/lookup/metroarea", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(result => {
                    //console.log(result)
                    if (result.length === 0) {
                        resolve(false); // Resolve with false
                    } else {
                        resolve(result as any); // Resolve with true
                        return result
                    }
                })
                .catch(error => {
                    reject(error); // Reject with the error
                });
        })
    }

    useEffect(() => {

        if (loadMore) {

            contactSearch()

        }

    }, [clickCount])


    const loadMoreFunct = () => {
        setLoadMore((prevState: any) => !prevState)
        setClickCount((prevCount: number) => prevCount + 1);
    }

    const sortContacts = (method: any, asc: boolean) => {
        if (method === "contactAccuracyScore") {
            contactResults.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
                const aValue = a[method];
                const bValue = b[method];
                return asc ? aValue - bValue : bValue - aValue;
            });
        } else if (["firstName", "lastName", "jobTitle"].includes(method)) {
            contactResults.sort((a: { [x: string]: string; }, b: { [x: string]: string; }) => {
                const aValue = a[method] || "";
                const bValue = b[method] || "";
                return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            });
        } else if (method === 'company.name') {
            contactResults.sort((a: { company: { name: string; }; }, b: { company: { name: string; }; }) => {
                const aValue = a.company?.name || "";
                const bValue = b.company?.name || "";
                return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            });
        }
        setContactResults([...contactResults]); // Update state with sorted results
    };

    async function contactSearch() {

        if ((!searchCriteria.zipCode && !searchCriteria.radius) || (searchCriteria.zipCode && searchCriteria.radius)) {

            //if (((searchCriteria.firstName || searchCriteria.lastName) && (searchCriteria.zipCode || searchCriteria.metro)) || (!searchCriteria.firstName && !searchCriteria.lastName)) {

            setSearchLoading(true);
            const bearer = await authPOST();
            const data: any = await contactPOST(bearer, clickCount);

            if (data.length > 0 && !loadMore) {
                // Sort data by contactAccuracyScore in descending order
                data.sort((a: { contactAccuracyScore: number; }, b: { contactAccuracyScore: number; }) => b.contactAccuracyScore - a.contactAccuracyScore);

                setContactResults(data as any);
                setSearchLoading(false);
                setSearched(true);
                setClickCount(1);
            } else if (data.length && loadMore) {
                // Sort new data by contactAccuracyScore and concatenate with previous results
                const sortedData = [...contactResults, ...data].sort((a, b) => b.contactAccuracyScore - a.contactAccuracyScore);
                setContactResults(sortedData);
                setSearchLoading(false);
                setLoadMore((prevState: any) => !prevState);
            }

            else {
                setSearchLoading(false);
                setSearchErrorModal(true)
            }
            /*}
            else {
                alert("If First or Last Name is entered, Zip Code or Metro is required.")
            }*/
        }
        else {
            alert("If Zip Code is populated, a Zip Code Radius must also be chosen.")
        }
    }

    const searchedNOTenriched = searched && !enriched && !enrichLoading

    const finishedEnrich = searched && enriched && !enrichLoading

    const NOTsearchedORenriched = !searched && !searchLoading && !enrichLoading

    useEffect(() => {
        console.log(NOTsearchedORenriched)
    }, [searched])

    return (
        <div className="property-list">

            <Modal
                open={creditsModal}
                onRequestClose={() => setCreditsModal(false)}
                passiveModal
                preventCloseOnClickOutside
                modalHeading="Insufficient credits"
            >
                <p className="modal-notice">
                    Your account has run out of credits used to retrieve contact information from ZoomInfo.
                    Please <a href="mailto:dsilhan@heartlandcompany.com?subject=SR%20Sales%20-%20ZoomInfo%20Credits%20Request&body=Please%20grant%20my%20account%20more%20credits%20for%20contact%20research">contact us</a> to request more.
                </p>
            </Modal>

            <Modal
                open={searchErrorModal}
                onRequestClose={() => setSearchErrorModal(false)}
                passiveModal
                preventCloseOnClickOutside
                modalHeading="No results found"
            >
                <p className="modal-notice">
                    Your search has returned 0 results. Please refine or simplify your search inputs and try again.
                    <br/>
                    <br/>
                    Reminder: Searches do not consume any of your alotted credits, so don't worry about searches returning no results!
                </p>
            </Modal>
            <div className={props.isMobile ? 'property-list-container-mobile' : 'property-list-container1'}>

                {!props.isMobile &&
                    <>
                        <span className="exportList">
                            <IconButton
                                onClick={exportListModal}
                                label="View export list"
                                kind="ghost"
                                align="left"
                                disabled={props.exportListArray.length ? false : true}
                            >
                                <TaskView size={16} className="is-favorite" />
                            </IconButton>
                        </span>
                        <div className="saved-title">ZoomInfo Search</div>
                        <div className="divider"></div>
                    </>
                }

                {NOTsearchedORenriched && contactResults.length > 0 && !props.isMobile &&

                    <div className="re-search-div">
                        <IconButton className="re-search" kind="ghost" label="Back to results" align="left" wrapperClasses="re-search-wrapper" onClick={() => { setSearched(true) }}>
                            <ArrowRight className="arrow-right" />
                        </IconButton>

                    </div>

                }

                {NOTsearchedORenriched && contactResults.length > 0 && props.isMobile &&

                    <div className="re-search-div">
                        <IconButton className="re-search" kind="ghost" label="Back to results" align="left" wrapperClasses="re-search-wrapper" onClick={() => { setSearched(true) }}>
                            <ArrowRight className="arrow-right" />
                        </IconButton>

                    </div>

                }

                {NOTsearchedORenriched &&
                    <div className="search-container">
                        {/*<div className="search-by-title">
                            <div className="mode-switch">
                                <Button className={searchType === 'Contact' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' onClick={(evt: any) => { setSearchType('Contact') }}>
                                    Contact
                                </Button>
                                <Button className={searchType === 'Company' ? 'mode-switch-button-selected' : 'mode-switch-button'} kind='ghost' disabled={true} onClick={(evt: any) => { setSearchType('Company') }}>
                                    Company
                                </Button>
                </div>
                </div>*/}

                        {searchType === 'Contact' &&
                            <div className="saved-list">
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="First Name"
                                        value={searchCriteria.firstName}
                                        onChange={(e: any) => handleSearchCriteriaChange('firstName', e.target.value)}
                                    />

                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Last Name"
                                        value={searchCriteria.lastName}
                                        onChange={(e: any) => handleSearchCriteriaChange('lastName', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Job Title"
                                        helperText="Input multiple titles by separating with 'OR'"
                                        value={searchCriteria.jobTitle}
                                        onChange={(e: any) => handleSearchCriteriaChange('jobTitle', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Phone"
                                        value={searchCriteria.phone}
                                        onChange={(e: any) => handleSearchCriteriaChange('phone', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Company Name"
                                        value={searchCriteria.companyName}
                                        onChange={(e: any) => handleSearchCriteriaChange('companyName', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Street"
                                        value={searchCriteria.street}
                                        onChange={(e: any) => handleSearchCriteriaChange('street', e.target.value)}
                                    />
                                </div>
                                <br />
                                <div className='row'>
                                    <TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="Zip Code"
                                        value={searchCriteria.zipCode}
                                        onChange={(e: any) => handleSearchCriteriaChange('zipCode', e.target.value)}
                                    />

                                    {/*<TextInput
                                        id="text-input"
                                        type="text"
                                        labelText="State"
                                        value={searchCriteria.state}
                                        onChange={(e: any) => handleSearchCriteriaChange('state', e.target.value)}
                />*/}
                                    <Select
                                        id="city-select"
                                        labelText="Zip Code Radius"
                                        onChange={(e: any) => handleSearchCriteriaChange('radius', e.target.value)}
                                        value={searchCriteria.radius}
                                    >
                                        <SelectItem
                                            value=""
                                        />
                                        {[{ name: "10", text: "10 Miles" }, { name: "25", text: "25 Miles" }, { name: "50", text: "50 Miles" }].map((radius) => (
                                            <SelectItem
                                                key={radius.name}
                                                text={radius.text}
                                                value={radius.name}
                                            />
                                        ))}
                                    </Select>
                                    <Select
                                        id="city-select"
                                        labelText="Metro Area"
                                        onChange={(e: any) => handleSearchCriteriaChange('metro', e.target.value)}
                                        value={searchCriteria.metro}
                                    >
                                        <SelectItem
                                            value=""
                                        />
                                        {metros.map((metro: { Id: any; Name: any; }) => (
                                            <SelectItem
                                                key={metro.Id}
                                                text={metro.Name}
                                                value={metro.Id}
                                            />
                                        ))}
                                    </Select>
                                </div>
                                {/*<br />
                                    <TextInput id="text-input" type="text" labelText="Zip Code" />
                                    <br />
                    <TextInput id="text-input" type="text" labelText="State" />*/}
                            </div>
                        }
                        {searchType === 'Company' &&
                            <div className="saved-list">
                                <TextInput id="text-input" type="text" labelText="Company Name" />
                                <br />
                                <TextInput id="text-input" type="text" labelText="Company Location" />
                                <br />
                                <TextInput id="text-input" type="text" labelText="Company Industry" />
                            </div>
                        }
                        <Button className="search-button" kind='primary' onClick={() => { contactSearch() }}>
                            Search
                            <ArrowRight className="arrow-right" />
                        </Button>
                    </div>
                }
                {searchLoading &&

                    <div className="loading-div">

                        <InlineLoading
                            status="active"
                            iconDescription="Loading search results"
                            description="Loading search results..."
                        />
                    </div>

                }

                {enrichLoading &&

                    <div className="loading-div">

                        <InlineLoading
                            status="active"
                            iconDescription="Loading contact information"
                            description="Loading contact information..."
                        />
                    </div>
                }
                {searchedNOTenriched && (
                    <>
                        <div className="search-results-header">
                            <Tooltip
                                delay={100}
                                direction="right"
                                content={'Back to search'}
                            >
                                <Button className="back-button" kind='ghost' onClick={() => setSearched(false)}>
                                    <ArrowLeft className="arrow-right" />
                                </Button>
                            </Tooltip>
                            <p className="results-text">
                                Showing {contactResults.length} of {maxResults} contact results
                            </p>
                            <div className="sort-container">
                                <div className="sort-div">
                                    <Accordion>
                                        <AccordionItem title={"Sort"}>
                                            {/*<ContentSwitcher>
                                                <Switch name="one" text="First section" />
                                                <Switch name="two" text="Second section" />
                                                <Switch name="three" text="Third section" />
                </ContentSwitcher>*/}

                                            {[{ text: "First Name", filter: "firstName" }, { text: "Last Name", filter: "lastName" }, { text: "Job Title", filter: "jobTitle" }, { text: "Company Name", filter: "company.name" }, { text: "Accuracy Score", filter: "contactAccuracyScore" }].map((field) => (

                                                <div className="row">

                                                    <p>
                                                        {field.text}
                                                    </p>
                                                    <div className="sort-buttons-div">


                                                        <IconButton
                                                            onClick={(e: any) => sortContacts(field.filter, false)}
                                                            label="Descending"
                                                            kind="ghost"
                                                            align="top-right"
                                                        //disabled={props.exportListArray.length ? false : true}
                                                        >
                                                            <SortDescending size={16} className="is-favorite" />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(e: any) => sortContacts(field.filter, true)}
                                                            label="Ascending"
                                                            kind="ghost"
                                                            align="top-right"
                                                        //disabled={props.exportListArray.length ? false : true}
                                                        >
                                                            <SortAscending size={16} className="is-favorite" />
                                                        </IconButton>
                                                    </div>

                                                </div>
                                            ))}


                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        {contactResults.map((contact: { [key: string]: any; }, index: React.Key | null | undefined) => (
                            <ZoomInfoItem
                                key={index}
                                contactResult={contact}
                                onClick={onClickEnriched}
                                contactEnrich={enrichData}
                                userTables={props.userTables}
                                user={props.user}
                                enrichLoading={enrichLoadingFunct}
                                creditsModal={creditsModalFunct}

                            />
                        ))}
                        {!loadMore && (maxResults > contactResults.length) &&

                            <div className="load-more-container">
                                {/*UPDATES FROM NATALIE: Load more button*/}
                                <Button renderIcon={Add}
                                    onClick={loadMoreFunct}
                                    kind="primary"
                                    size="lg">
                                    Load more contacts
                                </Button>
                            </div>
                        }
                    </>
                )}
                {finishedEnrich && (
                    <>
                        <ZoomInfoDetails
                            Contact={contactEnrichment}
                            onClick={onClickEnriched}
                            exportList={props.exportList}
                            filteredCredentials={props.filteredCredentials}
                        />
                    </>
                )}
            </div>
        </div>

    )
}