import React, { useEffect, useRef } from 'react'
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";

interface iProps {
  view: __esri.MapView;
  activeWidget: string  | null | undefined;
  open: boolean;
}

export default function DistanceWidget(props: iProps) {

  const widget = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<__esri.DistanceMeasurement2D | null>();

  useEffect(() => {
    if (props.open && props.activeWidget === "distance") {
      widgetRef.current = new DistanceMeasurement2D({
        view:props.view,
        //UPDATES FROM NATALIE: default units
        unit: "miles",
        //END UPDATES FROM NATALIE          
        container: widget.current!
      })
      widgetRef.current.viewModel.start();
    }
    
    return () => { 
      widgetRef.current && widgetRef.current.destroy(); 
    }
  },[props.open, props.view, props.activeWidget])
    
  return <div ref={widget}></div>
}