import React from 'react';
import {Navigate} from 'react-router-dom';

interface iProps {
  statusChecked: boolean;
  isSignedIn: boolean;
  children: JSX.Element
}

interface iState {
  hasError: boolean;
}

export default class AuthGuard extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props);
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {

    if (this.state.hasError) {
      return <Navigate to="/" replace />;
    }

    if (this.props.isSignedIn) {
      return this.props.children;
    } else if (this.props.statusChecked && !this.props.isSignedIn) {
      return <Navigate to="/" replace />
    } else {
      return (
        <div>
          {/* Checking status */}
        </div>
      );
    }
  }
}