import React, {useState} from 'react';
import './saved-property-list-item.scss';
import Property from '../../../models/property';
import {roundString} from '../../../utilities';
import {useNavigate} from "react-router-dom";

import {
  Button
  //@ts-ignore
} from '@carbon/react';

import {
  Document,
  Enterprise,
  FavoriteFilled,
  TrashCan,
  User,
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  property: Property;
  removeProperty: (propertyId: number) => void;
  hasNote: boolean;
}


export default function SavedPropertyListItem(props: iProps) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const hasManagerInfo = props.property.propertyManagerContact || props.property.propertyManagerName;
  const hasOwnerInfo = props.property.ownerContact || props.property.ownerName;
  const hasLeasingCompanyInfo = props.property.leasingCompanyContact || props.property.leasingCompanyName;

  const remove = (evt: any) => {
    evt.stopPropagation();
    if (props.removeProperty)
      props.removeProperty(props.property.id);

  }

  const goToProperty = () => {
    navigate("/properties", {replace: false, state: {property: props.property}});
  }

  const iconSize = 16;
  return (
    <div className='saved-property-list-item'
      onClick={goToProperty}
      onMouseEnter={() => {setHover(true)}}
      onMouseLeave={() => {setHover(false)}} >
      <div className="row">
        <div style={{flex: 1.2}}>
          <div className="sub-section mb">
            <span className="text-primary">
              {props.property.name ? props.property.name : props.property.address}
            </span>
            <FavoriteFilled size={iconSize} className="is-favorite" />
            <Document size={iconSize} className={props.hasNote ? "" : "disabled-icon"} />
          </div>

          <div className='sub-section text-secondary'>
            {props.property.address}, {props.property.city}, {props.property.state} {props.property.zip}
          </div>
        </div>

        <div style={{flex: 1}} className='sub-section text-tertiary'>
          <span>
            {props.property.type}
          </span>
          <span>
            {roundString(props.property.acres, 1)} acres
          </span>
        </div>

        {
          !hover &&

          <div className="sub-section rh-item">
            <User size={iconSize} className={hasManagerInfo ? "" : "disabled-icon"} />
            <Enterprise size={iconSize} className={hasOwnerInfo || hasLeasingCompanyInfo ? "" : "disabled-icon"} />
            <span>
              {props.property.rank}%
            </span>
          </div>
        }
        {
          hover &&
          <div className='rh-item'>
            <Button
              onClick={remove}
              kind="danger--ghost"
              size="sm">
              Remove&nbsp;
              <TrashCan />
            </Button>
          </div>
        }
      </div>
    </div>
  );
}