
export const emailFail = (opco: any, message: any, address: any) => {
    var requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow'
    };

    var url = "https://api.elasticemail.com/v2/email/send?apikey=0F77A0082EEF0D99F6963EA94673DB77409236ABC797FA90D9E527ED8FDC7C67DD6839DA5CB29C18610DC341C9029A17&subject=" + "SR Sales: Send to Aspire FAILED&from=daltonsilhan@gmail.com&to=dsilhan@heartlandcompany.com&" + "bodyText=Send to Aspire failed for " + opco + " (" + "Error " + message + "). " + "Address : " + address
    
    fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

export const emailSuccess = (opco: any, address: any) => {
    var requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow'
    };

    var url = "https://api.elasticemail.com/v2/email/send?apikey=0F77A0082EEF0D99F6963EA94673DB77409236ABC797FA90D9E527ED8FDC7C67DD6839DA5CB29C18610DC341C9029A17&subject=" + "SR Sales: Send to Aspire SUCCESS&from=daltonsilhan@gmail.com&to=dsilhan@heartlandcompany.com&" + "bodyText=Send to Aspire succeeded for " + opco + ". " + "Address : " + address
    
    fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}