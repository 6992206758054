import React, {useRef, useState, useEffect} from "react";
import "./note.scss";
import UserNote from "../../../../../models/user-note";

import {
  //@ts-ignore
  OverflowMenu,
  OverflowMenuItem,
  TextArea,
  IconButton
  //@ts-ignore
} from '@carbon/react';

import {
  Checkmark,
  Close,
  Edit,
  TrashCan,
  // @ts-ignore
} from '@carbon/icons-react';

interface iProps {
  note: UserNote;
  deleteNote: (objectId: number) => void;
  updateNote: (objectId: number, noteText: string) => void;
}

export default function Note(props: iProps) {
  const [editing, setEditing] = useState(false);
  const [newNoteText, setNewNoteText] = useState(props.note.noteText);
  const editInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editInput.current) {
      editInput.current.focus();
    }
  }, [editing])

  const editNote = () => {
    setEditing(true);
    setNewNoteText(props.note.noteText);
  }

  const submitNoteUpdate = () => {
    if (newNoteText) {
      setEditing(false);
      props.updateNote(props.note.objectId, newNoteText);
    }
  }

  const cancelEditNote = () => {
    setEditing(false);
  }


  const deleteNote = () => {
    props.deleteNote(props.note.objectId);
  }

  const date = new Date(props.note.modifiedTimestamp);
  return (
    <div className="note">
      {!editing &&
        <div className="row">
          <div className="note-text">{props.note.noteText}</div>
          <OverflowMenu ariaLabel="Edit/Delete Note" selectorPrimaryFocus={'.note-edit-menu-item'} //UPDATES FROM NATALIE: Removed blue highlight around edit
            size="sm"
            flipped={true}>
            <OverflowMenuItem
              itemText={<span className="note-edit-menu-item"><Edit size={14} />Edit</span>}
              onClick={editNote}
            />
            <OverflowMenuItem
              itemText={<span className="note-edit-menu-item"><TrashCan size={14} />Delete</span>}
              onClick={deleteNote}
            />
          </OverflowMenu>
        </div>
      }
      {editing &&
        <div>
          <TextArea
            className='rename-input'
            ref={editInput}
            id={`rename-search-${props.note.objectId}-input`}
            hideLabel={true}
            labelText={`Edit Note`}
            value={newNoteText}
            onChange={(evt: any) => {setNewNoteText(evt.target.value)}}
            onClick={(evt: any) => {evt.stopPropagation()}}
            onKeyDown={(evt: any) => {if (evt.key === 'Enter') {submitNoteUpdate()} }}
            placeholder='Enter new name...' />

          <IconButton
            onClick={submitNoteUpdate}
            label="Submit"
            kind="primary"
            size="sm"
          >
            <Checkmark />
          </IconButton>
          <IconButton
            onClick={cancelEditNote}
            label="Cancel"
            kind="secondary"
            size="sm"
          >
            <Close />
          </IconButton>
        </div>
      }
      {!editing &&
        <div className="note-metadata">
          {props.note.userFullName ? props.note.userFullName : props.note.userId}&nbsp;
          {date.toLocaleDateString('en-US')} {date.toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'})}
        </div>
      }
    </div>
  );
}

