import './home.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../images/icon.png';
import mapImage from '../../images/map-view.png';

import {
  Button,
  //@ts-ignore
} from '@carbon/react';

//UPDATES FROM NATALIE: Sign in icon
import {
  Login
  // @ts-ignore
} from '@carbon/icons-react';
//END UPDATES FROM NATALIE

interface iProps {
  isSignedIn: boolean;
  login: () => Promise<void>;
}

export default function Home(props: iProps) {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  var log = function log(message: string, isError: boolean | undefined) {
    isError ? console.error(message) : console.log(message);
  };

  function _catch(body: { (): Promise<void> | undefined; (): any; }, recover: { (error: any): void; (arg0: unknown): any; }) {
    try {
      var result = body();
    } catch (e) {
      return recover(e);
    }
    if (result && result.then) {
      return result.then(void 0, recover);
    }
    return result;
  }

  function refreshCacheAndReload() {
    try {
      return Promise.resolve(_catch(function () {
        var _temp3 = function () {
          var _window;

          if ((_window = window) !== null && _window !== void 0 && _window.caches) {
            var _window2 = window,
              caches = _window2.caches;
            return Promise.resolve(caches.keys()).then(function (cacheNames) {
              var cacheDeletionPromises = cacheNames.map(function (n) {
                return caches["delete"](n);
              });
              return Promise.resolve(Promise.all(cacheDeletionPromises)).then(function () {
                console.log('cache deleted')
                window.location.reload();
              });
            });
          }
        }();

        if (_temp3 && _temp3.then) return _temp3.then(function () { });
      }, function (error: any) {
        log('An error occurred while deleting the cache.', true);
        log(error, true);
      }));
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const caching = () => {
    let metaVersion = '';
    console.log("cache check")

    return Promise.resolve(fetch("/meta.json")).then(function (res) {
      return Promise.resolve(res.json()).then(function (_ref2) {
        metaVersion = _ref2.version;
        let version = metaVersion;

        console.log(version, process.env.REACT_APP_VERSION)

        if (version !== process.env.REACT_APP_VERSION) {
          refreshCacheAndReload()
          /*console.log("cache busting")
          if ('caches' in window) {
            caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach(name => {
                caches.delete(name);
              });
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
          }*/

          //localStorage.clear();
          //localStorage.setItem('version', packageJson.version);
        }
      });
    });
  };

  useEffect(() => {
    //caching();
    if (props.isSignedIn) {
      navigate("/start");
    }
  }, []);

  // Used to prevent button(jumping) from 
  // rendering before rest of page
  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1000)
    return () => setShowButton(false);
  }, [])

  const signIn = () => {
    props.login().then(() => {
      navigate("/start");
    });
  }

  return (
    <div className='home-container'>
      <div className='img-container'>
        <img src={logo} alt="SmartReach logo" className='responsive-img' />
      </div>
      <div className='map-img-container'>
        <img src={mapImage} alt="View of map." className='map-img' />
      </div>
      {showButton && !props.isSignedIn &&
        <Button className="signin-btn" onClick={signIn} size='lg' renderIcon={Login}>Please Sign In</Button> //UPDATES FROM NATALIE: Add icon to button
      }
    </div>
  );
}