import React, { useState } from 'react'
import './navbar.scss'
import { Link, useLocation } from "react-router-dom";

import {
  HeaderMenuButton,
  HeaderName,
  SideNav,
  SideNavMenuItem,
  SideNavItems,
  //@ts-ignore
} from '@carbon/react';


interface iProps {
  links: { path: string, label: string }[];
};

export default function MobileNavBar(props: iProps) {
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const location = useLocation();


  return (
    <>
      <HeaderMenuButton
        aria-label="Open menu"
        isCollapsible
        onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
        isActive={isSideNavExpanded}
      />
      <HeaderName element={Link} to="/" prefix="">
        <span className="branding">
          <span className='smartreach-logo' >
            <span className='font-light'>
              SR
            </span>
            &nbsp;Sales
          </span>
        </span>
      </HeaderName>
      {isSideNavExpanded &&
        <SideNav
          className='sidebar'
          aria-label="Side Navigation"
          // isRail
          expanded={isSideNavExpanded}
          onOverlayClick={() => setIsSideNavExpanded(false)}
        >
          <SideNavItems>
            {props.links.map((link, index) => {
              return (
                <SideNavMenuItem element={Link} to={link.path} isActive={location.pathname.startsWith(link.path)} key={index}>
                  {link.label}
                </SideNavMenuItem>
              );
            })
            }
          </SideNavItems>
        </SideNav>
      }
    </>
  )
}
