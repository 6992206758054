import React, { useState } from 'react';
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionItem
  //@ts-ignore
} from '@carbon/react';

interface iProps {
  isSignedIn: boolean;
  isMobile: boolean;
}

export default function ModalManagerHelp(props: iProps) {
  // get variables from navbar
  let isSignedIn = props.isSignedIn;
  let isMobile = props.isMobile;
  let location = useLocation();

  let showOrHideQ1 = "hidden";
  let showOrHideQ2 = "hidden";
  let showOrHideQ3 = "hidden";
  let showOrHideQ4 = "hidden";
  let showOrHideQ5 = "hidden";
  let showOrHideQ6 = "hidden";
  let showOrHideQ7 = "hidden";
  let showOrHideQ8 = "hidden";
  let showOrHideQ9 = "hidden";
  let showOrHideQ10 = "hidden";
  let showOrHideQ11 = "hidden";
  let showOrHideQ12 = "hidden";
  let showOrHideQ13 = "hidden";
  let showOrHideQ14 = "hidden";

  const selectQuestions = () => {
    // start options
    if (location.pathname == "/start") {
      if (!isMobile) {
        // Q1 Desktop Start
        showOrHideQ1 = "show";
      } else {
        // Q2 Mobile Start
        showOrHideQ2 = "show";
      }
    }
    // prospecting options
    else if (location.pathname == "/properties") {
      // Q3-7 Prospecting
      showOrHideQ3 = "show";
      showOrHideQ4 = "show";
      showOrHideQ5 = "show";
      showOrHideQ6 = "show";
      showOrHideQ7 = "show";
    } // end prospecting options
    // new sales options
    else if (location.pathname == "/sales") {
      if (!isMobile) {
        // Q8 Desktop Sales
        showOrHideQ13 = "show";
      } else {
        // Q9 Mobile Sales
        showOrHideQ14 = "show";
      }
    }
    else if (location.pathname == "/contacts") {
        showOrHideQ13 = "show";
        showOrHideQ14 = "show";
    }
    // landing page
    else {
      // if signed in
      if (isSignedIn) {
        if (!isMobile) {
          // Q10 signed In Desktop Landing
          showOrHideQ10 = "show";
        } else {
          // Q11 signed In Mobile Landing
          showOrHideQ11 = "show";
        }
      }
      // if signed out
      else {
        // Q12 signed Out Landing
        showOrHideQ12 = "show";
      }
    }
  } // end selectQuestions

  selectQuestions();

  return (
    <Accordion>
      {/* Q1 Desktop Start*/}
      <AccordionItem title="How do I view the map?" className={showOrHideQ1}>
        At the top of the screen, click the Properties button to see the map.
      </AccordionItem>
      {/* Q2 Mobile Start*/}
      <AccordionItem title="How do I view the map?" className={showOrHideQ2}>
        At the top left of the screen, click on the three horizontal lines.
        <br></br>
        <br></br>
        From there you can navigate to the properties map or the New Sales dashboard.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="How do I filter properties by area?" className={showOrHideQ3}>
        Click the "Draw boundary" button on the top right corner of the map.
        <br></br>
        <br></br>
        Next you have the option to draw a circle, a polygon, or freehand.
        <br></br>
        <br></br>
        For the draw Circle and Freehand options, click and drag to outline the places you wish to select.
        <br></br>
        <br></br>
        For the draw Polygon option, click to make each corner of the place you wish to select and double click to complete the figure.
        <br></br>
        <br></br>
        You can add multiple boundaries to the map. Click an option to add another boundary.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="How do I measure distances?" className={showOrHideQ4}>
        Click the ruler button.
        <br></br>
        <br></br>
        Next you have the option to measure by distance or by area.
        <br></br>
        <br></br>
        Click Add a measurement to add another measurement to the map.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="What does the heat map show?" className={showOrHideQ5}>
        The heatmap shows high revenue areas with colors closer to yellow and low revenue areas with colors closer to blue.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="How can I tell the if the drive time is within a 30 minute range?" className={showOrHideQ5}>
        The areas on the map with a drive time of 30 minutes or less are highlighted in transparent white.
        <br></br>
        <br></br>
        You can also filter properties by drive time under More Filters.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="How do I edit contact information for a property or request a contact?" className={showOrHideQ6}>
        Click on a property on the map or in the properties list.
        <br></br>
        <br></br>
        At the top right of the property details, click on the edit button marked with the pencil icon.
        <br></br>
        <br></br>
        To request a contact, click "Request contact info" when there is no contact information available for a property.
      </AccordionItem>
      {/* Q3-7 Prospecting*/}
      <AccordionItem title="How do I filter Aspire data?" className={showOrHideQ7}>
        Click on More filters.
        <br></br>
        <br></br>
        Choose whether to filter CRM prospects or Active contracts from Aspire.
      </AccordionItem>
      {/* Q8 desktop sales*/}
      <AccordionItem title="How do I view the other map?" className={showOrHideQ8}>
        At the top of the screen, click the Prospecting button to see the map.
      </AccordionItem>
      {/* Q9 mobile sales*/}
      <AccordionItem title="How do I view the other map?" className={showOrHideQ9}>
        At the top left of the screen, click on the three horizontal lines.
        <br></br>
        <br></br>
        From there you can navigate to the prospecting map or the New Sales dashboard.
      </AccordionItem>
      {/* Q10 signed in desktop landing*/}
      <AccordionItem title="How do I view the map?" className={showOrHideQ10}>
        At the top of the screen, click the Prospecting button to see the map.
      </AccordionItem>
      {/* Q11 signed in mobile landing*/}
      <AccordionItem title="How do I view the map?" className={showOrHideQ11}>
        At the top left of the screen, click on the three horizontal lines.
        <br></br>
        <br></br>
        From there you can navigate to the prospecting map or the New Sales dashboard.
      </AccordionItem>
      {/* Q12 signed out landing*/}
      <AccordionItem title="Issues logging in?" className={showOrHideQ12}>
        Once you click the blue "Please Sign In" button, a new window will popup.
        <br></br>
        <br></br>
        If you forgot your username or password
        <br></br>
        <br></br>
        then click on the appropriate links under the Sign In button and follow the instructions
      </AccordionItem>
      {/* Q13 signed in mobile landing*/}
      <AccordionItem title="Why am I not receiving any results for my ZoomInfo Search?" className={showOrHideQ13}>
        Our API connection is capable of retrieving all contact info available from ZoomInfo.
        <br></br>
        <br></br>
        Continue to add/remove field values to receive results.
        <br></br>
        <br></br>
        If still no results, ZoomInfo does not have the desired contact info available.
      </AccordionItem>
      {/* Q14 signed out landing*/}
      <AccordionItem title="Why are there errors in sending my contacts to Aspire?" className={showOrHideQ14}>
        The fields marked by a red astirisk in the upload modal are required.
        <br></br>
        <br></br>
        Any required fields left blank will cause a contact not to be uploaded to Aspire.
      </AccordionItem>
    </Accordion>
  );
}