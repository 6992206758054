import iConfig from "./iConfig";
const config: iConfig = {
  oAuthInfo: {
    portalUrl: 'https://heartlandco.maps.arcgis.com/',
    appId: 'FOkcGWM9oEFy7WWh',
  },
  userTables: {
    searchHistory: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/ArcGIS/rest/services/SmartReach_User_Tables/FeatureServer/0',
    savedSessions: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/ArcGIS/rest/services/SmartReach_User_Tables/FeatureServer/1',
    notes: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/ArcGIS/rest/services/SmartReach_User_Tables/FeatureServer/2',
    userMarkets: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/ArcGIS/rest/services/SmartReach_User_Tables/FeatureServer/3',
    favoriteProperties: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/ArcGIS/rest/services/SmartReach_User_Tables/FeatureServer/4',
    userPlatform: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/SmartReach_User_Tables/FeatureServer/5'
  },
  //propertyLayer: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/SR3_1properties/FeatureServer', //3.1
  //propertyLayer: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/CostarProperties_SR3_2/FeatureServer', //3.2
  propertyLayer: 'https://services1.arcgis.com/dqIGQgErV3nGqtSd/arcgis/rest/services/SR_Sales_CoStar/FeatureServer', //3.2.1
  drawSymbol: {
    type: 'simple-fill',
    style: "none",
    outline: {
      color: [15, 98, 254],
      width: 3
    }
  }
};

export default config;