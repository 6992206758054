import Credential from '@arcgis/core/identity/Credential';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import Portal from '@arcgis/core/portal/Portal';


export default class AuthService {
  private static instance: AuthService;
  public static initialized: boolean = false;
  private static credential: Credential | null;
  private static oauthInfo: OAuthInfo;
  private static portal: Portal;

  // Singleton pattern
  private constructor() { }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  /**
   * Register application ID and Portal URL
   * with the IdentityManager
   * @param appId
   * @param portalUrl
   */
  initializeIdentityManager = (appId: string, portalUrl: string) => {
    if (!AuthService.initialized) {
      if (!AuthService.oauthInfo) {
        AuthService.oauthInfo = new OAuthInfo({
          appId,
          portalUrl,
          popup: true
        });
        IdentityManager.registerOAuthInfos([AuthService.oauthInfo]);
      }
      if (!AuthService.portal) {
        AuthService.portal = new Portal({
          url: portalUrl
        });
      }
      AuthService.initialized = true;
    }
  };

  /**
   * Check current logged in status for current portal
   */
  checkCurrentStatus = async () => {
    AuthService.credential = await IdentityManager.checkSignInStatus(`${AuthService.oauthInfo.portalUrl}/sharing`);
    return AuthService.credential;
  };

  hasValidCredential = () => {
    return !!(AuthService.credential && Date.now() < AuthService.credential.expires);
  }

  /**
   * Attempt to sign in,
   * first check current status
   * if not sighned in, then go through
   * steps to get credentials
   */
  signIn = async () => {
    if (!AuthService.credential) {
      try {
        AuthService.credential = await this.checkCurrentStatus();
      } catch (error) {
        AuthService.credential = await this.fetchCredentials();
      }
    }
    return AuthService.credential;
  };

  /**
   * Sign the user out, but if we checked credentials
   * manually, make sure they are registered with
   * IdentityManager, so it can destroy them properly
   */
  signOut = async () => {
    // make sure the identitymanager has
    // the credential so it can destroy it
    await this.signIn();
    IdentityManager.destroyCredentials();
    AuthService.credential = null;
  };

  /**
   * Get the credentials for the provided portal
   */
  fetchCredentials = async () => {
    AuthService.credential = await IdentityManager.getCredential(`${AuthService.oauthInfo.portalUrl}/sharing`, {
      error: null as any,
      oAuthPopupConfirmation: false,
      token: null as any
    });
    return AuthService.credential;
  };

  /**
   * Get PortalUser object for signed in user
   */
  getPortalUser = async () => {
    if (AuthService.credential && AuthService.portal) {
      if (AuthService.portal.loaded) {
        return AuthService.portal.user;
      } else {
        await AuthService.portal.load()
        return AuthService.portal.user;
      }
    } else {
      return null;
    }
  }
}