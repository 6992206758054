
import React, { useState, useCallback, useRef, useEffect } from "react";
import './filter-dropdown.scss';

import FilterModal from "../filter-modal/FilterModal";
import { useMediaQuery } from 'react-responsive'
import { DeviceSize } from '../../../../responsive';

import {
  Button,
  //@ts-ignore
} from '@carbon/react';

import {
  ChevronDown,
  //@ts-ignore
} from '@carbon/icons-react';

interface iProps {
  buttonLabel: string;
  title: string;
  buttonClass: string;
  children: JSX.Element;

  onApply?: Function;
  onReset?: Function;
};

export default function FilterDropdown(props: iProps) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [open, setOpen] = useState(false);
  const dropdownButton = useRef<any>(null);
  const chevron = useRef<any>(null);
  const dropdownContent = useRef<any>(null);

  const windowClickListener = useCallback((evt: any) => {
    if (evt.target === dropdownButton.current || evt.target === chevron.current) {
      return;
    }

    if (dropdownContent.current) {
      if (!dropdownContent.current.contains(evt.target) || evt.target.classList.contains('close-dropdown')) {
        setOpen(false);
        window.removeEventListener('click', windowClickListener);
      }
    }
  }, [])

  // cleans up event listener
  useEffect(() => {
    return () => {
      window.removeEventListener('click', windowClickListener);
    }
  }, [windowClickListener]);


  const toggleDropdown = (evt: any) => {
    if (!open) {
      setOpen(true);
      window.addEventListener('click', windowClickListener);
    } else {
      closeDropdown();
    }
  }

  const closeDropdown = () => {
    setOpen(false);
    window.removeEventListener('click', windowClickListener);
  }

  const onApply = () => {
    if(props.onApply) {
      props.onApply();
    }
    closeDropdown();
  }

  return (
    <>
    <div className="filter-dropdown">
      <Button
        onClick={toggleDropdown}
        className={props.buttonClass}
        size="sm"
        ref={dropdownButton}>
        {props.buttonLabel}&nbsp;
        <ChevronDown size={14} ref={chevron} />
      </Button>
      {!isMobile &&
        <>
          {open &&
            <div className="dropdown-content" ref={dropdownContent}>
              <div className="filter-header">
                <span className="filter-title">{props.title}</span>
                <Button
                  onClick={props.onReset}
                  className='reset-filter-button'
                  size="sm"
                  kind="ghost">
                  Reset filters
                </Button>
              </div>
              <div className="filter-content">
                {props.children}
              </div>
              <div className="filter-footer">
                <Button
                  onClick={closeDropdown}
                  className="footer-button"
                  size="xl"
                  kind="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={onApply}
                  className="footer-button"
                  size="xl"
                  kind="primary">
                  Apply
                </Button>
              </div>
            </div>
          }
        </>
      }
    </div>
    {/* <> */}
    {isMobile && open &&
      <FilterModal isShowing={open}>
        <div className="dropdown-content" ref={dropdownContent}>
          <div className="filter-header">
            <span className="filter-title">{props.title}</span>
            <Button
              onClick={props.onReset}
              className='reset-filter-button'
              size="sm"
              kind="ghost">
              Reset filters
            </Button>
          </div>
          <div className="filter-content">
            {props.children}
          </div>
          <div className="filter-footer">
            <Button
              onClick={closeDropdown}
              className="footer-button"
              size="xl"
              kind="secondary">
              Cancel
            </Button>
            <Button
              onClick={onApply}
              className="footer-button"
              size="xl"
              kind="primary">
              Apply
            </Button>
          </div>
        </div>
      </FilterModal>
    }
  </>
  )
};