import React, {useState, useRef, useEffect, MutableRefObject} from 'react'
import "./draw.scss";
// Esri Widgets
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";

//UPDATES FROM NATALIE: Draw tooltip
import ToolTip from "../shared/tool-tip/ToolTip";
//END UPDATES FROM NATALIE

// Carbon Widgets
import {
  Button
  //@ts-ignore
} from '@carbon/react';

//UPDATES FROM NATALIE: Measurement Icons
import {
  //Select_01,
  TrashCan,
  ChevronDown,
  ChevronUp,
  RadioButton,
  AreaCustom as PolygonIcon,
  Draw as FreeDrawIcon
  // @ts-ignore
} from '@carbon/icons-react';
//END UPDATES FROM NATALIE


interface iProps {
  view: MutableRefObject<__esri.MapView>;
  symbol: any
}

export default function Draw(props: iProps) {
  const view = props.view;

  const sketchViewModel = useRef<__esri.SketchViewModel | null>(null);
  const drawLayer = useRef<__esri.GraphicsLayer | null>(null);

  // opens draw tool window
  const [toolsOpen, openTools] = useState(false);
  // const [clear, setClear] = useState(false);

  useEffect(() => {
    const layer = view.current.map.findLayerById('DrawBoundary');
    if (layer) {
      drawLayer.current = layer as __esri.GraphicsLayer;

      const fill = new SimpleFillSymbol({
        style: props.symbol.style,
        outline: props.symbol.outline
      });

     sketchViewModel.current = new SketchViewModel({
        view: view.current,
        layer: drawLayer.current,
        polygonSymbol: fill,
      });


      return () => {
        sketchViewModel.current?.destroy();
        drawLayer.current?.removeAll();
      }
    }
  }, [view, props.symbol])

  const selectCircle = () => {
    view.current.popup.close();
      sketchViewModel.current?.create("circle");
  }

  const selectPolygon = () => {
    view.current.popup.close();
    sketchViewModel.current?.create("polygon");
  }

  const selectDraw = () => {
    view.current.popup.close();
    sketchViewModel.current?.create("polygon", {mode: "freehand"});
  }

  const openDrawTool = () => {
    if (toolsOpen) {
      drawLayer.current?.removeAll();
    }
    openTools(!toolsOpen);
    
  }

  const openDrawTool2 = () => {
    openTools(!toolsOpen);
  }

  const openDrawTool3 = () => {
    if (toolsOpen) {
      drawLayer.current?.removeAll();
    }
    
  }

  //UPDATES FROM NATALIE: Draw boundary UI
  return (
    <div className="top-right">
      {toolsOpen &&
        <ToolTip delay={100} direction="left" content="Filter an area by drawing boundaries">
        <Button className="expand-button" kind='primary' renderIcon={ChevronUp} onClick={openDrawTool2}> 
        Draw boundary
        </Button>
        </ToolTip>
      }
      {!toolsOpen &&
        <ToolTip delay={100} direction="left" content="Filter an area by drawing boundaries">
        <Button className="draw-button" renderIcon={ChevronDown} onClick={openDrawTool}> {/*Changed icon from Select_01*/}
          Draw boundary
        </Button>
        </ToolTip>
      }
      {toolsOpen &&
        <div className="tool-panel">
          <ToolTip delay={100} direction="left" content="Click and drag from the center point">
            <Button
            id="circle"
            className="button"
            kind="secondary"
            iconDescription="Draw Circle"
            onClick={selectCircle}
            tooltipPosition="left"
            /*renderIcon={RadioButton}*/
          >&nbsp; Circle <RadioButton className="RadioButton"/>
          </Button>
          </ToolTip>
          <ToolTip delay={100} direction="left" content="Click points on map and double click to finish">
          <Button
            id="polygon"
            className="button"
            kind="secondary"
            iconDescription="Draw Polygon"
            onClick={selectPolygon}
            tooltipPosition="left"
            /*renderIcon={PolygonIcon}*/
          >&nbsp; Polygon <PolygonIcon className="PolygonIcon"/>
          </Button>
          </ToolTip>
          <ToolTip delay={100} direction="left" content="Click and drag to draw area. Release to finish.">
          <Button
            id="draw"
            className="button"
            kind="secondary"
            iconDescription="Free Draw"
            onClick={selectDraw}
            tooltipPosition="left"
            /*renderIcon={FreeDrawIcon}*/
          >&nbsp; Freehand <FreeDrawIcon className="FreeDrawIcon"/>
          </Button>
          </ToolTip>    
                       
          {/*<Button
            id="circle"
            className="button esri-icon-radio-unchecked"
            hasIconOnly
            iconDescription="Draw Circle"
            onClick={selectCircle}
            tooltipPosition="left"
          />
          <Button
            id="polygon"
            className="button esri-icon-polygon"
            hasIconOnly
            iconDescription="Draw Polygon"
            onClick={selectPolygon}
            tooltipPosition="left"
          />
          <Button
            id="draw"
            className="button esri-icon-lasso"
            hasIconOnly
            iconDescription="Free Draw"
            onClick={selectDraw}
            tooltipPosition="left"
          />*/}
          {/* <Button 
            id="clear" 
            className="button esri-icon-erase" 
            hasIconOnly
            iconDescription="Erase"
            onClick={clearDrawing}
          /> */}
        </div>
      }
      {toolsOpen &&
        <div className="tool-panel2">  
          <Button className="cancel-button" kind='primary' onClick={openDrawTool3}> 
          Clear
          </Button>
          <Button className="done-button" kind='primary' onClick={openDrawTool2}> 
          Done
          </Button>
          </div>
      }
    </div>
  );
}