import React, { useEffect, useRef } from 'react'
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";

interface iProps {
  view: __esri.MapView;
  activeWidget: string | null | undefined;
  open: boolean;
}

export default function AreaWidget(props: iProps) {
  
  const widget = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<__esri.AreaMeasurement2D>();

  useEffect(() => {

    if(props.open && props.activeWidget === "area"){
      widgetRef.current = new AreaMeasurement2D({
        view: props.view,
        //UPDATES FROM NATALIE: default units
        unit: "acres",
        //END UPDATES FROM NATALIE           
        container: widget.current!
      });
      widgetRef.current.viewModel.start();
    }
    
    return () => { widgetRef.current && widgetRef.current.destroy(); }
  },[props.open, props.activeWidget, props.view])
 
  return <div ref={widget}></div>
}