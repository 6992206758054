import React, { useState, MutableRefObject, useRef, useEffect } from "react";
import "./ZoomInfoDetails.scss";
import { Aspire_URL_Test } from "../../../SendToAspire/SendToAspire.test"
import { Aspire_URL_Dev } from "../../../SendToAspire/SendToAspire.Dev";
import { Aspire_URL_Prod } from "../../../SendToAspire/SendToAspire.Prod";
import { Client_ID_Test } from "../../../SendToAspire/SendToAspire.test";
import { Secret_Test } from "../../../SendToAspire/SendToAspire.test";
import { ContactEnrichment } from "../../../models/contact";

import { useNavigate } from 'react-router-dom';

import {
    Dropdown,
    IconButton,
    Grid,
    Column,
    Link,
    Select,

    //UPDATES FROM NATALIE: Import dependencies
    Modal,
    //ModalManager,
    Button,
    TextInput,
    TextArea,
    TimePicker,
    TimePickerSelect,
    SelectItem,
    //END UPDATES FROM NATALIE    

    Tag,
    Tooltip, //UPDATES FROM NATALIE
    ToggletipLabel,
    Toggletip,
    ToggletipButton,
    ToggletipContent
    //@ts-ignore
} from '@carbon/react';

import {
    Add,
    Close,
    Copy,
    Edit,
    Favorite,
    FavoriteFilled,
    Information,
    UserProfile,
    Email,
    Phone,
    TaskAdd,
    ArrowLeft,
    Wikis,
    Map,
    Launch,
    Send,
    ZoomInArea
    // @ts-ignore
} from '@carbon/icons-react';
import { link } from "fs";

type Credentials = {
    [market: string]: {
        ALIAS: string;
        CLIENTID: string;
        SECRET: string;
    };
};

interface iProps {
    Contact: any;
    onClick?: Function;
    exportList?: Function;
    filteredCredentials: Credentials | undefined;
}

export default function ZoomInfoDetails(props: iProps) {

    const navigate = useNavigate();

    const [linkedIn, setLinkedIn] = useState('')
    const [companyLinkedIn, setCompanyLinkedIn] = useState('')

    const contactResults = props.Contact.data.result[0].data[0]

    const socials = contactResults.externalUrls

    const companySocials = contactResults.company.socialMediaUrls

    const [upload, setUpload] = useState<Boolean | null>(null);
    const [proceedModal, setProceedModal] = useState(false)
    const [selectedInstance, setSelectedInstance] = useState(undefined)
    const [clientSecret, setClientSecret] = useState({
        clientID: "",
        secret: ""
    })
    const [fieldMappings, setFieldMappings] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        mobilePhone: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        stateName: '',
        zipcode: '',
        title: '',
        properties: {

            propertyName: '',
            propertyAddress: '',
            propertyCity: '',
            propertyState: '',
            propertyZipCode: '',
            propertyIndustry: '',
            propertyTax: 0,
            propertyPayTerms: 0,

        }
    });
    const [uploadResults, setUploadResults] = useState({
        successCount: 0,
        errorCount: 0,
        existCount: 0
    })
    const [resultsModal, setResultsModal] = useState(false)


    useEffect(() => {
        if (window.location.href == "http://localhost:3000/contacts") {
            setClientSecret({
                clientID: Client_ID_Test,
                secret: Secret_Test
            });
        }
    }, []);

    const handleInstanceChange = (instanceName: any) => {
        setSelectedInstance(instanceName); // Set the selected instance

        if (window.location.href == "https://dev.smartreach.app/contacts") {
            setClientSecret({
                //@ts-ignore
                clientID: props.filteredCredentials[instanceName].CLIENTID,
                //@ts-ignore
                secret: props.filteredCredentials[instanceName].SECRET
            });
            /*setClientSecret({
                clientID: Client_ID_Test,
                secret: Secret_Test
            });*/
        }

        if (window.location.href == "https://smartreach.app/contacts") {
            setClientSecret({
                //@ts-ignore
                clientID: props.filteredCredentials[instanceName].CLIENTID,
                //@ts-ignore
                secret: props.filteredCredentials[instanceName].SECRET
            });
        }
    };

    const proceedFunct = () => {
        if (contactResults) {
            setProceedModal(true)
        }
        else {
            alert('Please choose an Aspire instance and import a CSV before uploading.')
        }
    }

    const modalClose = () => {
        setProceedModal(false)
    }

    const onClick = () => {
        if (props.onClick) props.onClick();
    }

    const exportList = (contact: any) => {
        if (props.exportList) props.exportList(contact);
    }

    useEffect(() => {
        for (const item of socials) {
            if (item.type.includes("link") || item.type.includes("LINK")) {
                setLinkedIn(item.url)
            }
        }
        for (const item of companySocials) {
            if (item.type.includes("link") || item.type.includes("LINK")) {
                setCompanyLinkedIn(item.url)
            }
        }
    }, [socials, companySocials]);

    const handleFieldMappingChange = (field: string, value: string) => {
        setFieldMappings({ ...fieldMappings, [field]: value });
    };

    let Aspire_URL = ""

    if (window.location.href == "http://localhost:3000/contacts") {
        Aspire_URL = Aspire_URL_Test;
    }

    if (window.location.href == "https://dev.smartreach.app/contacts") {
        Aspire_URL = Aspire_URL_Dev;
        //Aspire_URL = Aspire_URL_Test;
    }

    if (window.location.href == "https://smartreach.app/contacts") {
        Aspire_URL = Aspire_URL_Prod;
    }

    const goToLocation = (address: any) => {
        navigate('/properties', { state: { address } });
    };

    function authPOST() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "clientId": clientSecret.clientID,
            "secret": clientSecret.secret
        });

        let requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // AUTHORIZATION REQUEST THAT WILL USE CLIENT ID AND SECRET FOR EACH OPCO
        return fetch(Aspire_URL + "/Authorization", requestOptions)
            .then(response => {
                if (!response.ok) {
                    //console.error('Network response was not ok');
                    return Promise.reject('Network response was not ok');
                }
                return response.json();
            })
            .then(result => {
                const bearer_token = result.Token;
                const bearer = 'Bearer ' + bearer_token;
                return bearer as any;
            })
            .catch(error => {
                //console.error('Error:', error);
                throw error; // Re-throw the error to propagate it to the caller
            });
    }


    function companyGET(bearer: any, item: any) {

        let myHeaders = new Headers({
            "Authorization": bearer,
            "Content-Type": "application/json"
        });

        let requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let encodedCompanyName = encodeURIComponent(fieldMappings.companyName.trim() || item.company.name);
        let urlcomp = `${Aspire_URL}/Companies?$filter=CompanyName eq '${encodedCompanyName}'`;

        return fetch(urlcomp, requestOptions)
            .then(response => {
                if (!response.ok) {
                    //console.error('Network response was not ok');
                    return Promise.reject('Network response was not ok');
                }
                return response.json();
            })
            .then(result => {
                if (result.length === 0) {
                    //console.log("No company found");
                    return Promise.resolve(0 as any);
                } else {
                    return Promise.resolve(result[0].CompanyID);
                }
            })
            .catch(error => {
                //console.error('Error:', error);
                return Promise.reject(error);
            });
    }

    function companyPOST(bearer: any, CompanyID: any, item: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (CompanyID !== 0) {
                //console.log('Company already exists in Aspire');
                // No need to setCompanyID here as it's already set
                resolve(CompanyID);
            } else {
                let myHeaders = new Headers({
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                });

                var raw = JSON.stringify({
                    "CompanyName": item.company.name || item[fieldMappings.companyName.trim()] || null,
                    "Active": true
                });

                let requestOptions: RequestInit = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                // COMPANIES POST REQUEST THAT CREATES A NEW COMPANY IN ASPIRE FROM CURRENT PROPERTY'S COMPANY
                fetch(Aspire_URL + "/Companies", requestOptions)
                    .then((res) => {
                        //console.log(requestOptions);
                        if (res.status === 400) {
                            resolve(0); // Resolve with a default value if needed
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result !== undefined && result !== '' && result !== null && result !== ' ') {
                            resolve(result.CompanyID);
                        } else {
                            resolve(0);
                        }
                    })
                    .catch(error => {
                        //console.error('Error:', error);
                        reject(error);
                    });
            }
        });
    }

    function contactGET(bearer: any, item: any) {

        //const firstName = item['FirstName'] || item[fieldMappings.firstName.trim()];
        //const lastName = item['LastName'] || item[fieldMappings.lastName.trim()];
        const email = fieldMappings.email.trim() || item.email

        let url = `${Aspire_URL}/Contacts?%24filter=Email%20eq%20%27${encodeURIComponent(email)}%27`/*%20and%20LastName%20eq%20%27${encodeURIComponent(lastName)}%27`*/;
        //console.log(url)

        let requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                "Authorization": bearer,
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        };

        return fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    //console.error('Network response was not ok');
                    return Promise.reject('Network response was not ok');
                }
                return response.json();
            })
            .then(result => {
                if (result.length === 0) {
                    //console.log("No contact found");
                    return 0 as any;
                } else {
                    return result[0].ContactID as any;
                }
            })
            .catch(error => {
                //console.error('Fetch error:', error);
                return Promise.reject(error);
            });
    }

    function contactTYPE(bearer: any) {

        const raw = "";

        let requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                "Authorization": bearer,
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        };

        return fetch(Aspire_URL + "/ContactTypes?$select=ContactTypeName,ContactTypeID&$filter=Active%20eq%20true%20and%20ContactTypeName%20eq%20%27Prospect%27&$top=1", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.length === 0) {
                    //console.log("No ContactType found");
                    return Promise.resolve(0 as any);
                } else {
                    return Promise.resolve(result[0].ContactTypeID);
                }
            })
            .catch(error => { return Promise.reject(error) });
    }

    function contactPOST(bearer: any, ContactID: any, CompanyID: any, item: any, contactTypeID: any) {
        if (ContactID) {
            //console.log('Contact already exists in Aspire');
            setUploadResults(prevState => ({ ...prevState, existCount: prevState.existCount + 1 }));
            return Promise.resolve(0 as any);
        }
        else {

            let stateProvinceCode = null
            let stateProvinceName = null

            if (fieldMappings.stateName) {
                if (fieldMappings.stateName.trim().length > 2)
                    stateProvinceName = fieldMappings.stateName.trim()
                else if (fieldMappings.stateName.trim().length === 2) {
                    stateProvinceCode = fieldMappings.stateName.trim()
                }
            }
            else if (!fieldMappings.stateName && item.state) {
                if (item.state.trim().length > 2)
                    stateProvinceName = item.state.trim()
                else if (item.state.trim().length === 2) {
                    stateProvinceCode = item.state.trim()
                }
            }

            let myHeaders = new Headers({
                "Authorization": bearer,
                "Content-Type": "application/json"
            });

            let raw = JSON.stringify({
                "Contact": {
                    "FirstName": fieldMappings.firstName.trim() || item.firstName || null,
                    "LastName": fieldMappings.lastName.trim() || item.lastName || null,
                    "CompanyID": CompanyID,
                    //"CompanyName": item['CompanyName'] || null,
                    "ContactTypeID": contactTypeID,
                    "Title": fieldMappings.title.trim() || item.jobTitle || null,
                    "Email": fieldMappings.email.trim() || item.email || null,
                    "MobilePhone": fieldMappings.mobilePhone.trim() || item.mobilePhone || null,
                    "OfficePhone": fieldMappings.phone.trim() || item.phone || null,
                    "Active": true
                },
                "OfficeAddress": {
                    "AddressLine1": fieldMappings.address.trim() || item.street || null,
                    "AddressLine2": null,
                    "City": fieldMappings.city.trim() || item.city || null,
                    "StateProvinceCode": stateProvinceCode || "",
                    "StateProvinceName": stateProvinceName || null,
                    "ZipCode": fieldMappings.zipcode.trim() || item.zipCode || null,
                }
            });

            let requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            let OuchFactor = '';

            // CONTACTS POST REQUEST THAT WILL CREATE A NEW CONTACT IN ASPIRE FROM CURRENT PROPERTY'S CONTACT
            return fetch(Aspire_URL + "/Contacts", requestOptions)
                .then((res) => {
                    //console.log(requestOptions);
                    if (res.status === 400) {
                        OuchFactor = "BigOuch";
                        //console.log(OuchFactor);
                        return Promise.resolve(null);
                    } else {
                        return res.json();
                    }
                })
                .then(result => {
                    if (result !== null && OuchFactor !== "BigOuch") {
                        setUploadResults(prevState => ({ ...prevState, successCount: prevState.successCount + 1 }));
                        return result;
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    setUploadResults(prevState => ({ ...prevState, errorCount: prevState.errorCount + 1 }));
                    return Promise.reject(error);
                });
        }
    }


    async function uploadToAspire() {
        if (contactResults && selectedInstance) {
            const bearer = await authPOST();
            const contactTypeID = await contactTYPE(bearer);
            if ((contactResults.firstName || fieldMappings.firstName) && (contactResults.lastName || fieldMappings.lastName) && (contactResults.company.name || fieldMappings.companyName) && (contactResults.email || fieldMappings.email)) {
                modalClose()
                setUpload(true);
                try {
                    //const bearer = await authPOST();
                    const companyResult = await companyGET(bearer, contactResults);
                    const companyID = await companyPOST(bearer, companyResult, contactResults);
                    const contactID = await contactGET(bearer, contactResults);
                    await contactPOST(bearer, contactID, companyID, contactResults, contactTypeID);
                } catch (error) {
                    setUploadResults(prevState => ({ ...prevState, errorCount: prevState.errorCount + 1 }));
                    console.error('Error uploading data to Aspire:', error);
                }
            } else {
                alert('Please fill out all required fields.')
                setUploadResults(prevState => ({ ...prevState, errorCount: prevState.errorCount + 1 }));
            }

        }
        else {
            alert('Please select valid Aspire instance.')
        }
        setFieldMappings({
            firstName: '',
            lastName: '',
            companyName: '',
            mobilePhone: '',
            phone: '',
            email: '',
            address: '',
            city: '',
            stateName: '',
            zipcode: '',
            title: '',
            properties: {

                propertyName: '',
                propertyAddress: '',
                propertyCity: '',
                propertyState: '',
                propertyZipCode: '',
                propertyIndustry: '',
                propertyTax: 0,
                propertyPayTerms: 0,
            }
        })
        setResultsModal(true)
    }


    const copyToClipBoard = () => {
        if (contactResults.street)
            navigator.clipboard.writeText(
                `${contactResults.street + " " + contactResults.city + ", " +
                contactResults.state + " " + contactResults.zipCode}`
            );
        //UPDATES FROM NATALIE: remove tooltip  
        //setAddressTooltip("Copied!");
        //END UPDATES FROM NATALIE
    }


    return (

        <div className="zoominfo-details">
            <div className="property-details-header">
                <IconButton
                    //onClick={unfavoriteProperty}
                    className="back-button"
                    label="Back to search results"
                    kind="ghost"
                    align="right"
                    onClick={() => onClick()}
                >
                    <ArrowLeft className="arrow-right" />
                </IconButton>
                <div className="title">Contact details</div>
                <div className="button-group">
                    <IconButton
                        //onClick={unfavoriteProperty}
                        label="Add contact to export list"
                        kind="ghost"
                        align="left"
                        onClick={() => exportList(contactResults)}
                    >
                        <TaskAdd size={16} className="is-favorite" />
                    </IconButton>

                    <IconButton
                        //onClick={unfavoriteProperty}
                        label="Jump to contact on map"
                        kind="ghost"
                        align="left"
                        onClick={() => goToLocation(contactResults.street + ", " + contactResults.city + ", " + contactResults.state + " " + contactResults.zipCode)}
                    >
                        <Map size={16} className="is-favorite" />
                    </IconButton>
                    <IconButton
                        //href="https://cloud.youraspire.com/app/properties/new"
                        //target="_blank"
                        //onClick={() => { }}
                        onClick={() => { proceedFunct() }}
                        label="Send contact to Aspire"
                        //disabled="true"
                        kind="ghost"
                        align="left"
                    >
                        <Send size={28} className="Send" />
                    </IconButton>

                </div>
            </div>
            <div className="details-container">
                <Grid className="section-container contact-info" fullWidth>
                    <Column className="detail-header" sm={4} md={8} lg={16}>
                        <div className="property-header-left">
                            <div className="property-name">{contactResults.firstName + " " + contactResults.lastName}</div>
                            {(contactResults.street || contactResults.city || contactResults.state || contactResults.zipCode) &&
                                <button className="property-address" onClick={copyToClipBoard}>

                                    <div>{contactResults.street}</div>
                                    <div className="property-city-state-zip">
                                        <div>{contactResults.city + ", " + contactResults.state + " "}</div>
                                        <div>{contactResults.zipCode}</div>
                                    </div>
                                </button>
                            }
                            {(!contactResults.street && !contactResults.city && !contactResults.state && !contactResults.zipCode) &&

                                <button className="property-address" onClick={copyToClipBoard}>

                                    N/A Address
                                </button>
                            }
                        </div>
                        <div className="potential-container">
                            <div>
                                <ToggletipLabel className="potential">Accuracy</ToggletipLabel>
                                <Toggletip align="left">
                                    <ToggletipButton label="Show information">
                                        <Information className="info-icon" size={16} />
                                    </ToggletipButton>
                                    <ToggletipContent>
                                        <p>
                                            Accuracy indicates the likelihood the
                                            contact information is correct.
                                        </p>
                                    </ToggletipContent>
                                </Toggletip>
                            </div>
                            <div className="potential-rank">{contactResults.contactAccuracyScore || contactResults.contactAccuracyScore === 0 ? `${contactResults.contactAccuracyScore}%` : "N/A"}</div>
                            <div className="labels updated-date">As of: {contactResults.lastUpdatedDate?.split(" ")[0] && contactResults.lastUpdatedDate?.split(" ")[0] !== "" ? contactResults.lastUpdatedDate?.split(" ")[0] : "N/A"}</div>
                        </div>
                    </Column>
                    <Column className="columns" sm={2} md={2} lg={5}>
                        <div className="labels property-manager">Job Title</div>
                    </Column>
                    <Column className="columns" sm={2} md={6} lg={9}>
                        {contactResults.jobTitle && contactResults.jobTitle !== "" ? contactResults.jobTitle : "N/A"}
                    </Column>
                    <Column className="columns" sm={2} md={2} lg={5}>
                        <div className="labels leasing-company">Job Function</div>
                    </Column>
                    <Column className="columns" sm={2} md={6} lg={9}>
                        {contactResults.jobFunction?.[0]?.name && contactResults.jobFunction?.[0]?.name !== "" ? contactResults.jobFunction?.[0]?.name : "N/A"}
                    </Column>
                    <Column className="columns" sm={2} md={2} lg={5}>
                        <div className="labels property-owner">Company</div>
                    </Column>
                    <Column className="columns" sm={2} md={6} lg={9}>
                        {contactResults.company.name && contactResults.company.name !== "" ? contactResults.company.name : "N/A"}
                    </Column>
                    <Column className="columns" sm={2} md={2} lg={5}>
                        <div className="labels property-owner">Work Phone</div>
                    </Column>
                    <Column className="columns" sm={2} md={6} lg={9}>
                        {contactResults.phone && contactResults.phone !== "" ? contactResults.phone : "N/A"}
                    </Column>
                    <Column className="columns" sm={2} md={2} lg={5}>
                        <div className="labels property-owner">Mobile Phone</div>
                    </Column>
                    <Column className="columns" sm={2} md={6} lg={9}>
                        {contactResults.mobilePhone && contactResults.mobilePhone !== "" ? contactResults.mobilePhone : "N/A"}
                    </Column>
                    <Column className="link-container" sm={4} md={8} lg={16}>
                        {/*<Link
                            className="phone link"
                            disabled={contactResults.phone ? false : true}
                            renderIcon={Phone}
                            href={`tel:${contactResults.phone}`}
                            target="_blank"
                        >
                            Call Work
                        </Link>
                        <Link
                            className="phone link"
                            disabled={contactResults.mobilePhone ? false : true}
                            renderIcon={Phone}
                            href={`tel:${contactResults.mobilePhone}`}
                            target="_blank"
                        >
                            Call Mobile
    </Link>*/}
                        <Link
                            className="email link"
                            disabled={contactResults.email ? false : true}
                            renderIcon={Email}
                            href={`mailto:${contactResults.email}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Email
                        </Link>
                        <Link
                            className="linkedin link"
                            renderIcon={UserProfile}
                            disabled={linkedIn ? false : true}
                            href={linkedIn}
                            target="_blank"
                            rel="noopener noreferrer"
                        //onClick={copyToClipBoard}
                        >
                            LinkedIn
                        </Link>
                    </Column>
                </Grid>
                <div className="company-details-header">
                    <div className="title">Company details</div>
                    <div className="button-group">
                        <IconButton
                            //onClick={unfavoriteProperty}
                            label="Jump to company on map"
                            kind="ghost"
                            align="left"
                            onClick={() => goToLocation(contactResults.company.street + ", " + contactResults.company.city + ", " + contactResults.company.state + " " + contactResults.company.zipCode)}
                        >
                            <Map size={16} className="is-favorite" />
                        </IconButton>
                    </div>

                </div>
                <>
                    <Grid className="section-container contact-info" fullWidth>
                        <Column className="detail-header" sm={4} md={8} lg={16}>
                            <div className="property-header-left">
                                <div className="property-name">{contactResults.company.name}</div>
                                {(contactResults.company.street || contactResults.company.city || contactResults.company.state || contactResults.company.zipCode) &&
                                    <button className="property-address" onClick={copyToClipBoard}>

                                        <div>{contactResults.company.street}</div>
                                        <div className="property-city-state-zip">
                                            <div>{contactResults.company.city + ", " + contactResults.company.state + " "}</div>
                                            <div>{contactResults.company.zipCode}</div>
                                        </div>
                                    </button>
                                }
                                {(!contactResults.company.street && !contactResults.company.city && !contactResults.company.state && !contactResults.company.zipCode) &&

                                    <button className="property-address" onClick={copyToClipBoard}>

                                        N/A Address
                                    </button>
                                }
                            </div>
                            {/*<div className="potential-container">
                                <div>
                                <img src={contactResults.company.logo} alt="company-logo" className='responsive-img' />
                                </div>
</div>*/}
                        </Column>
                        <Column className="columns" sm={4} md={8} lg={16}>
                            <div className="labels property-manager">NAICS Codes</div>
                        </Column>
                        <Column sm={2} md={6} lg={15}>
                            <div className="columns value-labels amenities"
                                style={{
                                    color: contactResults.company.naicsCodes && contactResults.company.naicsCodes.length > 0
                                        ? 'var(--cds-text-primary)'
                                        : 'var(--cds-text-disabled)'
                                }}
                            >
                                {contactResults.company.naicsCodes && contactResults.company.naicsCodes.length > 0
                                    ? Array.from(new Set(contactResults.company.naicsCodes.map((code: any) => code.name))).map((name: any, index) => (
                                        <Tag size="sm" key={index}>{name}</Tag>
                                    ))
                                    : "N/A"}
                            </div>
                        </Column>
                        <br />
                        <Column className="columns" sm={2} md={2} lg={5}>
                            <div className="labels leasing-company">Sector</div>
                        </Column>
                        <Column className="columns" sm={2} md={6} lg={9}>
                            {contactResults.company.type?.trim() ? contactResults.company.type : "N/A"}
                        </Column>
                        <Column className="columns" sm={2} md={2} lg={5}>
                            <div className="labels leasing-company">Phone</div>
                        </Column>
                        <Column className="columns" sm={2} md={6} lg={9}>
                            {contactResults.company.phone?.trim() ? contactResults.company.phone : "N/A"}
                        </Column>


                        {/*<Column className="columns" sm={2} md={2} lg={5}>
                            <div className="labels property-owner">Company</div>
                        </Column>
                        <Column className="columns" sm={2} md={6} lg={9}>
                            {contactResults.company.name}
                            </Column>*/}
                        <Column className="link-container" sm={4} md={8} lg={16}>
                            {/*<Link
                                className="company phone"
                                disabled={contactResults.company.phone ? false : true}
                                renderIcon={Phone}
                                href={`tel:${contactResults.company.phone}`}
                                target="_blank"
                            >
                                Call
                        </Link>*/}
                            <Link
                                className="company website"
                                disabled={contactResults.company.website ? false : true}
                                renderIcon={Wikis}
                                href={`http://${contactResults.company.website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Website
                            </Link>
                            <Link
                                className="company linkedin"
                                renderIcon={UserProfile}
                                disabled={companyLinkedIn ? false : true}
                                href={companyLinkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                            //onClick={copyToClipBoard}
                            >
                                LinkedIn
                            </Link>
                        </Column>
                    </Grid>
                </>
                {/*<div className="button-group">
                    <IconButton
                        //onClick={unfavoriteProperty}
                        label="Add contact to export list"
                        kind="ghost"
                        align="left"
                    >
                        <TaskAdd size={16} className="is-favorite" />
                    </IconButton>

                    <Tooltip title="Send Property to Aspire">
                        <IconButton
                            //href="https://cloud.youraspire.com/app/properties/new"
                            target="_blank"
                            //onClick={() => { }}
                            //onClick={() => { { openAspireModal(); console.log(props.marketVariables) } }}
                            label="Send Property to Aspire"
                            //disabled="true"
                            kind="ghost"
                            align="left"
                        >
                            <Send size={28} className="Send" />
                        </IconButton></Tooltip>

</div>*/}
            </div>
            <Modal
                open={proceedModal}
                onRequestClose={() => {
                    modalClose(); setFieldMappings({
                        firstName: '',
                        lastName: '',
                        companyName: '',
                        mobilePhone: '',
                        phone: '',
                        email: '',
                        address: '',
                        city: '',
                        stateName: '',
                        zipcode: '',
                        title: '',
                        properties: {

                            propertyName: '',
                            propertyAddress: '',
                            propertyCity: '',
                            propertyState: '',
                            propertyZipCode: '',
                            propertyIndustry: '',
                            propertyTax: 0,
                            propertyPayTerms: 0,
                        }
                    });
                }}
                //modalHeading="Add a custom domain"
                modalLabel="Contact Upload to Aspire"
                primaryButtonText="Upload"
                secondaryButtonText="Cancel"
                onRequestSubmit={() => (uploadToAspire())}
            >
                <p className="modal-notice">
                    Notice: Default values populated from contact fields. Any default value overwritten
                    will accept the new value for Aspire upload. Required fields noted by red asterisk.
                </p>
                {/*<p className="bullets">
      - FirstName
    </p>
    <p className="bullets">
      - LastName
    </p>
    <p className="bullets">
      - CompanyName
    </p>
    <p className="bullets">
      - StateProvinceCode or ZipCode
  </p>*/}
                <div>
                    {/* Field mapping selects */}
                    {props.filteredCredentials &&
                        <div className="row-instance">
                            <Select
                                id="instance-select"
                                className="instance-select"
                                //placeholder="Select Value"
                                labelText="Select Aspire Instance"
                                value={selectedInstance}
                                onChange={(e: any) => handleInstanceChange(e.target.value)}
                            >
                                <SelectItem value="">Select an instance</SelectItem>
                                {/*//@ts-ignore*/}
                                {Object.keys(props.filteredCredentials).sort().map((name, index) => (
                                    <SelectItem key={index} value={name} text={props.filteredCredentials![name].ALIAS} />
                                ))}
                            </Select>
                        </div>
                    }
                    <div className="row">
                        <TextInput
                            id="firstName"
                            type="text"
                            labelText="First Name"
                            placeholder={contactResults.firstName || ''}
                            value={fieldMappings.firstName ? fieldMappings.firstName : contactResults.firstName}
                            onChange={(e: any) => handleFieldMappingChange('firstName', e.target.value)}
                        />
                        <TextInput
                            id="lastName"
                            type="text"
                            labelText="Last Name"
                            placeholder={contactResults.lastName || ''}
                            value={fieldMappings.lastName ? fieldMappings.lastName : contactResults.lastName}
                            onChange={(e: any) => handleFieldMappingChange('lastName', e.target.value)}
                        />
                    </div>
                    <div className="row">
                        <TextInput
                            id="companyName"
                            type="text"
                            labelText="Company Name"
                            placeholder={contactResults.company.name || ''}
                            value={fieldMappings.companyName ? fieldMappings.companyName : contactResults.company.name}
                            onChange={(e: any) => handleFieldMappingChange('companyName', e.target.value)}
                        />
                        <TextInput
                            id="title"
                            type="text"
                            labelText="Title"
                            placeholder={contactResults.jobTitle || ''}
                            value={fieldMappings.title ? fieldMappings.title : contactResults.jobTitle}
                            onChange={(e: any) => handleFieldMappingChange('title', e.target.value)}
                        />
                    </div>
                    <div className="row">
                        <TextInput
                            id="phone"
                            type="text"
                            labelText="Mobile Phone"
                            placeholder={contactResults.mobilePhone || ''}
                            value={fieldMappings.mobilePhone ? fieldMappings.mobilePhone : contactResults.mobilePhone}
                            onChange={(e: any) => handleFieldMappingChange('mobilePhone', e.target.value)}
                        />
                        <TextInput
                            id="phone"
                            type="text"
                            labelText="Office Phone"
                            placeholder={contactResults.phone || ''}
                            value={fieldMappings.phone ? fieldMappings.phone : contactResults.phone}
                            onChange={(e: any) => handleFieldMappingChange('phone', e.target.value)}
                        />
                        <TextInput
                            id="email"
                            type="text"
                            labelText="Email"
                            placeholder={contactResults.email || ''}
                            value={fieldMappings.email ? fieldMappings.email : contactResults.email}
                            onChange={(e: any) => handleFieldMappingChange('email', e.target.value)}
                        />
                    </div>
                    <div className="row">
                        <TextInput
                            id="address"
                            type="text"
                            labelText="Address"
                            placeholder={contactResults.street || ''}
                            value={fieldMappings.address ? fieldMappings.address : contactResults.street}
                            onChange={(e: any) => handleFieldMappingChange('address', e.target.value)}
                        />
                    </div>
                    <div className="row">
                        <TextInput
                            id="city"
                            type="text"
                            labelText="City"
                            placeholder={contactResults.city || ''}
                            value={fieldMappings.city ? fieldMappings.city : contactResults.city}
                            onChange={(e: any) => handleFieldMappingChange('city', e.target.value)}
                        />
                        <TextInput
                            id="stateName"
                            type="text"
                            labelText="State"
                            placeholder={contactResults.state || ''}
                            value={fieldMappings.stateName ? fieldMappings.stateName : contactResults.state}
                            onChange={(e: any) => handleFieldMappingChange('stateName', e.target.value)}
                        />
                        <TextInput
                            id="zipCode"
                            type="text"
                            labelText="ZipCode"
                            placeholder={contactResults.zipCode || ''}
                            value={fieldMappings.zipcode ? fieldMappings.zipcode : contactResults.zipCode}
                            onChange={(e: any) => handleFieldMappingChange('zipCode', e.target.value)}
                        />
                    </div>
                </div>
                {/* Additional code for rendering CSV data and handling field replacements */}
            </Modal>
        </div >
    )
}